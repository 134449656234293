import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { useCallback, useLayoutEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useDispatch, useSelector } from 'react-redux'
import { GF } from 'utils/GlobalFunctions'
import { getDB_Data_lazy } from 'features/actions/PageActions'
import { closePopUp } from 'features/actions/PopUpActions'
import { Label } from 'components/UI/moduls/Input'

const fields = [
  {
    label: 'Заголовок',
    name: 'TITLE',
  },
  {
    label: 'Подзаголовок',
    name: 'SUBTITLE',
    multiline: true,
    rows: 2,
  },
]

const defaultState = {
  IS_SUSTEM: 1,
}

export default function POP_HelpText({ ID_OBJECT }) {
  const HELP_CARDS = useSelector((store) => store.page.HELP_CARDS)
  const SelfUser = useSelector((store) => store.user.data)
  const [Self, setSelf] = useState(defaultState)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (ID_OBJECT == null) {
      if (Self.ID != null) setSelf(defaultState)
    } else setSelf({ ...HELP_CARDS.find((h) => h.ID == ID_OBJECT) })
  }, [HELP_CARDS, ID_OBJECT])

  const changeText = useCallback(
    (text, fieldKey) => {
      const tmp = { ...Self }
      tmp[fieldKey] = text
      setSelf(tmp)
    },
    [Self],
  )

  const onDelete = useCallback(() => {
    GF.API_task({
      operation: 'deleteSome',
      Table: 'HELP_CARDS',
      ID: Self.ID,
    }).then((res) => {
      dispatch(closePopUp())
      dispatch(getDB_Data_lazy('HELP_CARDS'))
    })
  })

  const onSave = useCallback(() => {
    const tmp = { ...Self }
    delete tmp.ID

    GF.API_task({
      operation: 'createUpdateSome',
      Table: 'HELP_CARDS',
      ID: Self.ID || null,
      data: { ...tmp },
    }).then((res) => {
      dispatch(closePopUp())
      dispatch(getDB_Data_lazy('HELP_CARDS'))
    })
  })

  return (
    <div style={{ width: 800 }}>
      <h4>Карточка</h4>

      {SelfUser.ROLE == 'ADMN' && (
        <FormControl fullWidth sx={{ mb: 1 }}>
          <InputLabel id="ID_STATUS-label">Статус</InputLabel>
          <Select
            value={Self.IS_SUSTEM}
            labelId="ID_STATUS-label"
            variant="outlined"
            label="Статус"
            onChange={(e, value) => changeText(value.props.value * 1, 'IS_SUSTEM')}
          >
            <MenuItem value="0">Для заказчиков</MenuItem>
            <MenuItem value="1">Для сотрудников</MenuItem>
          </Select>
        </FormControl>
      )}

      {fields.map((f) =>
        SelfUser.ROLE == 'ADMN' ? (
          <div
            style={{
              width: '100%',
            }}
          >
            <TextField
              style={{
                marginBottom: 10,
                width: '100%',
              }}
              {...f}
              value={Self[f.name]}
              onChange={(e) => changeText(e.target.value, f.name)}
            />
          </div>
        ) : (
          <>
            <Label>{f.label}</Label>
            <p>{Self[f.name]}</p>
          </>
        ),
      )}

      {SelfUser.ROLE == 'ADMN' ? (
        <ReactQuill theme="snow" value={Self.TEXT} onChange={(html) => changeText(html, 'TEXT')} />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: Self.TEXT }} />
      )}

      {SelfUser.ROLE == 'ADMN' && (
        <Stack flexDirection={'row'} justifyContent="space-between">
          {Self.ID == null ? (
            <div />
          ) : (
            <Button color="error" onClick={onDelete}>
              Удалить
            </Button>
          )}
          <Button color="success" onClick={onSave}>
            Сохранить
          </Button>
        </Stack>
      )}
    </div>
  )
}
