export enum IEnvIconEnum {
  'chat' = 'chat',
  'data' = 'data',
  'help' = 'help',
  'home' = 'home',
  'tasks' = 'tasks',
  'schedule' = 'schedule',
  'report' = 'report',
  'user' = 'user',
  'logout' = 'logout',
  'arrow' = 'arrow',
  'logo' = 'logo',
  'menu' = 'menu',
  'trashCan' = 'trashCan',
}
export enum IEnvImageEnum {
  'bg' = 'bg',
}
