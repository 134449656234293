import React, { ReactNode, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link, Route, Switch } from 'react-router-dom'

import { IRootStoreState } from 'features/types'
import { IEnvIconEnum } from 'components/UI/images/types'

import {
  Burger,
  LeftMenuView,
  ListElem,
  ContentView,
  IconContainer,
  LinkContainer,
  Logo,
  LogoContainer,
} from './styles'
import EnvIcon from 'components/UI/images/EnvIcon'
import { envIconPath } from 'components/UI/images/envIconPath'

import { openPopUp } from 'features/actions/PopUpActions'
import Help from 'pages/Help'
import Planner from 'pages/Planner'
import Chats from 'pages/Chats'
import DataBase from 'pages/DataBase'
import MainPage from 'pages/MainPage'
import Reports from 'pages/Reports'
import Tasks from 'pages/Tasks'
import UserProfile from 'pages/UserProfile'

interface IMenu {
  title: string
  url: string
  view: ReactNode
  icon: ReactNode
}
interface IAllMenu {
  ADMN: Array<IMenu>
  EMPL: Array<IMenu>
  CLEN: Array<IMenu>
}
type SimpleRoutes = Array<{ url: string; view: ReactNode }>
interface ILeftMenu {
  user: any
  location: any
}

const AllMenu: IAllMenu = {
  ADMN: [
    {
      title: 'Главная',
      url: '/main',
      view: <MainPage />,
      icon: <EnvIcon name={IEnvIconEnum.home} />,
    },
    {
      title: 'База данных',
      url: '/data',
      view: <DataBase />,
      icon: <EnvIcon name={IEnvIconEnum.data} />,
    },
    {
      title: 'Задачи',
      url: '/tasks',
      view: <Tasks />,
      icon: <EnvIcon name={IEnvIconEnum.tasks} />,
    },
    {
      title: 'Отчеты',
      url: '/reports',
      view: <Reports />,
      icon: <EnvIcon name={IEnvIconEnum.report} />,
    },
    {
      title: 'Чаты',
      url: '/chats',
      view: <Chats />,
      icon: <EnvIcon name={IEnvIconEnum.chat} />,
    },
    {
      title: 'Помощь',
      url: '/help',
      view: <Help />,
      icon: <EnvIcon name={IEnvIconEnum.help} />,
    },
  ],
  EMPL: [
    {
      title: 'Главная',
      url: '/main',
      view: <MainPage />,
      icon: <EnvIcon name={IEnvIconEnum.home} />,
    },
    {
      title: 'Задачи',
      url: '/tasks',
      view: <Tasks />,
      icon: <EnvIcon name={IEnvIconEnum.tasks} />,
    },
    {
      title: 'Планировщик',
      url: '/planner',
      view: <Planner />,
      icon: <EnvIcon name={IEnvIconEnum.schedule} />,
    },
    {
      title: 'Чаты',
      url: '/chats',
      view: <Chats />,
      icon: <EnvIcon name={IEnvIconEnum.chat} />,
    },
    {
      title: 'Помощь',
      url: '/help',
      view: <Help />,
      icon: <EnvIcon name={IEnvIconEnum.help} />,
    },
  ],
  CLEN: [
    {
      title: 'Главная',
      url: '/main',
      view: <MainPage />,
      icon: <EnvIcon name={IEnvIconEnum.home} />,
    },
    {
      title: 'Задачи',
      url: '/tasks',
      view: <Tasks />,
      icon: <EnvIcon name={IEnvIconEnum.tasks} />,
    },
    {
      title: 'Чаты',
      url: '/chats',
      view: <Chats />,
      icon: <EnvIcon name={IEnvIconEnum.chat} />,
    },
    {
      title: 'Помощь',
      url: '/help',
      view: <Help />,
      icon: <EnvIcon name={IEnvIconEnum.help} />,
    },
  ],
}
const SimpleRoutes: SimpleRoutes = [
  {
    url: '/chats/:ID_CHAT',
    view: <Chats />,
  },
  {
    url: '/user_profile',
    view: <UserProfile />,
  },
]

const isSelectedMenu = (url: string, location: { pathname: string | any[] }) => {
  let isSelected = location.pathname.indexOf(url) == 0

  if (location.pathname == '/' && url == '/main') {
    isSelected = true
  }

  return isSelected
}

const LeftMenu = (props: ILeftMenu) => {
  const { user, location } = props
  // @ts-ignore
  const menu = AllMenu[user.data.ROLE]

  const [state, setState] = useState({
    isOpen: false,
  })

  if (menu == null) return <></>

  return (
    <>
      <Burger key="burger" isOpen={state.isOpen} onClick={() => setState({ isOpen: !state.isOpen })}>
        <div className="b1" />
        <div className="b2" />
        <div className="b3" />
      </Burger>

      <LeftMenuView key="left-menu-view" isOpen={state.isOpen}>
        <LogoContainer>
          <Logo src={envIconPath({ name: IEnvIconEnum.logo })} />
        </LogoContainer>

        {menu.map((m: IMenu) => {
          const isSelected = isSelectedMenu(m.url, location)

          return (
            <LinkContainer key={m.url} isSelected={isSelected}>
              <Link to={m.url} onClick={() => setState({ isOpen: false })}>
                <IconContainer isSelected={isSelected}>{m.icon}</IconContainer>
                <ListElem isSelected={isSelected}>{m.title}</ListElem>
              </Link>
            </LinkContainer>
          )
        })}
      </LeftMenuView>

      <ContentView key="content-view">
        <Switch>
          {SimpleRoutes.map((m) => (
            <Route key={m.url} path={m.url}>
              {m.view}
            </Route>
          ))}
          {menu.map((m: IMenu) => (
            <Route key={m.url} path={m.url}>
              {m.view}
            </Route>
          ))}

          <Route path="/">
            <MainPage />
          </Route>
        </Switch>
      </ContentView>
    </>
  )
}

const mapDispatchToProps = (dispatch: React.Dispatch<any>) => {
  return {
    openPopUp: (arg: any) => dispatch(openPopUp(arg)),
  }
}
const mapStateToProps = (store: IRootStoreState) => {
  return {
    user: store.user,
    page: store.page,
  }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LeftMenu))
