import { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, CardActions, CardContent, CardHeader, MenuItem, MenuList } from '@mui/material'

import { TaskWrapper } from './styles'

import { openPopUp } from 'features/actions/PopUpActions'
import POP_HelpText from 'pages/Help/POP_HelpText'
import PageLayout from 'components/UI/layouts/PageLayout'

const Help = () => {
  const HELP_CARDS = useSelector((store) => store.page.HELP_CARDS)
  const SelfUser = useSelector((store) => store.user.data)
  const dispatch = useDispatch()
  const [cards, setCards] = useState(HELP_CARDS)

  const isAdmn = SelfUser.ROLE == 'ADMN'
  const isEmpl = SelfUser.ROLE == 'EMPL'
  const isClen = SelfUser.ROLE == 'CLEN'

  const openPOP = useCallback((id = null) => {
    dispatch(openPopUp(<POP_HelpText ID_OBJECT={id} />))
  }, [])
  const menuItem = () => {
    return cards.map((card) => {
      return [
        <MenuItem key={card.id + '_card'} onClick={() => openPOP(card.ID)}>
          <CardHeader title={card.TITLE} />
          <CardContent>
            {card.SUBTITLE?.substring(0, 150)}
            {card.SUBTITLE?.length > 150 && '...'}
          </CardContent>

          <CardActions>
            <Button size="small" onClick={() => openPOP(card.ID)}>
              {isAdmn ? 'Изменить' : 'Подробнее'}
            </Button>
          </CardActions>
        </MenuItem>,
      ]
    })
  }

  useLayoutEffect(() => {
    let cardsTmp = []

    if (isEmpl) cardsTmp = [...HELP_CARDS.filter((c) => c.IS_SUSTEM == 1)]
    if (isAdmn) cardsTmp = [...HELP_CARDS]
    if (isClen) cardsTmp = [...HELP_CARDS.filter((c) => c.IS_SUSTEM == 0)]

    setCards(cardsTmp)
  }, [SelfUser, HELP_CARDS])

  return (
    <PageLayout
      topContent={isAdmn && <Button onClick={() => openPOP()}>Добавить карточку</Button>}
      fullWidthContent={
        <TaskWrapper>
          <MenuList>{menuItem()}</MenuList>
        </TaskWrapper>
      }
      isTopContent={isAdmn}
      isFullWidthContent
    />
  )
}

export default Help
