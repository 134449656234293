import { Button } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { POPbottom, POPscroll } from 'components/PopUp/index'
import { closePopUp, openPopUp } from 'features/actions/PopUpActions'

class POP_Alert extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let { text, buttons } = this.props
    if (buttons == null)
      buttons = [
        <Button color="success" onClick={() => this.props.closePopUp()}>
          Ок
        </Button>,
      ]
    else if (buttons.length == null) buttons = [buttons]
    return (
      <Content key={'Alert' + this.key} className="Content content-fluid">
        <POPscroll bottom={buttons != null}>
          <Text>{text}</Text>
          {this.props.content}
        </POPscroll>
        {!!buttons && (
          <POPbottom>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: 20,
                flexDirection: 'row',
              }}
            >
              {buttons}
            </div>
          </POPbottom>
        )}
      </Content>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (content) => dispatch(openPopUp(content)),
    closePopUp: (content) => dispatch(closePopUp(content)),
  }
}

const mapStateToProps = (store) => {
  return {
    page: store.page,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_Alert)

const Content = styled.div`
  width: 430px;
  max-width: calc(100% - 40px);
`

const Text = styled.p`
  text-align: center;
  padding-top: 50px;
  padding-bottom: 10px;
  font-weight: 300;
`
