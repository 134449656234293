import { Card } from '@mui/material'
import styled from 'styled-components'

import { GFlexCenter } from 'styles/global'

interface IChartComponentStyled {
  circleSize?: number
}
interface IMainCardStyled {
  color?: string
}

const ChartComponentStyled = styled.div<IChartComponentStyled>`
  position: relative;
  width: ${(props) => props.circleSize}px;
  height: ${(props) => props.circleSize}px;
`
const TotalCount = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  h4 {
    margin-bottom: 15px;

    font-size: 50px;
    font-weight: 600;
    line-height: 59px;
    text-align: center;
    color: #2a2a2a;
  }
`

const MainCard = styled(Card)`
  width: 483px;
  height: 310px;
  padding: 20px;
  border-radius: 10px;
  background: #ffffff;
`

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const Title = styled.div`
  margin-bottom: 20px;

  h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }
  h3 {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }
`

const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
`

const InfoWrapper = styled.div`
  ${GFlexCenter};
  height: 200px;
  flex-direction: column;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
`
const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-bottom: 15px;

  p {
    margin: 0 0 0 15px;
  }
`
const Color = styled.div<IMainCardStyled>`
  display: inline-flex;

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
  }
`
const Value = styled.div`
  display: inline-flex;

  p {
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
  }
`
const Label = styled.div`
  display: inline-flex;
  padding-top: 5px;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
`

export {
  ChartComponentStyled,
  TotalCount,
  MainCard,
  TitleWrapper,
  Title,
  ChartWrapper,
  InfoWrapper,
  InfoContainer,
  Info,
  Color,
  Label,
  Value,
}
