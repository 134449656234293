import React from 'react'
import { TextField } from '@mui/material'
import styled from 'styled-components'

import { GlobalFunctions } from 'utils/GlobalFunctions'

export default class Input extends React.Component {
  constructor() {
    super()
    this.state = {}
    this.key = GlobalFunctions.randomString()
    this.startValue = null

    this.input = {}
  }

  componentDidMount() {
    this.ready(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.ready(nextProps)
  }

  render() {
    const { title, name, type } = this.props
    return [
      type == 'textarea' ? (
        <TextField
          variant="filled"
          size="small"
          label={title}
          ref={this.input[name]}
          itemRef={this.input[name]}
          id={this.key}
          key={this.key + 'I'}
          Ref={this.input[name]}
          inputRef={this.input[name]}
          className="form-control"
          {...this.props}
        />
      ) : (
        <TextField
          variant="filled"
          size="small"
          label={title}
          Ref={this.input[name]}
          ref={this.input[name]}
          id={this.key}
          key={this.key + 'I'}
          itemRef={this.input[name]}
          inputRef={this.input[name]}
          {...this.props}
        />
      ),
    ]
  }
}

export const Label = styled.label`
  font-size: 11px;
  opacity: 0.7;
  display: block;
`
