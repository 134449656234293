import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { Bg, Pop } from './styles'

import { GlobalFunctions } from 'utils/GlobalFunctions'
import { closePopUp } from 'features/actions/PopUpActions'

const PopUp = (props) => {
  const { zIndex, closePopUp, children } = props
  const [visible, setVisible] = useState(false)
  const popUpRef = useRef(null)

  let key = GlobalFunctions.randomString()

  const onClosePopUp = () => {
    closePopUp()
    setVisible(false)
  }

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 1)
  }, [])

  return [
    <Bg onClick={onClosePopUp} zIndex={zIndex} />,
    <Pop key={key} ref={popUpRef} visible={visible} zIndex={zIndex}>
      <IconButton
        className="float-end"
        size="small"
        onClick={onClosePopUp}
        style={{
          position: 'absolute',
          right: 5,
          zIndex: 15,
          top: 5,
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
      {children}
    </Pop>,
  ]
}

const mapDispatchToProps = (dispatch) => {
  return {
    closePopUp: () => dispatch(closePopUp()),
  }
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUp)

export function POPscroll(props) {
  return (
    <div
      style={{
        maxHeight: 'calc(90vh - 80px)',
        overflowY: 'auto',
        alignItems: 'center',
        overflowX: 'hidden',
        margin: -25,
        padding: 25,
        paddingTop: props.top ? 55 + 20 : 25,
        paddingBottom: props.bottom ? 55 + 20 : 25,
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}

export function POPbottom(props) {
  return (
    <div
      style={{
        position: 'absolute',
        height: 55,
        bottom: 0,
        left: 0,
        zIndex: 9999999,
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'absolute',
          height: 55,
          bottom: 0,
          transition: '.3s',
          overflow: 'hidden',
          left: 0,

          background: 'rgba(255,255,255,0.8)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          width: '100%',
          ...props.style,
        }}
      >
        <hr
          style={{
            margin: 0,
            padding: 0,
            marginLeft: 25,
            marginRight: 25,
          }}
        />
        <div
          style={{
            padding: 25,
            paddingTop: 10,
            paddingBottom: 10,
            display: 'flex',
            gap: 20,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  )
}

export function POPgap(props) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 20,
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}

export function POPtop(props) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        height: 55,
        overflow: 'hidden',
        left: 0,
        zIndex: 10,
        background: 'rgba(255,255,255,0.8)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',

        width: '100%',
        ...props.style,
      }}
    >
      <div
        style={{
          padding: 25,
          paddingTop: 10,
          paddingBottom: 10,
          display: 'flex',
          gap: 20,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {props.children}
      </div>

      <hr
        style={{
          margin: 0,
          padding: 0,
          marginLeft: 25,
          marginRight: 25,
          bottom: 0,
          position: 'absolute',
          width: 'calc(100% - 50px)',
        }}
      />
    </div>
  )
}
