import { Button, Chip, IconButton } from '@mui/material'
import React, { memo } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { openPopUp } from 'features/actions/PopUpActions'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import moment from 'moment'
import 'moment/locale/ru'
import POP_Project from 'pages/Tasks/POP_Project'
import POP_Task from 'pages/Tasks/POP_Task'

const monthsGroup = {}

class Index extends React.Component {
  constructor() {
    super()
    this.state = {
      isHidden: [],
      monthsGroup_data: [],
    }
    this.input = []

    this.canReRender = true
    this.memo = {
      renderData: <div />,
    }

    this.montsScroll = null
    this.CurrentMonth = {
      month: moment().format('MM') * 1,
      year: moment().format('YYYY') * 1,
    }

    const endMonth = {
      month: this.CurrentMonth.month,
      year: this.CurrentMonth.year + 1,
    }

    let currentMonth = {
      month: 1,
      year: 2022,
    }
    while (currentMonth.year * 100 + currentMonth.month < endMonth.year * 100 + endMonth.month) {
      monthsGroup[currentMonth.year * 100 + currentMonth.month] = {
        month: currentMonth.month,
        year: currentMonth.year,
        title: moment(`${currentMonth.month}.${currentMonth.year}`, 'M.YYYY').locale('ru').format('MMMM'),
      }
      currentMonth.month++
      if (currentMonth.month > 12) {
        currentMonth.month = 1
        currentMonth.year++
      }
    }
  }

  componentDidMount() {
    this.setState({ monthsGroup_data: this.renderData() })
  }

  renderData() {
    if (!this.canReRender) return this.memo.renderData

    this.canReRender = false

    const { props } = this
    const { BACKLOG } = props
    const monthsGroup_data = JSON.parse(JSON.stringify(monthsGroup))

    let WORK_COAST = 0
    let WORK_HOURS = 0

    BACKLOG.forEach((task) => {
      if (task.WORK_COASTS != null && task.WORK_COASTS.length > 0) {
        task.WORK_COASTS.forEach((wc) => {
          let DATE_CREATE = moment(wc).format('YYYYMM') * 1
          if (monthsGroup_data[DATE_CREATE] != null) {
            if (monthsGroup_data[DATE_CREATE].data == null) {
              monthsGroup_data[DATE_CREATE].data = {
                ALL_COAST: wc.COAST,
                ALL_HOURS: wc.HOURS,
                WORK_COASTS: [wc],
              }
            } else {
              monthsGroup_data[DATE_CREATE].data.ALL_COAST += wc.COAST
              monthsGroup_data[DATE_CREATE].data.ALL_HOURS += wc.HOURS
              monthsGroup_data[DATE_CREATE].data.WORK_COASTS.push(wc)
            }
          }
          WORK_COAST += wc.COAST
          WORK_HOURS += wc.HOURS
        })
      }
    })

    setTimeout(() => {
      this.canReRender = true
    }, 100)

    this.memo.renderData = monthsGroup_data
    return monthsGroup_data
  }

  render() {
    const { props, state } = this
    const { PROJECTS, BACKLOG } = props
    const monthsGroup_data = state.monthsGroup_data

    let lastYear = null

    return (
      <MainView>
        <div
          style={{
            position: 'fixed',
            boxShadow: '0px 5px 10px rgb(0 0 0 / 5%)',
            top: 60,
            left: 'calc(40vw + 230px)',
            width: 'calc(60vw - 215px)',
            zIndex: 9,
            overflowX: 'auto',
            backgroundColor: 'white',
          }}
          className="topMonts"
          ref={(ref) => (this.montsScroll = ref)}
        >
          <table>
            <thead>
              <tr>
                {Object.keys(monthsGroup_data).map((key) => {
                  let lastYearView = null
                  if (lastYear != monthsGroup_data[key].year) {
                    lastYear = monthsGroup_data[key].year
                    lastYearView = lastYear
                  }
                  return (
                    <td className="month">
                      {lastYearView} {monthsGroup_data[key].title}
                    </td>
                  )
                })}
              </tr>
            </thead>
          </table>
        </div>

        <div
          style={{
            height: 'calc(100vh - 50px)',
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
            }}
          >
            <div
              style={{
                boxShadow: '10px 0px 20px rgba(0,0,0,0.07)',
                zIndex: 10,
                background: 'color(srgb 0.9112 0.9258 0.9344)',
              }}
            >
              <table
                style={{
                  marginTop: 10,
                  width: '40vw',
                }}
              >
                <thead>
                  <tr>
                    <td>Статус</td>
                    <td colSpan={2}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <p style={{ margin: 0, padding: 0 }}>Описание</p>
                        <Button
                          key="myBtn"
                          size="small"
                          onClick={() => {
                            if (state.isHidden.length == PROJECTS.length) this.setState({ isHidden: [] })
                            else this.setState({ isHidden: PROJECTS.map((p) => p.ID) })
                          }}
                        >
                          {state.isHidden.length == PROJECTS.length ? 'Развернуть все' : 'Свернуть все'}
                        </Button>
                      </div>
                    </td>
                  </tr>
                </thead>
                {PROJECTS.map((proj) => {
                  let hidden = state.isHidden.includes(proj.ID)
                  return (
                    <>
                      <tr className="project">
                        <td>
                          <Chip
                            size="small"
                            label={proj.STATUS_TITLE}
                            style={{
                              backgroundColor: proj.STATUS_COLOR,
                              color: 'white',
                            }}
                          />
                        </td>
                        <td
                          className="longText big"
                          onClick={() => {
                            this.props.openPopUp(
                              <POP_Project callBack={() => this.componentDidMount()} ID_OBJECT={proj.ID} />,
                            )
                          }}
                        >
                          <div
                            style={{
                              height: 40,
                              overflow: 'hidden',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div>
                              <small className="d-block m-0 p-0">{proj.COMPANY_TITLE}</small>
                              <p className="d-block m-0 p-0">{proj.TITLE}</p>
                            </div>
                            <p className="d-block m-0 p-0">
                              {GF.makeCoast(
                                (() => {
                                  let ALL_COAST = 0
                                  props.BACKLOG.forEach((b) => {
                                    if (b.PROJECT.ID == proj.ID) ALL_COAST += b.ALL_COAST
                                  })
                                  return ALL_COAST
                                })(),
                              )}
                            </p>
                          </div>
                        </td>
                        <td>
                          <IconButton
                            onClick={() => {
                              let index = state.isHidden.findIndex((p) => p == proj.ID)
                              let tmp = [...state.isHidden]
                              if (index == -1) {
                                tmp.push(proj.ID)
                              } else {
                                tmp.splice(index, 1)
                              }

                              this.setState({ isHidden: tmp })
                            }}
                          >
                            <ExpandMoreIcon
                              style={{
                                translate: '.3s',
                                transform: hidden ? 'rotate(180deg)' : 'rotate(0deg)',
                              }}
                            />
                          </IconButton>
                        </td>
                      </tr>

                      {!hidden &&
                        BACKLOG.map((task) =>
                          task.PROJECT.ID != proj.ID ? null : (
                            <tr>
                              <td
                                style={{
                                  backgroundColor: task.STATUS_COLOR,
                                  fontSize: 12,
                                  opacity: 0.5,
                                  color: 'rgba(255, 255, 255, 0.9)',
                                }}
                              >
                                {task.STATUS_TITLE}
                              </td>
                              <td
                                className="longText small"
                                colSpan={2}
                                onClick={() => {
                                  this.props.openPopUp(
                                    <POP_Task callBack={() => this.componentDidMount()} ID_OBJECT={task.ID} />,
                                  )
                                }}
                              >
                                {task.TEXT}
                              </td>
                            </tr>
                          ),
                        )}
                    </>
                  )
                })}
              </table>
            </div>

            <div
              style={{
                position: 'fixed',
                bottom: 50,
                left: 800,
                width: '30vw',
                backgroundColor: ' rgba(255, 255, 255, 0.71)',
                padding: '10px 20px',
                paddingTop: 15,
                borderRadius: 10,
                WebkitBackdropFilter: 'blur(10px)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                ref={(ref) => (this.inputVal = ref)}
                onChange={(e) => {
                  this.montsScroll.scrollLeft = this.montsScroll.scrollWidth * (e.target.value / 100)
                  this.tasksScroll.scrollLeft = this.montsScroll.scrollLeft
                }}
                style={{
                  width: '100%',
                }}
              />
            </div>

            <div
              style={{
                overflowX: 'auto',
                marginRight: -25,
                width: 'calc(60vw - 215px)',
              }}
              className="taskScroll"
              ref={(ref) => (this.tasksScroll = ref)}
              onScroll={(e) => {
                this.montsScroll.scrollLeft = e.target.scrollLeft
                this.inputVal.value = Math.floor((e.target.scrollLeft / this.montsScroll.scrollWidth) * 100)
              }}
            >
              <table
                style={{
                  marginTop: 16,
                  width: '40vw',
                }}
                className="byMonths"
              >
                <thead>
                  <tr>
                    {Object.keys(monthsGroup_data).map(() => {
                      return <td className="month"></td>
                    })}
                  </tr>
                </thead>
                {PROJECTS.map((proj) => {
                  let hidden = state.isHidden.includes(proj.ID)
                  return (
                    <>
                      <tr className="project">
                        {(() => {
                          let result = []

                          Object.keys(monthsGroup_data).forEach((key) => {
                            let COAST = 0
                            let HOURS = 0
                            if (monthsGroup_data[key].data != null && monthsGroup_data[key].data.WORK_COASTS != null) {
                              monthsGroup_data[key].data.WORK_COASTS.forEach((wc) => {
                                if (wc.ID_PROJECT == proj.ID) {
                                  COAST += wc.COAST
                                  HOURS += wc.HOURS
                                }
                              })
                            }

                            result.push(
                              COAST == 0 ? <td className="big"></td> : <td className="big">{GF.makeCoast(COAST)}</td>,
                            )
                          })

                          return result
                        })()}
                      </tr>

                      {!hidden &&
                        BACKLOG.map((task) =>
                          task.PROJECT.ID != proj.ID ? null : (
                            <tr>
                              {(() => {
                                let result = []

                                Object.keys(monthsGroup_data).forEach((key) => {
                                  let COAST = 0
                                  let HOURS = 0
                                  if (
                                    monthsGroup_data[key].data != null &&
                                    monthsGroup_data[key].data.WORK_COASTS != null
                                  ) {
                                    monthsGroup_data[key].data.WORK_COASTS.forEach((wc) => {
                                      if (wc.ID_TASK == task.ID) {
                                        COAST += wc.COAST
                                        HOURS += wc.HOURS
                                      }
                                    })
                                  }

                                  result.push(
                                    COAST == 0 ? (
                                      <td className="coast"></td>
                                    ) : (
                                      <td className="coast">{GF.makeCoast(COAST)}</td>
                                    ),
                                  )
                                })

                                return result
                              })()}
                            </tr>
                          ),
                        )}
                    </>
                  )
                })}
              </table>
            </div>
          </div>
        </div>
      </MainView>
    )
  }
}

const MainView = styled.div`
  margin-top: -20px;
  position: relative;
  margin-left: -20px;
  margin-right: -30px;
  margin-bottom: -20px;

  & .topMonts::-webkit-scrollbar {
    display: none;
  }

  & .taskScroll::-webkit-scrollbar {
    height: 10px;
  }

  & tr {
    vertical-align: middle;
    border-bottom: 1px solid rgb(222, 222, 222);
  }

  & td {
    padding: 3px;
    border: 1px solid rgb(222, 222, 222);
  }

  & .month {
    width: 120px;
    min-width: 90px;
    text-align: center;
    font-size: 12px;
    height: 31px;
  }

  & .longText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 30vw;
  }

  & .small {
    font-size: 13px;
    height: 31px;
  }

  & .big {
    font-weight: 500;
    font-size: 14px;
    height: 60px;
  }

  & .project {
    background: white;
  }

  & .byMonths .big {
  }

  & .byMonths .coast {
    height: 31px;
  }
`

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
  }
}

const mapStateToProps = (store) => {
  return {
    COMPANYS: store.page.COMPANYS,
    STATUSES: store.page.STATUSES,
    PROJECTS: store.page.PROJECTS,
    BACKLOG: store.page.BACKLOG,
    user: store.user.data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(memo(Index)))
