import { UploadFile } from '@mui/icons-material'
import { Chip, IconButton } from '@mui/material'
import { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { openPopUp } from 'features/actions/PopUpActions'
import POP_Doc from 'pages/Tasks/POP_Doc'

export default function DocumentsList({ idProject }) {
  const [files, setFiles] = useState([])
  const DOCUMENTS = useSelector((store) => store.page.DOCUMENTS)

  useLayoutEffect(() => {
    setFiles(DOCUMENTS.filter((d) => d.ID_PROJECT == idProject))
  }, [DOCUMENTS, idProject])

  const dispatch = useDispatch()
  const openFile = useCallback(
    (id) => {
      dispatch(openPopUp(<POP_Doc ID_OBJECT={id} ID_PROJECT={idProject} />))
    },
    [idProject],
  )

  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.24)',
        borderRadius: 4,
        padding: 14,
        minHeight: 40,
        position: 'relative',
        width: '100%',
        marginTop: 20,
      }}
    >
      <p
        style={{
          position: 'absolute',
          transform: 'scale(0.75)',
          top: -14,
          left: -10,
          paddingLeft: 5,
          paddingRight: 5,
          background: 'white',
        }}
      >
        Докумнты проекта
      </p>

      <IconButton
        style={{
          top: 5,
          right: 0,
          position: 'absolute',
          zIndex: 10,
        }}
        onClick={() => openFile()}
      >
        <UploadFile />
      </IconButton>

      {files.length == 0 ? (
        <>
          <p
            style={{
              color: 'gray',
              margin: 0,
            }}
          >
            Нет файлов проекта
          </p>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            gap: 5,
            flexWrap: 'wrap',
            paddingRight: 30,
          }}
        >
          {files.map((file) => (
            <Chip size="small" key={'file' + file.ID} label={file.TITLE} onClick={() => openFile(file.ID)} />
          ))}
        </div>
      )}
    </div>
  )
}
