import React from 'react'
import { InputAdornment, TextField } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { StandardTextFieldProps } from '@mui/material/TextField/TextField'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'

import { SearchFieldStyled } from 'components/UI/inputs/SearchField/styles'

interface ISearchField extends StandardTextFieldProps {
  onChange?: StandardInputProps['onChange']
  onIconClick?: () => void
}

const SearchField = (props: ISearchField) => {
  const { onChange, onIconClick } = props

  return (
    <SearchFieldStyled isIconClick={!!onIconClick}>
      <TextField
        label="Поиск"
        variant="outlined"
        size="small"
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon onClick={onIconClick} />
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </SearchFieldStyled>
  )
}

export default SearchField
