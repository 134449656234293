import React from 'react'
import styled from 'styled-components'

class Table extends React.Component {
  constructor() {
    super()
    this.state = {
      errorMSG: null,
    }
    this.input = []
  }

  render() {
    const { rows, renderItem, data, itemOnClick, rowOnClick, className, canCheck, checkBoxs } = this.props

    return (
      <TableView className={className}>
        <TableObj className="table">
          <thead>
            <tr>
              {!canCheck ? null : <td width="16"></td>}
              {rows.map((row, index) => (
                <td
                  colSpan={row.colSpan != null ? row.colSpan : 1}
                  width={row.width != null ? row.width : null}
                  onClick={(e) => {
                    e.stopPropagation()
                    try {
                      rowOnClick({
                        data: row,
                        index,
                      })
                    } catch (e) {}
                  }}
                >
                  {row.title}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                onClick={(e) => {
                  e.stopPropagation()
                  try {
                    itemOnClick({
                      data: item,
                      index,
                    })
                  } catch (e) {}
                }}
              >
                {!canCheck ? null : (
                  <td>
                    <input
                      key={'checkBox_' + item.ID}
                      ref={(ref) => (checkBoxs[item.ID] = ref)}
                      class="form-check-input ms-1"
                      type="checkbox"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </td>
                )}
                {renderItem({
                  data: item,
                  index,
                })}
              </tr>
            ))}
          </tbody>
        </TableObj>
      </TableView>
    )
  }
}

const Menu = styled.img`
  height: 16px;
  width: 20px;

  margin-top: -3px;
  transition: 0.3s;
  opacity: 0;
`

const TableObj = styled.table`
  margin: 0;
  border-radius: 5px;
  overflow: hidden;

  background-color: rgb(250, 250, 250);

  & tbody tr td {
    transition: 0.3s;
    font-size: 12px;
    cursor: pointer;
    vertical-align: middle;
  }

  & > tbody > tr:nth-of-type(odd) {
    background-color: white;
  }

  & > tbody > tr:hover td {
    background-color: rgb(219, 227, 231) !important;
  }

  & > :not(caption) > * > * {
    box-shadow: none;
  }

  & > :not(:last-child) > :last-child > * {
    border-bottom-color: rgb(168, 168, 168);
    line-height: 13px;
    font-size: 14px;
    vertical-align: middle;
  }

  & tr:hover ${Menu} {
    opacity: 0.3;
  }
`

const TableView = styled.div`
  border-radius: 10px;
  border: 1px solid rgb(222, 226, 230);
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.03);
  overflow: hidden;
  display: inline;
`

export default Table
