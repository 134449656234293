import moment from 'moment/moment'

interface IFilterByStatus {
  data: any[]
  id: number
  isLastStatus?: boolean
}

export const filterChats = (value: string, chats: any[]) => {
  if (!(chats && chats.length)) return []

  const lowerCaseValue = value.toLowerCase()

  return chats.filter((chat) => {
    const isFIO = chat.DIALOG_USERS.some((user: { FIO: string }) => user.FIO.toLowerCase().includes(lowerCaseValue))
    const isCompanyTitle = chat.COMPANY.TITLE.toLowerCase().includes(lowerCaseValue)

    return isFIO || isCompanyTitle
  })
}
export const filterProjects = (value: string, projects: any[]) => {
  if (!(projects && projects.length)) return []

  const lowerCaseValue = value.toLowerCase()

  return projects.filter((project) => {
    const isCompanyTitle = project.COMPANY_TITLE.toLowerCase().includes(lowerCaseValue)
    const isTitle = project.TITLE.toLowerCase().includes(lowerCaseValue)

    return isCompanyTitle || isTitle
  })
}
export const formatDate = (dateString: moment.MomentInput) => {
  if (!dateString) return ''

  const date = moment(dateString)
  const now = moment()

  // Checking if it's today
  if (date.isSame(now, 'day')) {
    return date.format('HH:mm')
  }

  // Check if it's yesterday
  if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
    return 'вчера'
  }

  // Format the date in "day of the month"
  return date.format('D MMMM')
}

export const filterByStatus = (props: IFilterByStatus) => {
  const { data, id, isLastStatus } = props

  return data.filter((d: { ID_STATUS: number; ID_LAST_USER?: number }) => {
    if (isLastStatus) return d.ID_LAST_USER === id
    return d.ID_STATUS === id
  }).length
}

export const sortArrByLastChangeDate = (data: Array<any>, sort?: 'ASC' | 'DESC') => {
  if (!data) return []

  return data.sort((a, b) => {
    const dateA = new Date(a.LAST_CHANGE_DATE)
    const dateB = new Date(b.LAST_CHANGE_DATE)

    const dateMore = sort === 'ASC' ? dateA < dateB : dateA > dateB
    const dateLess = sort === 'ASC' ? dateA > dateB : dateA < dateB

    if (dateMore) {
      return -1
    } else if (dateLess) {
      return 1
    } else {
      return 0
    }
  })
}
