import React from 'react'
import { connect } from 'react-redux'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { TreeItem, TreeView } from '@mui/lab'
import {
  Alert,
  Autocomplete,
  Avatar,
  Breadcrumbs,
  Button,
  Chip,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  Tooltip,
} from '@mui/material'
import moment from 'moment'

import {
  Card,
  ChatSelected,
  FilterWrapper,
  ProjectsWrapper,
  TaskStatus,
  TasksWrapper,
  TaskText,
  TitleWrapper,
  TopWrapper,
} from './styles'

import { filterProjects } from 'utils/functions'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import { getBACKLOG } from 'features/actions/PageActions'
import { openPopUp } from 'features/actions/PopUpActions'
import POP_Project from 'pages/Tasks/POP_Project'
import POP_Task from 'pages/Tasks/POP_Task'
import PageLayout from 'components/UI/layouts/PageLayout'
import SearchField from 'components/UI/inputs/SearchField/SearchField'
import ButtonFilter from 'components/UI/button/ButtonFilter'
import ButtonCreate from 'components/UI/button/ButtonCreate'

class Tasks extends React.Component {
  constructor(props) {
    super(props)
    this.TASKS_STATUSES = props.STATUSES.filter((s) => s.ENTITY == 'TASKS')

    this.state = {
      expanded: props.COMPANYS.map((c) => c.ID),
      errorMSG: null,
      selectedCompanys: props.user.ROLE == 'CLEN' ? props.COMPANYS.filter((c) => c.ID != 1) : props.COMPANYS,
      selectedCompany: '',
      searchedProjects: props.PROJECTS,
      ProjDeSelectedIds: [],
      viewStatuses: this.TASKS_STATUSES.filter((s) => s.ID == 5 || s.ID == 6 || s.ID == 7 || s.ID == 10),

      isShowFilter: false,
    }
    this.input = []

    this.updateTimer = null
  }

  componentDidMount() {
    this.updateTimer = setInterval(() => {
      this.props.getBACKLOG()
    }, 10000)
  }

  componentWillUnmount() {
    clearInterval(this.updateTimer)
  }

  renderView() {
    const { COMPANYS, user, PROJECTS, BACKLOG } = this.props
    const { selectedCompanys, searchedProjects, ProjDeSelectedIds } = this.state
    let PROJECTS_sorted = searchedProjects.slice(0).sort((a, b) => a.ID_COMPANY - b.ID_COMPANY)

    return {
      projects: () => {
        var Company = null
        let data = []
        PROJECTS_sorted.forEach((p) => {
          if (
            selectedCompanys.findIndex((c) => c.ID == p.ID_COMPANY) == -1 ||
            (user.ROLE != 'ADMN' && p.ID_STATUS == 9)
          )
            return null

          let isProjSelected = ProjDeSelectedIds.findIndex((prj) => prj == p.ID)

          var isNewCompany = p.ID_COMPANY != Company
          Company = p.ID_COMPANY
          var comp = {}
          if (isNewCompany) {
            comp = COMPANYS.find((c) => c.ID == p.ID_COMPANY)
          }
          let isLongTitle = p.TITLE.length > 30

          var content = (
            <MenuItem
              className="projects"
              onClick={() => {
                this.setState((prevState) => ({
                  ...prevState,
                  ProjDeSelectedIds: [p.ID],
                  selectedCompany: p,
                }))
              }}
            >
              <Chip
                style={{
                  color: 'white',
                  backgroundColor: isProjSelected != -1 ? p.STATUS_COLOR : 'rgb(190,190,190)',
                }}
                label={'#' + p.ID}
                size="small"
                className="me-2"
              />
              <ListItemText>
                <b
                  style={{
                    fontSize: 12,
                  }}
                >
                  {p.TITLE.substr(0, 30)}
                  {p.TITLE.length > 30 ? '..' : null}
                </b>
              </ListItemText>
              <IconButton
                className="DetalButton"
                size="small"
                type="text"
                onClick={(e) => {
                  e.stopPropagation()
                  this.props.openPopUp(<POP_Project ID_OBJECT={p.ID} />)
                }}
              >
                {' '}
                <MoreVertIcon fontSize="inherit" />{' '}
              </IconButton>
            </MenuItem>
          )

          if (isLongTitle)
            content = (
              <Tooltip title={'..' + p.TITLE.substr(30)} placement="right">
                {content}
              </Tooltip>
            )
          if (isNewCompany)
            data.push({
              company: comp,
              projects: [content],
            })
          else data.at(-1).projects.push(content)
        })
        try {
          return data.map((d) => {
            let tasks = BACKLOG.filter((t) => t.PROJECT.ID_COMPANY == d.company.ID)
            let closedTasks = tasks.filter((t) => t.ID_STATUS == 8 || t.ID_STATUS == 11).length

            return (
              <TreeItem
                nodeId={d.company.ID}
                label={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 13,
                        lineHeight: '14px',
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      {d.company.TITLE}
                    </p>
                    <p
                      style={{
                        width: 150,
                        marginTop: 10,
                        marginBottom: 10,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        fontSize: 12,
                        fontWeight: 'bold',
                      }}
                    >
                      {closedTasks} / {tasks.length}
                    </p>
                  </div>
                }
              >
                {d.projects}
              </TreeItem>
            )
          })
        } catch (e) {
          // console.error('TreeItem', e)
          return null
        }
      },

      backlog: () => {
        return BACKLOG.map((task) => {
          try {
            if (
              selectedCompanys.findIndex((c) => c.ID == task.COMPANY?.ID) == -1 ||
              ProjDeSelectedIds.findIndex((prj) => prj == task.PROJECT?.ID) == -1 ||
              this.state.viewStatuses.findIndex((s) => s.ID == task.ID_STATUS) == -1
            )
              return null
          } catch (e) {
            // console.error('BACKLOG.map', e)
            return null
          }

          let haveDEADLINE = task.DATE_DEADLINE != null
          let DATE_DEADLINE = null
          let TIME_DEADLINE = null
          let DEADLINE_type = null
          const DATE_CREATE = task.DATE_CREATE ? GF.makeNormalDate(task.DATE_CREATE, 'dd.mm.yyyy') : ''

          if (haveDEADLINE) {
            DATE_DEADLINE = GF.makeNormalDate(task.DATE_DEADLINE, 'dd.mm.yyyy')
            TIME_DEADLINE = GF.makeNormalDate(task.DATE_DEADLINE, 'H:M')

            if (moment().isAfter(moment(task.DATE_DEADLINE))) DEADLINE_type = 'CUR'

            if (GF.makeNormalDate(new Date(), 'dd.mm.yyyy') == DATE_DEADLINE) {
              DATE_DEADLINE = 'Сегодня'
              DEADLINE_type = 'CUR'
            } else {
              let daysLag = Math.ceil(
                (new Date().getTime() - new Date(GF.makeNormalDate(task.DATE_DEADLINE, 'mm/dd/yyyy H:M')).getTime()) /
                  (1000 * 3600 * 24),
              )
              if (daysLag >= -7 && daysLag <= 0) DEADLINE_type = 'WEK'
            }
          }

          return [
            <MenuItem
              className="d-block"
              onClick={() => {
                this.props.openPopUp(<POP_Task ID_OBJECT={task.ID} />)
              }}
            >
              <TaskStatus style={{ top: 10, right: 15 }} statusColor={task.STATUS_COLOR}>
                <div>
                  <Chip size="small" className="float-end" label={task.STATUS_TITLE} />
                </div>
              </TaskStatus>

              <TaskStatus style={{ top: 40, right: 15 }}>
                {task.AUTHOR.ID_USER == user.ID ? (
                  <div>
                    <Chip size="small" color="success" label="Я автор" />
                  </div>
                ) : null}
                {task.LAST_HUMAN == null ? null : (
                  <div className="ml-10">
                    <Chip
                      size="small"
                      className="float-end"
                      avatar={
                        task.LAST_HUMAN.IMAGES_URL == null ? (
                          <Avatar>{task.LAST_HUMAN.FIO[0]}</Avatar>
                        ) : (
                          <Avatar src={task.LAST_HUMAN.IMAGES_URL[0]} alt={task.LAST_HUMAN.LOGIN[0]} />
                        )
                      }
                      label={task.LAST_HUMAN.ID_USER == user.ID ? 'Назначено мне' : GF.FIO(task.LAST_HUMAN.FIO, 2)}
                    />
                  </div>
                )}
              </TaskStatus>

              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <small>
                  {task.COMPANY.TITLE.substr(0, 50)} {task.COMPANY.TITLE.length > 50 ? '..' : ''}
                </small>
                <small>
                  {task.PROJECT.TITLE.substr(0, 50)} {task.PROJECT.TITLE.length > 50 ? '..' : ''}
                </small>
                {!haveDEADLINE ? null : (
                  <Chip
                    size="small"
                    color={
                      task.ID_STATUS == 8
                        ? 'default'
                        : DEADLINE_type == 'CUR'
                        ? 'error'
                        : DEADLINE_type == 'WEK'
                        ? 'warning'
                        : 'default'
                    }
                    label={`${DEADLINE_type == 'CUR' ? '🔥' : ''} ${
                      DEADLINE_type == null ? '' : 'до'
                    } ${DATE_DEADLINE}${DEADLINE_type != null ? ' ' : ' до '}${TIME_DEADLINE}`}
                  />
                )}
              </Breadcrumbs>

              <div>
                {task.PROJECT.ID_STATUS != 9 ? null : (
                  <Alert style={{ width: 'max-content' }} color="warning">
                    Проект завершен
                  </Alert>
                )}
              </div>

              <TaskText>
                {task.AUTHOR.ID_USER == 1 ? (
                  <p className="mb-3">
                    {task.TEXT.substr(0, 150)} {task.TEXT.length > 150 ? '..' : ''}
                  </p>
                ) : (
                  [
                    <p className="mb-5">
                      {task.TEXT.substr(0, 150)} {task.TEXT.length > 150 ? '..' : ''}
                    </p>,
                  ]
                )}

                <ChatSelected className="flex">
                  <div className="flex flex-col mr-10">
                    <Avatar alt={task.AUTHOR.FIO} src={task.AUTHOR.IMAGES_URL} />
                  </div>
                  <div className="flex flex-col flex-jcc">
                    <p>{task.AUTHOR.FIO}</p>
                  </div>
                </ChatSelected>
              </TaskText>

              <TaskStatus style={{ bottom: 10, right: 15 }}>
                <div>
                  <p>Начало</p>
                  <Chip size="small" color="success" label={DATE_CREATE} />
                </div>
                {haveDEADLINE && (
                  <div className="ml-10">
                    <p>Дедлайн</p>
                    <Chip size="small" color="success" label={DATE_DEADLINE} />
                  </div>
                )}
              </TaskStatus>
            </MenuItem>,
          ]
        })
      },
    }
  }

  onFilter() {
    this.setState((prevState) => ({
      ...prevState,
      isShowFilter: !this.state.isShowFilter,
    }))
  }
  onSearch(e, PROJECTS) {
    const value = e.target.value

    this.setState((prevState) => ({
      ...prevState,
      searchedProjects: filterProjects(value, PROJECTS),
    }))
  }

  render() {
    const { COMPANYS, user, PROJECTS, openPopUp } = this.props
    const { selectedCompanys, selectedCompany, searchedProjects, isShowFilter } = this.state

    return (
      <PageLayout
        topContent={
          <TopWrapper>
            <SearchField onChange={(e) => this.onSearch(e, PROJECTS)} />
            <ButtonFilter onClick={() => this.onFilter()} />

            {isShowFilter ? (
              <FilterWrapper>
                <Autocomplete
                  multiple
                  options={this.TASKS_STATUSES}
                  value={this.state.viewStatuses}
                  onChange={(event, newValue) => {
                    this.setState({ viewStatuses: newValue })
                  }}
                  limitTags={2}
                  getOptionLabel={(option) => option.TITLE}
                  defaultValue={this.state.viewStatuses}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Показать статусы" size="small" />
                  )}
                />
              </FilterWrapper>
            ) : (
              <></>
            )}

            {isShowFilter && user.ROLE != 'CLEN' ? (
              <FilterWrapper>
                <Autocomplete
                  multiple
                  options={COMPANYS}
                  value={selectedCompanys}
                  onChange={(event, newValue) => {
                    this.setState({ selectedCompanys: newValue })
                  }}
                  limitTags={2}
                  getOptionLabel={(option) => option.TITLE}
                  defaultValue={[COMPANYS[0]]}
                  size="small"
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Компании" size="small" />}
                />
              </FilterWrapper>
            ) : (
              <></>
            )}

            {user.ROLE != 'ADMN' && user.ROLE != 'CLEN' ? null : (
              <ButtonCreate onClick={() => openPopUp(<POP_Task />)}>Создать</ButtonCreate>
            )}
          </TopWrapper>
        }
        leftContent={
          <ProjectsWrapper>
            <TitleWrapper>
              <h5 className="mt-1">Все компании и проекты</h5>
              {user.ROLE != 'ADMN' ? null : (
                <Button variant="text" className="float-end" onClick={() => this.props.openPopUp(<POP_Project />)}>
                  Добавить
                </Button>
              )}
            </TitleWrapper>
            <Card className="mt-3">
              <MenuList dense>
                <TreeView
                  onNodeSelect={(e, nodeIds) => {
                    this.setState({
                      ProjDeSelectedIds: this.props.PROJECTS.filter((p) => p.ID_COMPANY == nodeIds).map((p) => p.ID),
                    })
                  }}
                  onNodeToggle={(e, nodeIds) => {
                    this.setState({ expanded: nodeIds })
                  }}
                  expanded={this.state.expanded}
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                >
                  {this.renderView().projects()}
                </TreeView>
              </MenuList>
            </Card>
          </ProjectsWrapper>
        }
        rightContent={
          <TasksWrapper>
            <TitleWrapper>
              <h5 className="mt-1">Задачи {selectedCompany.COMPANY_TITLE}</h5>
            </TitleWrapper>
            <Card className="mt-3">
              <MenuList>{this.renderView().backlog()}</MenuList>
            </Card>
          </TasksWrapper>
        }
        isRightFullHeight
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    getBACKLOG: (arg) => dispatch(getBACKLOG(arg)),
  }
}
const mapStateToProps = (store) => {
  return {
    user: store.user.data,
    COMPANYS: store.page.COMPANYS,
    STATUSES: store.page.STATUSES,
    PROJECTS: store.page.PROJECTS,
    BACKLOG: store.page.BACKLOG,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks)
