import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Avatar } from '@mui/material'

import { SButton, SCheckbox, SInputText, SLink, SText } from 'styles/global/components'
import { AvatarWrapper, InfoWrapper, ProfileView, ProfileWrapper, Role } from './styles'

import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import { useToast } from 'components/Toast/Toast'
import { openPopUp } from 'features/actions/PopUpActions'
import POP_PasswodChange from 'pages/UserProfile/POP_PasswodChange'

const UserProfile = (props) => {
  const { user, setEmail, openPopUp } = props
  const userNames = user?.FIO ? user.FIO.split(' ') : ['', '']
  let updateTimer = null
  const initialForm = {
    name: userNames[0] ?? '',
    surname: userNames[1] ?? '',
    phone: user.PHONE ?? '',
    email: user.EMAIL ?? '',
  }

  const toast = useToast()
  const [formState, setFormState] = useState(initialForm)
  const [isChange, setIsChange] = useState(false)

  const onFormChange = (e, name) => {
    const value = e.target.value
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const onFormSubmit = (e) => {
    e.preventDefault()

    if (isChange) {
      GF.API_task({
        operation: 'changeEmail',
        block: 'Auth',
        EMAIL: formState.email,
      }).then(() => {
        setEmail(formState.email)
      })

      setIsChange(false)

      toast({ title: 'Изменения сохранены (только E-mail)' })
    } else {
      setIsChange(true)
    }
  }
  const onFormCancel = () => {
    setFormState(initialForm)
    setIsChange(false)
  }

  useEffect(() => {
    return () => {
      clearTimeout(updateTimer)
    }
  }, [])

  return (
    <ProfileView>
      <div className="flex">
        <AvatarWrapper>
          {user.IMAGES_URL == null || user.IMAGES_URL[0] == null ? (
            <Avatar sx={{ width: 160, height: 160 }}>{user.LOGIN.substr(0, 4).toUpperCase()}</Avatar>
          ) : (
            <Avatar src={user.IMAGES_URL[0]} label={user.FIO} sx={{ width: 160, height: 160 }} />
          )}
        </AvatarWrapper>
        <ProfileWrapper className="flex">
          <form onSubmit={(e) => onFormSubmit(e)} className="flex flex-col">
            <div className="flex flex-row">
              <Role>
                <SText>{user.ROLE_TITLE}</SText>
              </Role>
            </div>
            <div className="flex flex-row">
              <SInputText
                label="Имя"
                margin="dense"
                value={formState.name}
                disabled={!isChange}
                onChange={(e) => onFormChange(e, 'name')}
              />
              <SInputText
                label="Фамилия"
                margin="dense"
                value={formState.surname}
                disabled={!isChange}
                onChange={(e) => onFormChange(e, 'surname')}
              />
            </div>
            <div className="flex flex-row">
              <SInputText
                label="Телефон"
                margin="dense"
                value={formState.phone}
                disabled={!isChange}
                onChange={(e) => onFormChange(e, 'phone')}
              />
              <SInputText
                label="E-mail"
                margin="dense"
                value={formState.email}
                disabled={!isChange}
                onChange={(e) => onFormChange(e, 'email')}
              />
            </div>
            <div className="flex flex-row">
              <SButton type="submit">{isChange ? 'Сохранить' : 'Изменить'}</SButton>
              <SButton onClick={() => openPopUp(<POP_PasswodChange />)}>Изменить пароль</SButton>
              {isChange && <SButton onClick={onFormCancel}>Отменить изменения</SButton>}
            </div>
          </form>
        </ProfileWrapper>
        <InfoWrapper className="flex flex-col">
          <div className="flex flex-row"></div>
          <div className="flex flex-row">
            <div className="flex flex-col mt-20">
              <SText>
                Здесь можно посмотреть или изменить личную информацию. Данные будут доступны всем пользователям этой
                системы, чтобы с вами было удобнее связаться.
              </SText>
              <div className="flex flex-row mt-20">
                <div className="flex flex-row">
                  <SCheckbox defaultChecked />
                </div>
                <div className="flex flex-col ml-10">
                  <SText>Я согласен с условиями политики конфидициальности</SText>
                  <SLink href="#">Подробнее</SLink>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row"></div>
          <div className="flex flex-row"></div>
        </InfoWrapper>
      </div>
    </ProfileView>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    setEmail: (payload) => dispatch({ type: 'setEmail', payload }),
  }
}
const mapStateToProps = (store) => {
  return {
    user: store.user.data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfile))
