import styled from 'styled-components'

const ProfileView = styled.div`
  width: 100%;
  height: 100%;
  max-height: 411px;
  padding: 25px;
  border-radius: 10px;
  background-color: white;
`

const AvatarWrapper = styled.div`
  margin-right: 25px;
`

const ProfileWrapper = styled.div`
  margin-right: 25px;
`
const Role = styled.div`
  width: Hug (139px);
  height: 32px;
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  background: #e3f0ff;

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
`

const InfoWrapper = styled.div`
  width: 484px;
`

export { ProfileView, AvatarWrapper, Role, ProfileWrapper, InfoWrapper }
