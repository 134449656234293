import React, { createContext, ReactNode, useContext, useState } from 'react'
import { AlertTitle } from '@mui/material'
import { AlertColor } from '@mui/material/Alert/Alert'

import { StyledAlert, ToastContainer, ToastFade } from './styles'

interface IToastProvider {
  children: ReactNode
}
interface IToastMessage {
  title?: string
  message: string
  severity?: AlertColor
}

const ToastContext = createContext(null)

const ToastProvider = ({ children }: IToastProvider) => {
  const [showToast, setShowToast] = useState(false)
  const [state, setState] = useState<IToastMessage>({
    title: '',
    message: '',
    severity: 'success',
  })

  const showToastMessage = ({ title, message, severity }: IToastMessage) => {
    setState((prevState) => ({
      ...prevState,
      title,
      message,
      severity,
    }))
    setShowToast(true)
    setTimeout(() => {
      setShowToast(false)
    }, 3000)
  }

  return (
    <ToastContext.Provider
      // @ts-ignore
      value={showToastMessage}
    >
      {children}
      {showToast && (
        <ToastContainer>
          <ToastFade>
            <StyledAlert severity={state.severity}>
              <AlertTitle>{state.title}</AlertTitle>
              {state.message}
            </StyledAlert>
          </ToastFade>
        </ToastContainer>
      )}
    </ToastContext.Provider>
  )
}

const useToast = () => {
  return useContext(ToastContext)
}

export { ToastProvider, useToast }
