import { Autocomplete, Avatar, Button, Chip, TextField } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import { getBACKLOG, getDB_Data } from 'features/actions/PageActions'
import { closePopUp, openPopUp } from 'features/actions/PopUpActions'

class POP_SelectEmploy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      DEPT: null,
      EMPLOY: props.selected_ID_EMPLOY == null ? null : props.EMPLOY.find((e) => e.ID == props.selected_ID_EMPLOY),
      TEXT: null,
    }
    this.input = []
  }

  render() {
    const { DEPTS, USERS, showTextField, allowSelfSelect, user } = this.props
    const { TEXT, DEPT, EMPLOY } = this.state
    let USERS_filterd = []
    if (DEPT != null)
      USERS.forEach((u) => {
        if (allowSelfSelect == false && u.ID == user.ID) return null

        let deptsFinded = u.DPTS.find((d) => d.ID == DEPT.ID)
        if (deptsFinded != null) {
          USERS_filterd.push({ ...u, localGrade: deptsFinded.GRADE })
        }
      })

    return (
      <POP>
        <h4>Выбор исполнителя</h4>

        <div className="mt-3" />
        <Autocomplete
          options={DEPTS}
          value={DEPT}
          size="small"
          getOptionLabel={(option) => option.TITLE}
          onChange={(event, newValue) => {
            this.setState({ DEPT: newValue, EMPLOY: null })
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Отдел" size="small" />}
        />
        <div className="mt-3" />
        <Autocomplete
          options={USERS_filterd}
          value={EMPLOY}
          disabled={DEPT == null}
          size="small"
          getOptionLabel={(option) => option.FIO}
          renderOption={(props, option) => (
            <div {...props}>
              <div
                style={{
                  width: 'max-content',
                  margin: 'auto',
                }}
              >
                <Chip
                  size="small"
                  avatar={
                    option.IMAGES_URL == null ? <Avatar>{option.FIO[0]}</Avatar> : <Avatar src={option.IMAGES_URL[0]} />
                  }
                  label={GF.FIO(option.FIO, 2)}
                />
                <small className="d-block mx-auto text-center w-100">{option.localGrade}</small>
              </div>
            </div>
          )}
          onChange={(event, newValue) => {
            this.setState({ EMPLOY: newValue })
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Исполнитель" size="small" />}
        />
        {showTextField == false ? null : (
          <TextField
            className="w-100 mt-3"
            margin="dense"
            size="small"
            multiline
            rows={5}
            label="Наставление к задаче"
            value={TEXT}
            onChange={(e) => {
              this.setState({ TEXT: e.target.value })
            }}
          />
        )}

        <Button
          className="mt-3 float-end"
          onClick={() => {
            this.props.closePopUp()
            this.props.callBack({
              USER: EMPLOY,
              TEXT,
            })
          }}
        >
          Выбрать
        </Button>
      </POP>
    )
  }
}

const POP = styled.div`
  width: 300px;
`

const mapDispatchToProps = (dispatch) => {
  return {
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    openPopUp: (arg) => dispatch(openPopUp(arg)),

    getBACKLOG: (arg) => dispatch(getBACKLOG(arg)),
    getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),
  }
}

const mapStateToProps = (store) => {
  return {
    DEPTS: store.page.DEPTS,
    USERS: store.page.USERS,
    user: store.user.data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_SelectEmploy)
