const initialState = {
  POPUPS: [],

  VARS: null, //статические переменные
  USERS: null,
  DEPTS: null,
  USER_STATUS: null,
  USER_ROLES: null,
  HUMANS: null,
  COMPANYS: null,
  STATUSES: null,
  PROJECTS: null,
  BACKLOG: null,
  HELP_CARDS: [],
  DOCUMENTS: [],
  CHATS: [],
  CHAT_USERS: [],
} //null - это пометка того, что это обязательные данные для старта системы

export function pageReducer(state = initialState, action) {
  switch (action.type) {
    case 'JUST_SET_VALUE':
      let temp_state = {}
      Object.assign(temp_state, state)
      temp_state[action.key] = action.payload

      return temp_state

    case 'GET_YC_SERVICES_AND_CATEGORIES':
      return {
        ...state,
        YC: {
          ...state.YC,
          ...action.payload,
        },
      }

    default:
      return state
  }
}
