import styled from 'styled-components'

interface IPopUpStyled {
  visible?: boolean
  zIndex: number
}

const Pop = styled.div<IPopUpStyled>`
  position: fixed;
  left: 50%;
  transition: 0.6s cubic-bezier(0.58, 1.47, 0.37, 0.94);
  top: ${(props: IPopUpStyled) => (props.visible ? '50%' : '51%')};
  opacity: ${(props: IPopUpStyled) => (props.visible ? '1' : '0')};
  height: auto;
  background: #fff;
  width: max-content;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  box-shadow: 5px 20px 150px rgba(0, 0, 0, 0.2);
  transform: translate(-50%, -50%);
  padding: 25px;
  z-index: ${(props: IPopUpStyled) => props.zIndex + 201};

  > .Content {
    margin: 20px;
  }
`
const Bg = styled.div<IPopUpStyled>`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: ${(props: IPopUpStyled) => props.zIndex + 200};
`

export { Pop, Bg }
