import styled from 'styled-components'

const MainView = styled.div`
  background: rgb(232, 236, 238);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`

export { MainView }
