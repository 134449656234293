import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Avatar } from '@mui/material'

import { IEnvIconEnum } from 'components/UI/images/types'

import { StyledAccount, StyledChip, AvatarWrapper, LabelWrapper, MenuWrapper, MenuOption } from './styles'
import EnvIcon from 'components/UI/images/EnvIcon'

import { GlobalFunctions } from 'utils/GlobalFunctions'
import useClickOutside from 'utils/hooks'

const Account = (props: any) => {
  const { user, logOut, loadUserData_FromStorage } = props
  const [isShowMenu, setIsShowMenu] = useState(false)
  const menuRef = useRef(null)

  const userData = {
    full_name: user?.FIO ?? '',
    role: user?.ROLE_TITLE ?? '',
  }

  const exitAk = () => {
    GlobalFunctions.deleteCookie('login')
    GlobalFunctions.deleteCookie('password')
    logOut()
    loadUserData_FromStorage()
  }

  const handleMenuClick = (event: any) => {
    event.stopPropagation()
    setIsShowMenu(!isShowMenu)
  }
  const handleOutsideClick = () => {
    setIsShowMenu(false)
  }

  useClickOutside({ ref: menuRef, callback: handleOutsideClick })

  return (
    <StyledAccount>
      <StyledChip
        label={
          <LabelWrapper>
            <p>{userData.full_name}</p>
            <p>{userData.role}</p>
          </LabelWrapper>
        }
        avatar={
          <AvatarWrapper>
            {user.IMAGES_URL == null ? (
              <Avatar>{user.LOGIN[0]}</Avatar>
            ) : (
              <Avatar src={user.IMAGES_URL[0]} alt={user.LOGIN[0]} />
            )}
          </AvatarWrapper>
        }
        onClick={handleMenuClick}
      />
      {isShowMenu && (
        <MenuWrapper ref={menuRef}>
          <MenuOption>
            <EnvIcon name={IEnvIconEnum.user} />
            <Link to="../user_profile" key="profile-link">
              Мой профиль
            </Link>
          </MenuOption>
          <MenuOption>
            <EnvIcon name={IEnvIconEnum.logout} />
            <Link to="" key="logout-link" onClick={() => exitAk()}>
              Выйти
            </Link>
          </MenuOption>
        </MenuWrapper>
      )}
    </StyledAccount>
  )
}

export default Account
