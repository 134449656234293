import styled from 'styled-components'
import { down, up } from 'styled-breakpoints'

import { IEnvImageEnum } from 'components/UI/images/types'

import { GFlexCenter } from 'styles/global'
import { envImagePath } from 'components/UI/images/envIconPath'

interface ILoginStyled {
  isLoaded?: boolean
  isLoadedALL?: boolean
}

const Page = styled.div<ILoginStyled>`
  overflow: hidden;
  height: 100vh;
  width: 100vw;

  ${(props) => (props.isLoadedALL ? 'background:rgba(0,0,0,0);' : 'background:rgba(0,0,0,1);')}
`
const PageContainer = styled.div`
  ${GFlexCenter};
  max-width: 1920px;
  padding: 30px;
  margin: 0 auto;
`

const LeftView = styled.div<ILoginStyled>`
  ${GFlexCenter};
  flex-direction: column;
  max-width: 788px;
  max-height: 748px;
  transition: 1s;

  h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    line-height: 47px;
    letter-spacing: 0;
    color: #2a2a2a;
  }

  ${down('md')} {
    ${(props) =>
      props.isLoaded
        ? `
    transform:scale(1);
    opacity:1;
    `
        : `
    transform:scale(1.5);
    opacity:0;
    `}
  }
  ${up('md')} {
    ${(props) =>
      props.isLoaded
        ? `
    transform:scale(1);
    opacity:1;
    `
        : `
    transform:scale(1.1);
    opacity:0;
    `}
  }
`
const BGimg = styled.div`
  background: url('${envImagePath({ name: IEnvImageEnum.bg })}');
  height: 100%;
  width: 100%;

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`
const DarkBg = styled.div`
  background: rgba(0, 0, 0, 0);
`

const RightView = styled.div<ILoginStyled>`
  ${GFlexCenter};
  max-width: 600px;
  max-height: 900px;

  ${(props) => (props.isLoadedALL ? `transition:1s;` : `transition:1s ease 1s;`)}
  ${(props) =>
    props.isLoaded
      ? `
transform:translateY(0);
opacity:1;
`
      : `
transform:translateY(50px);
opacity:0;
`}
`
const View = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`
const Img = styled.img`
  display: flex;
  justify-content: center;
  transition: 1s;
  width: 80px;
  right: 25px;
`

const LogoContainer = styled.div`
  margin-bottom: 60px;

  img {
    width: 135px;
    height: 72px;
  }
`
const TitleContainer = styled.div`
  margin-bottom: 15px;
  text-align: center;

  h2 {
    font-size: 26px;
  }
`
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding: 0 15px;
  width: 100%;

  .MuiFormControl-root {
    width: 100%;
    max-width: 550px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
  }
`
const RememberPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  margin-right: 50px;

  .MuiButton-root {
    background-color: inherit !important;
    border: none;
    color: #41a3da;

    :hover {
      color: #3089bb;
    }
  }
`
const LoginBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 15px;
  width: 100%;

  button {
    width: 100%;
    max-width: 499px;
    height: 46px;
    border-radius: 4px;
  }
`
const ErrorMsgContainer = styled.div`
  margin-top: 30px;
`

export {
  Page,
  PageContainer,
  LeftView,
  BGimg,
  DarkBg,
  RightView,
  View,
  Img,
  LogoContainer,
  TitleContainer,
  InputContainer,
  LoginBtnContainer,
  RememberPassword,
  ErrorMsgContainer,
}
