const GFlexDefault = {
  height: '100%',
  display: 'flex',
}
const GFlexCenter = {
  ...GFlexDefault,
  width: '100%',
  'justify-content': 'center',
  'align-items': 'center',
}
const GFlexWrapper = {
  ...GFlexDefault,
  width: '100%',
}
const GFlexCol = {
  ...GFlexDefault,
  'flex-direction': 'column',
}
const GFlexRow = {
  ...GFlexDefault,
  'flex-direction': 'row',
}

export { GFlexCenter, GFlexWrapper, GFlexCol, GFlexRow }
