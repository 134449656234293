import React from 'react'
import { connect } from 'react-redux'

import { IRootStoreState } from 'features/types'

import { MainView } from './styles'

import { openPopUp } from 'features/actions/PopUpActions'
import LeftMenu from 'components/Menu/LeftMenu'
import TopMenu from 'components/Menu/TopMenu'

const Main = () => {
  return (
    <MainView>
      <TopMenu />
      <LeftMenu />
    </MainView>
  )
}

const mapDispatchToProps = (dispatch: React.Dispatch<any>) => {
  return {
    openPopUp: (arg: any) => dispatch(openPopUp(arg)),
  }
}

const mapStateToProps = (store: IRootStoreState) => {
  return {
    page: store.page,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
