import { MutableRefObject, useEffect } from 'react'

interface IUseClickOutside {
  ref: MutableRefObject<null>
  callback: () => void
}

const useClickOutside = (props: IUseClickOutside) => {
  const { ref, callback } = props

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [ref, callback])
}

export default useClickOutside
