import { Alert } from '@mui/material'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ToastContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
`
const ToastFade = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out forwards;

  @keyframes ${fadeIn} {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
const StyledAlert = styled(Alert)`
  opacity: 1;
  transition: opacity 0.5s ease-in-out;

  &.alert-exit {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
`

export { ToastContainer, ToastFade, StyledAlert }
