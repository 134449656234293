import { GlobalFunctions } from 'utils/GlobalFunctions'
import { store } from 'features/store/configureStore'

export function loadUserData_FromStorage(content) {
  return (dispatch) => {
    let func = (mainObj, key, data) => {
      let tt = mainObj
      if (data != null) {
        tt[key] = data
      }
      return tt
    }

    let Data = {}
    Data = func(Data, 'login', GlobalFunctions.getCookie('login'))
    Data = func(Data, 'password', GlobalFunctions.getCookie('password'))

    dispatch({
      type: 'loadUserData_FromStorage',
      payload: Data,
    })

    global.afterSustemLoad()
  }
}

export function logOut(arg) {
  return (dispatch) => {
    dispatch({
      type: 'logOut',
    })
  }
}

export function loginIn() {
  return (dispatch) => {
    let userData = store.getState().user.data

    if (userData.login == null || userData.password == null) {
      // console.error('Данных для входа не обнаружено')
      dispatch({
        type: 'loginIn',
        payload: {
          isOk: false,
          data: {},
        },
      })
      return
    }

    GlobalFunctions.API_task({
      operation: 'loginIn',
      block: 'Auth',
      login: userData.login,
      password: userData.password,
    })
      .then((data) => {
        let isOk = false
        if (data != null) isOk = true
        try {
          data.IMAGES_URL = JSON.parse(data.IMAGES_URL)
        } catch (e) {}
        dispatch({
          type: 'loginIn',
          payload: {
            isOk,
            data: data,
          },
        })
        global.afterLoginned()
      })
      .catch(() => {
        dispatch({
          type: 'loginIn',
          payload: {
            isOk: false,
            data: {},
          },
        })
      })
  }
}
