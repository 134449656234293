import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withCookies } from 'react-cookie'
import { Alert, AlertTitle, Button, TextField } from '@mui/material'

import { IRootStoreState } from 'features/types'
import { IEnvIconEnum } from 'components/UI/images/types'

import { FlexChildDiv, FlexContainerCol, FlexContainerRow } from 'styles/global/components'
import {
  BGimg,
  DarkBg,
  ErrorMsgContainer,
  Img,
  InputContainer,
  LeftView,
  LoginBtnContainer,
  LogoContainer,
  Page,
  PageContainer,
  RememberPassword,
  RightView,
  TitleContainer,
  View,
} from './styles'
import { envIconPath } from 'components/UI/images/envIconPath'

import { GlobalFunctions } from 'utils/GlobalFunctions'
import { openPopUp } from 'features/actions/PopUpActions'
import { loadUserData_FromStorage } from 'features/actions/UserAct'
import Alert_pop from 'components/PopUp/Views/Alert'

interface ILogin {
  openPopUp: any
  cookies: any
  loadUserData_FromStorage: any
}

const Login = (props: ILogin) => {
  const { openPopUp, cookies, loadUserData_FromStorage } = props
  const input: any = []

  const [state, setState] = useState({
    errorMSG: null,
    isLoaded: false,
    isLoadedALL: false,
  })

  const passwordSendToMail = () => {
    if (!GlobalFunctions.checkReqvireds(input, ['PASSWORD'])) return

    GlobalFunctions.API_task({
      operation: 'ressetPass',
      block: 'Auth',
      LOGIN: encodeURIComponent(input.LOGIN.value),
    }).then((res) => {
      if (res == 'ok') {
        openPopUp(<Alert_pop text="Восстановленный пароль выслан на почту пользователя" />)
      }
    })
  }
  const signIN = () => {
    setState((prevState) => ({
      ...prevState,
      errorMSG: null,
    }))

    if (!GlobalFunctions.checkReqvireds(input)) return

    GlobalFunctions.API_task({
      operation: 'loginIn',
      block: 'Auth',
      password: encodeURIComponent(input.PASSWORD.value),
      login: encodeURIComponent(input.LOGIN.value),
    })
      .then((res) => {
        if (res == null) {
          setState((prevState: any) => ({
            ...prevState,
            errorMSG: 'Неверный логин или пароль',
          }))
          return
        }
        setState((prevState) => ({
          ...prevState,
          isLoaded: false,
          isLoadedALL: true,
        }))
        setTimeout(() => {
          cookies.set('login', res.LOGIN, { path: '/' })
          cookies.set('password', res.PASSWORD, { path: '/' })
          cookies.set('id', res.ID, { path: '/' })

          loadUserData_FromStorage()
        }, 1000)
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          errorMSG: error.message,
        }))
      })
  }

  useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isLoaded: true,
      }))
    }, 500)
  }, [])

  return (
    <Page className="Page Login bg-light" isLoadedALL={state?.isLoadedALL}>
      <PageContainer>
        <FlexContainerRow>
          <FlexChildDiv>
            <LeftView isLoaded={state?.isLoaded}>
              <h1>Добро пожаловать в Taskmanager</h1>
              <BGimg>
                <DarkBg />
              </BGimg>
            </LeftView>
          </FlexChildDiv>
          <FlexChildDiv>
            <RightView isLoaded={state?.isLoaded} isLoadedALL={state?.isLoadedALL}>
              <View>
                <FlexContainerCol>
                  <LogoContainer>
                    <Img src={envIconPath({ name: IEnvIconEnum.logo })} />
                  </LogoContainer>
                  <TitleContainer>
                    <h2>Войдите в аккаунт</h2>
                  </TitleContainer>
                  <InputContainer>
                    <TextField
                      label="Логин"
                      placeholder="Введите ваш логин"
                      variant="outlined"
                      size="small"
                      className="bg-light"
                      inputRef={(ref) => (input.LOGIN = ref)}
                    />
                  </InputContainer>
                  <InputContainer>
                    <TextField
                      label="Пароль"
                      placeholder="Введите ваш пароль"
                      type="password"
                      variant="outlined"
                      size="small"
                      className="bg-light mt-3 "
                      inputRef={(ref) => (input.PASSWORD = ref)}
                    />
                  </InputContainer>
                  <RememberPassword>
                    <Button variant="text" size="small" onClick={() => passwordSendToMail()}>
                      Не помню пароль
                    </Button>
                  </RememberPassword>
                  <LoginBtnContainer>
                    <Button variant="text" size="small" onClick={() => signIN()}>
                      Войти
                    </Button>
                  </LoginBtnContainer>
                  <ErrorMsgContainer>
                    {state?.errorMSG != null ? (
                      <Alert severity="error" className="mt-3">
                        <AlertTitle>Ошибка</AlertTitle>
                        {state?.errorMSG}
                      </Alert>
                    ) : null}
                  </ErrorMsgContainer>
                </FlexContainerCol>
              </View>
            </RightView>
          </FlexChildDiv>
        </FlexContainerRow>
      </PageContainer>
    </Page>
  )
}

const mapDispatchToProps = (dispatch: React.Dispatch<any>) => {
  return {
    openPopUp: (content: any) => dispatch(openPopUp(content)),
    loadUserData_FromStorage: (content: any) => dispatch(loadUserData_FromStorage(content)),
  }
}
const mapStateToProps = (store: IRootStoreState) => {
  return {
    page: store.page,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(Login))
