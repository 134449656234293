import React, { ReactNode } from 'react'

import { IProjectStatuses, IProjectStatusesColor, ITasksStatuses, ITasksStatusesColor } from 'features/types'

import {
  ChartWrapper,
  Color,
  Info,
  InfoWrapper,
  InfoContainer,
  Label,
  MainCard,
  TitleWrapper,
  Title,
  Value,
} from './styles'

import Chart from 'components/Chart/Chart'

interface IMainCardStatus {
  new?: number
  on_approval?: number
  in_work?: number
  revision?: number
  transfer?: number
  review?: number
  pause?: number
  closed?: number
}
interface IGetStatusList {
  status?: IMainCardStatus
  totalCount?: number
  isProjects?: boolean
}
interface IChartMainCard {
  status?: IMainCardStatus
  title?: string
  subTitle?: string
  totalCount?: number
  isProjects?: boolean
  titleButton?: ReactNode
}

const getStatusList = (props: IGetStatusList) => {
  const { status, totalCount = 0, isProjects } = props
  const statuses = []
  const isStatusEmpty = !status || Object.values(status).every((value) => value == 0)

  if (isStatusEmpty) {
    return [
      {
        label: 'Все',
        value: totalCount,
        color: 'grey',
      },
    ]
  }

  if (status.new) {
    statuses.push({
      label: isProjects ? IProjectStatuses.new : ITasksStatuses.new,
      value: status.new ?? 0,
      color: isProjects ? IProjectStatusesColor.new : ITasksStatusesColor.new,
    })
  }
  if (status.on_approval) {
    statuses.push({
      label: IProjectStatuses.on_approval,
      value: status.on_approval ?? 0,
      color: IProjectStatusesColor.on_approval,
    })
  }
  if (status.in_work) {
    statuses.push({
      label: IProjectStatuses.in_work,
      value: status.in_work ?? 0,
      color: isProjects ? IProjectStatusesColor.in_work : ITasksStatusesColor.in_work,
    })
  }
  if (status.revision) {
    statuses.push({
      label: IProjectStatuses.revision,
      value: status.revision ?? 0,
      color: IProjectStatusesColor.revision,
    })
  }
  if (status.transfer) {
    statuses.push({
      label: ITasksStatuses.transfer,
      value: status.transfer ?? 0,
      color: ITasksStatusesColor.transfer,
    })
  }
  if (status.review) {
    statuses.push({
      label: ITasksStatuses.review,
      value: status.review ?? 0,
      color: ITasksStatusesColor.review,
    })
  }
  if (status.pause) {
    statuses.push({
      label: ITasksStatuses.pause,
      value: status.pause ?? 0,
      color: ITasksStatusesColor.pause,
    })
  }
  if (status.closed) {
    statuses.push({
      label: isProjects ? IProjectStatuses.closed : ITasksStatuses.closed,
      value: status.closed ?? 0,
      color: isProjects ? IProjectStatusesColor.closed : ITasksStatusesColor.closed,
    })
  }

  return statuses
}

const ChartMainCard = (props: IChartMainCard) => {
  const { status, title, subTitle, totalCount, isProjects, titleButton } = props

  const statusList = getStatusList({ status, totalCount, isProjects })

  return (
    <MainCard>
      <TitleWrapper>
        <Title>
          {title ? <h2>{title}</h2> : <></>}
          {subTitle ? <h3>{subTitle}</h3> : <></>}
        </Title>
        <div>{titleButton}</div>
      </TitleWrapper>
      <ChartWrapper>
        <Chart totalCount={totalCount} statusList={statusList} />
        <InfoWrapper>
          <InfoContainer>
            {statusList.map((item) => (
              <Info key={item.label}>
                <Color color={item.color}>
                  <span></span>
                </Color>
                <Value>
                  <p>{item.value}</p>
                </Value>
                <Label>
                  <p>{item.label}</p>
                </Label>
              </Info>
            ))}
          </InfoContainer>
        </InfoWrapper>
      </ChartWrapper>
    </MainCard>
  )
}

export default ChartMainCard
