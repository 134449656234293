import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

export default function useMyTasks() {
  const selectUsersClients = createSelector(
    (state) => state.page.BACKLOG,
    (BACKLOG) => BACKLOG.filter((task) => task.AUTHOR?.ID_USER == (selfUser?.ID || -1)),
  )
  const selfUser = useSelector((store) => store.user.data)
  return useSelector(selectUsersClients)
}
