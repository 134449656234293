import { combineReducers } from 'redux'
import { pageReducer } from 'features/reducers/page'
import { userReducer } from 'features/reducers/user'
import { popupReducer } from 'features/reducers/popup'

export const rootReducer = combineReducers({
  page: pageReducer,
  user: userReducer,
  popups: popupReducer,
})
