import { Button, TextField } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { closePopUp, openPopUp } from 'features/actions/PopUpActions'
import { getBACKLOG, getDB_Data, getDB_Data_lazy, getUSERS } from 'features/actions/PageActions'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import { HexColorPicker } from 'react-colorful'

const form_fields = [
  {
    label: 'Название',
    name: 'TITLE',
  },
  {
    label: 'Описание',
    name: 'DESCR',
    multiline: true,
    rows: 4,
  },
]

class POP_Dept extends React.Component {
  constructor() {
    super()
    this.state = {
      Self: {},
    }
    this.input = []
  }

  componentDidMount() {
    const {} = this.state
    const { DEPTS, ID_OBJECT } = this.props

    if (ID_OBJECT != null) {
      var Self_tmp = DEPTS.find((p) => p.ID == ID_OBJECT)
      if (Self_tmp == null) return
      var Self = {}
      Object.assign(Self, Self_tmp)
      Object.keys(this.input).forEach((key) => {
        this.input[key].value = Self[key]
      })
      this.setState({ Self })
    }
  }

  Delete() {
    const { ID_OBJECT } = this.props

    GF.API_task({
      operation: 'deleteSome',
      Table: 'DEPTS',
      ID: ID_OBJECT,
    }).then(() => {
      this.props.getDB_Data_lazy('DEPTS')
      setTimeout(() => this.props.getUSERS(), 400)

      this.props.closePopUp()
    })
  }

  Save() {
    const { Self } = this.state
    if (!GF.checkReqvireds([this.input.TITLE])) return
    let data = {}
    Object.keys(this.input).forEach((key) => {
      try {
        let val = this.input[key].value
        data[key] = val.length == 0 ? null : val
      } catch (e) {}
    })

    let reqv = {
      operation: 'createUpdateSome',
      Table: 'DEPTS',
      data,
    }
    reqv.data.COLOR = Self.COLOR
    if (this.props.ID_OBJECT != null) reqv.ID = this.props.ID_OBJECT

    GF.API_task(reqv).then(() => {
      this.props.getDB_Data_lazy('DEPTS')
      setTimeout(() => this.props.getUSERS(), 400)

      this.props.closePopUp()
    })
  }

  render() {
    const { user } = this.props
    const { Self } = this.state

    return (
      <POP>
        <h4>Отдел</h4>

        {form_fields.map((input) => (
          <TextField
            className="w-100 mt-3"
            margin="dense"
            disabled={user.ROLE != 'ADMN'}
            size="small"
            {...input}
            inputRef={(ref) => (this.input[input.name] = ref)}
          />
        ))}

        <div className="mt-3" />

        <HexColorPicker
          className="w-100 mt-3"
          color={Self.COLOR}
          onChange={(color) =>
            this.setState({
              Self: {
                ...Self,
                COLOR: color,
              },
            })
          }
        />
        <TextField
          className="w-100 mt-3 mb-3"
          margin="dense"
          label="Цвет (HEX)"
          value={Self.COLOR}
          disabled={user.ROLE != 'ADMN'}
          size="small"
          onChange={(e) =>
            this.setState({
              Self: {
                ...Self,
                COLOR: e.target.value,
              },
            })
          }
        />

        {this.props.ID_OBJECT == null || user.ROLE != 'ADMN' ? null : (
          <Button className="float me-2" color="error" onClick={() => this.Delete()}>
            удалить
          </Button>
        )}
        <Button className="float-end" onClick={() => this.Save()}>
          Сохранить
        </Button>
      </POP>
    )
  }
}

const POP = styled.div`
  min-height: 100px;
  width: 500px;
`

const mapDispatchToProps = (dispatch) => {
  return {
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    getBACKLOG: (arg) => dispatch(getBACKLOG(arg)),
    getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),

    getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),

    getUSERS: (arg) => dispatch(getUSERS(arg)),
  }
}

const mapStateToProps = (store) => {
  return {
    PROJECTS: store.page.PROJECTS,
    DEPTS: store.page.DEPTS,
    user: store.user.data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_Dept)
