export interface IRootStoreState {
  page: {
    POPUPS: any
    PROJECTS: any
    COMPANYS: any
    BACKLOG: any
    STATUSES: any
  }
  user: { data: any; isLogined?: boolean }
  isLogined?: boolean
}

export enum IProjectStatuses {
  new = 'Новый',
  on_approval = 'На согласовании',
  in_work = 'В работе',
  revision = 'Доработка',
  closed = 'Закрыт',
}
export enum IProjectStatusesId {
  new = 1,
  on_approval = 2,
  in_work = 3,
  revision = 4,
  closed = 9,
}
export enum IProjectStatusesColor {
  new = '#DADADA',
  in_work = '#B5E0FF',
  closed = '#B8E7A1',
  on_approval = '#FFA500FF',
  revision = '#FF0000FF',
}

export enum ITasksStatuses {
  new = 'Новая',
  in_work = 'В работе',
  review = 'На проверке',
  closed = 'Завершена',
  transfer = 'Передана',
  pause = 'На паузе',
}
export enum ITasksStatusesId {
  new = 5,
  in_work = 6,
  review = 7,
  closed = 8,
  transfer = 10,
}
export enum ITasksStatusesColor {
  new = '#DADADA',
  in_work = '#B5E0FF',
  closed = '#B8E7A1',
  review = '#f68201',
  transfer = '#b651a7',
  pause = '#F5DA96',
}
