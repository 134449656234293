import styled from 'styled-components'

interface IPageLayoutStyled {
  isTopContent?: boolean
}

const MainWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 145px);
`

const TopWrapper = styled.div`
  width: 100%;
  height: 75px;
  padding: 15px;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 10px 10px 0 0;
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const FullWidthColWrapper = styled.div<IPageLayoutStyled>`
  width: 100%;
  height: ${(props: IPageLayoutStyled) => (props.isTopContent ? 'calc(100vh - 224px)' : 'calc(100vh - 149px)')};
  padding: 15px;
  border-radius: ${(props: IPageLayoutStyled) => (props.isTopContent ? '0 0 10px 10px' : '10px')};
  background-color: white;
  transition: 1s;
`
const FullWidthContent = styled.div`
  overflow: auto;
`

const LeftColWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 224px);
  padding: 15px;
  margin-right: 5px;
  border-radius: 0 0 0 10px;
  background-color: white;
  transition: 1s;

  flex: 0.2;
`
const LeftColContent = styled.div`
  overflow: auto;
`

const RightColWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 224px);
  border-radius: 0 0 10px 0;
  transition: 1s;
  overflow: hidden;

  flex: 0.8;
`
const RightColContent = styled.div`
  height: 100%;
  padding: 15px;
  background-color: white;
  overflow: auto;
`

export {
  MainWrapper,
  TopWrapper,
  ContentWrapper,
  FullWidthColWrapper,
  FullWidthContent,
  LeftColWrapper,
  LeftColContent,
  RightColWrapper,
  RightColContent,
}
