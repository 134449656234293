import { Button, TextField } from '@mui/material'
import React, { useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUSERS } from 'features/actions/PageActions'
import { closePopUp } from 'features/actions/PopUpActions'
import { GF } from 'utils/GlobalFunctions'

export default function POP_ChangeLoginPass({ ID_OBJECT }) {
  const USERS = useSelector((store) => store.page.USERS)
  const dispatch = useDispatch()

  const [login, setLogin] = useState(null)
  const [password, setPassword] = useState(null)

  useLayoutEffect(() => {
    const Self = USERS.find((u) => u.ID == ID_OBJECT)
    if (!login && !password) {
      setLogin(Self.LOGIN)
      setPassword(Self.PASSWORD)
    }
  }, [ID_OBJECT, USERS])

  function Save() {
    GF.API_task({
      operation: 'changeLoginPass',
      ID_USER: ID_OBJECT,
      LOGIN: login,
      PASSWORD: password,
    }).then(() => {
      dispatch(closePopUp())
      dispatch(getUSERS())
    })
  }

  return (
    <div
      style={{
        width: 300,
      }}
    >
      <h5>Логин / пароль пользователя</h5>
      <TextField
        className="w-100 mt-3"
        margin="dense"
        size="small"
        onChange={(e) => {
          setLogin(e.target.value)
        }}
        label="Логин"
        value={login}
      />

      <TextField
        label="Пароль"
        className="w-100 mt-3"
        margin="dense"
        size="small"
        onChange={(e) => {
          setPassword(e.target.value)
        }}
        value={password}
      />
      <hr />
      <Button onClick={Save}>Сохранить</Button>
    </div>
  )
}
