import PopUp from 'components/PopUp'
import React from 'react'

import { store } from 'features/store/configureStore'

export function openPopUp(content) {
  return (dispatch) => {
    let windows = store.getState().popups.windows

    dispatch({
      type: 'OPEN_POP_UP',
      payload: <PopUp zIndex={windows.length}>{content}</PopUp>,
    })
  }
}

export function closePopUp() {
  return (dispatch) => {
    dispatch({
      type: 'CLOSE_POP_UP',
      payload: null,
    })
  }
}

export function closePopImgs() {
  return (dispatch) => {
    dispatch({
      type: 'CLOSE_IMGS',
      payload: null,
    })
  }
}

export function openPopImgs(arg) {
  return (dispatch) => {
    dispatch({
      type: 'OPEN_IMGS',
      payload: {
        selectedImg: arg.selectedImg,
        aImgs: arg.aImgs,
      },
    })
  }
}
