import styled from 'styled-components'

interface IAppStyled {
  visible?: boolean
}

const BlurBg = styled.div<IAppStyled>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: 0.5s;
  width: 100vw;
  z-index: ${(props) => (props.visible ? 100 : -999)};
`

export { BlurBg }
