import { DatePicker } from '@mui/lab'
import { Button, Stack, TextField } from '@mui/material'
import moment from 'moment'
import { useCallback, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GF } from 'utils/GlobalFunctions'
import { getDB_Data_lazy } from 'features/actions/PageActions'
import { closePopUp } from 'features/actions/PopUpActions'

const form_fields = [
  {
    label: 'Название',
    key: 'TITLE',
  },
  {
    label: 'Описание',
    key: 'DESCR',
    multiline: true,
    rows: 4,
  },
]

const defaultState = {
  DATE_DOC: moment(),
}

export default function POP_Doc({ ID_OBJECT, ID_PROJECT }) {
  const [Self, setSelf] = useState(defaultState)

  const dispatch = useDispatch()
  const DOCUMENTS = useSelector((store) => store.page.DOCUMENTS)

  useLayoutEffect(() => {
    setSelf(DOCUMENTS.find((d) => d.ID == ID_OBJECT) || defaultState)
  }, [ID_OBJECT])

  const deleteFilte = useCallback(() => {
    GF.API_task({
      operation: 'deleteSome',
      Table: 'DOCUMENTS',
      ID: Self.ID || ID_OBJECT,
    }).then((res) => {
      dispatch(getDB_Data_lazy('DOCUMENTS', 'PROJECTS'))
      dispatch(closePopUp())
    })
  }, [Self, ID_OBJECT])

  const saveFile = useCallback(() => {
    const data = {}
    form_fields.forEach((field) => {
      data[field.key] = Self[field.key]
    })
    data.DATE_DOC = Self.DATE_DOC
    data.ID_PROJECT = ID_PROJECT

    GF.API_task({
      operation: 'createUpdateSome',
      Table: 'DOCUMENTS',
      ID: Self.ID || ID_OBJECT || null,
      data,
    }).then((res) => {
      dispatch(getDB_Data_lazy('DOCUMENTS', 'PROJECTS'))
      dispatch(closePopUp())
    })
  }, [Self, ID_PROJECT, ID_OBJECT])

  const changeDate = useCallback((DATE_DOC) => {
    setSelf((prev) => ({
      ...prev,
      DATE_DOC,
    }))
  }, [])

  return (
    <div style={{ width: 400 }}>
      <h4>{!ID_OBJECT ? 'Новый документ' : 'Документ'}</h4>

      {form_fields.map((input) => (
        <TextField
          className="w-100 mb-2"
          margin="dense"
          size="small"
          {...input}
          value={Self[input.key]}
          onChange={(e) => {
            const tmp = { ...Self }
            tmp[input.key] = e.target.value
            setSelf(tmp)
          }}
        />
      ))}

      <DatePicker
        renderInput={(props) => <TextField className="w-100 mt-2" size="small" {...props} />}
        label="Дата заключения"
        value={Self.DATE_DOC}
        onChange={changeDate}
      />

      <Stack flexDirection={'row'} className="mt-3">
        {!!Self.ID && (
          <Button color="error" className="me-1" onClick={deleteFilte}>
            Удалить
          </Button>
        )}

        <Button onClick={saveFile}>Сохранить</Button>
      </Stack>
    </div>
  )
}
