import React, { FC, ReactNode } from 'react'

import {
  MainWrapper,
  TopWrapper,
  ContentWrapper,
  FullWidthColWrapper,
  FullWidthContent,
  LeftColWrapper,
  LeftColContent,
  RightColWrapper,
  RightColContent,
} from './styles'

interface IPageLayout {
  topContent?: ReactNode
  leftContent?: ReactNode
  rightContent?: ReactNode
  fullWidthContent?: ReactNode
  isTopContent?: boolean
  isRightFullHeight?: boolean
  isFullWidthContent?: boolean
}

const PageLayout: FC<IPageLayout> = (props: IPageLayout) => {
  const {
    topContent,
    leftContent,
    rightContent,
    fullWidthContent,
    isTopContent = true,
    isRightFullHeight,
    isFullWidthContent,
  } = props

  return (
    <MainWrapper className="flex flex-col">
      {isTopContent && <TopWrapper className="flex flex-row flex-wrap">{topContent}</TopWrapper>}
      <ContentWrapper className="flex">
        {isFullWidthContent ? (
          <FullWidthColWrapper className="flex flex-col" isTopContent={isTopContent}>
            <FullWidthContent>{fullWidthContent}</FullWidthContent>
          </FullWidthColWrapper>
        ) : (
          <>
            <LeftColWrapper className="flex flex-col">
              <LeftColContent>{leftContent}</LeftColContent>
            </LeftColWrapper>
            <RightColWrapper className="flex flex-col">
              {isRightFullHeight ? <RightColContent>{rightContent}</RightColContent> : <>{rightContent}</>}
            </RightColWrapper>
          </>
        )}
      </ContentWrapper>
    </MainWrapper>
  )
}

export default PageLayout
