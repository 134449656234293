import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { AttachFile } from '@mui/icons-material'
import { Autocomplete, Avatar, Chip, IconButton, TextField, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SendIcon from '@mui/icons-material/Send'

import {
  Chat,
  ChatSelected,
  ChatsEmpty,
  ChatSender,
  CompaniesWrapper,
  FilterWrapper,
  LastDate,
  LastMessage,
  Message,
  Messages,
  MessagesContent,
  MessagesWrapper,
  MSG,
  MSG_img,
  SenderWrapper,
} from './styles'

import { filterChats, formatDate } from 'utils/functions'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import { getCHATS, JSONparse } from 'features/actions/PageActions'
import { openPopImgs, openPopUp } from 'features/actions/PopUpActions'
import POP_SelectEmploy from 'pages/Tasks/POP_SelectEmploy'
import SearchField from 'components/UI/inputs/SearchField/SearchField'
import ButtonCreate from 'components/UI/button/ButtonCreate'
import ButtonFilter from 'components/UI/button/ButtonFilter'
import PageLayout from 'components/UI/layouts/PageLayout'

class Chats extends React.Component {
  constructor(props) {
    super()
    this.state = {
      selectedCompanys: props.COMPANYS,
      searchedChats: props.CHATS,

      MSGs: [],
      lastChatsMsgsId: [],
      newMSGtext: '',

      isShowFilter: false,
    }

    this.input = []
    this.autoApdateTimer = null
    this.lastUpdateTime = null
    this.previosChatId = null
    this.nowUpdatingChats = false
    this.ChatBody = null

    this.nowDateDay = GF.makeNormalDate(new Date(), 'dd.mm.yyyy')

    this.AllChatUpdateTimer = null
  }

  getMSGs(variant) {
    try {
      if (this.nowUpdatingChats && variant != 'noTimer') return
      this.nowUpdatingChats = true

      const { MSGs } = this.state
      const {} = this.props

      if (this.props.match.params.ID_CHAT == null) {
        this.setState({ MSGs: [] })
        this.lastUpdateTime = null
        this.previosChatId = null
        return
      }

      var updatingChatId = this.props.match.params.ID_CHAT
      GF.API_task({
        operation: 'getChatMSGs',
        ID_CHAT: this.props.match.params.ID_CHAT,
        lastTimeUpdate: this.lastUpdateTime == null ? null : GF.makeNormalDate(this.lastUpdateTime, 'yyyy-mm-dd H:M:S'),
      }).then((data) => {
        try {
          this.nowUpdatingChats = false
          if (updatingChatId != this.props.match.params.ID_CHAT) {
            this.setState({ MSGs: [] })
            this.lastUpdateTime = null
            this.previosChatId = null
            return
          }
          this.previosChatId = updatingChatId
          this.lastUpdateTime = new Date() //Мы получаем все сообщения диалога только при его открытии, а потом мы подгружаем только новые
          // console.warn('lastUpdateTime=' + this.lastUpdateTime)
          let msgs_tmp = Object.assign({}, MSGs)
          msgs_tmp = MSGs.concat(data)
          JSONparse(data, 'HUMAN_IMAGES_URL')
          JSONparse(data, 'IMAGES_URL')

          this.setState({ MSGs: msgs_tmp })

          if (data.length > 0) this.ChatBody.scrollTop = this.ChatBody.scrollHeight
          if (variant != 'noTimer') this.autoApdateTimer = setTimeout(() => this.getMSGs(), 5000)
        } catch (e) {}
      })
    } catch (e) {}
  }

  componentDidMount() {
    this.AllChatUpdateTimer = setInterval(() => this.props.getCHATS(), 10000)
    if (this.props.match.params.ID_CHAT != null) this.getMSGs()
  }
  componentWillUnmount() {
    clearTimeout(this.autoApdateTimer)
    clearInterval(this.AllChatUpdateTimer)
  }

  renderView() {
    const { COMPANYS, CHATS, user } = this.props
    const { selectedCompanys, MSGs, FilteredChats } = this.state

    return {
      MSGs: () => {
        let lastUserId = null
        return MSGs.map((msg, index) => {
          let msgDate = GF.makeNormalDate(msg.DATE_CREATE, 'dd.mm.yyyy')
          let msgTime = GF.makeNormalDate(msg.DATE_CREATE, 'H:M')
          let justTime = false
          if (msgDate == this.nowDateDay) justTime = true

          let isAnotherUser = false
          let isIam = msg.ID_USER == user.ID
          if (lastUserId != msg.ID_USER) {
            isAnotherUser = true
            lastUserId = msg.ID_USER
          }
          let text = msg.TEXT.split(`\n`)

          let result_text = []
          text.forEach((t, i) => {
            result_text.push(t)
            if (i > 0) result_text.push(<br />)
          })

          const showMsgDate = (justTime ? 'Сегодня' : msgDate) + ' ' + msgTime

          return (
            <Message key={`${index} - ${msg.ID}`} isIam={isIam}>
              {!isAnotherUser ? null : (
                <Chip
                  color={isIam ? 'primary' : 'default'}
                  avatar={
                    msg.HUMAN_IMAGES_URL == null ? (
                      <Avatar>{msg.FIO[0]}</Avatar>
                    ) : (
                      <Avatar src={msg.HUMAN_IMAGES_URL[0]} alt={msg.FIO} />
                    )
                  }
                  label={msg.FIO}
                />
              )}

              <Tooltip title={showMsgDate} placement="left-end">
                <MSG
                  isIam={isIam}
                  style={{
                    maxWidth: msg.IMAGES_URL == null || msg.IMAGES_URL.length == 0 ? null : 310,
                    marginTop: msg.IMAGES_URL == null || msg.IMAGES_URL.length == 0 ? null : 15,
                  }}
                >
                  <p>{text}</p>
                  {msg.IMAGES_URL == null || msg.IMAGES_URL.length == 0
                    ? null
                    : [
                        <MSG_img
                          onClick={(e) =>
                            this.props.openPopImgs({
                              selectedImg: 0,
                              aImgs: [e.target.src],
                            })
                          }
                          src={`${global.URL_host}img/${msg.IMAGES_URL[0]}`}
                        />,
                        msg.IMAGES_URL.map((img, i) =>
                          i == 0 ? null : (
                            <MSG_img
                              key={i}
                              onClick={(e) =>
                                this.props.openPopImgs({
                                  selectedImg: 0,
                                  aImgs: [e.target.src],
                                })
                              }
                              index={i}
                              count={msg.IMAGES_URL.length - 1}
                              src={`${global.URL_host}img/${img}`}
                            />
                          ),
                        ),
                      ]}

                  <p style={{ width: '100%', paddingTop: '5px', textAlign: 'right' }}>{formatDate(msg.DATE_CREATE)}</p>
                </MSG>
              </Tooltip>
            </Message>
          )
        })
      },
    }
  }
  sendFiles(target) {
    this.sendMSG({
      canEmpty: true,
      dontGetNewMSGs: true,
    }).then((msg) => {
      GF.API_files(
        {
          operation: 'setIMAGE',
          Table: 'CHAT_MSG',
          ID: msg.ID,
        },
        target.files,
      ).then(() => {
        setTimeout(() => this.getMSGs('noTimer'), 300)
      })
    })
  }
  sendMSG(type = { canEmpty: false, dontGetNewMSGs: false }) {
    return new Promise((resolve, reject) => {
      const { selectedCompanys, MSGs, newMSGtext } = this.state
      if (!type.canEmpty)
        if (newMSGtext == null || newMSGtext.length == 0) {
          reject('empty msg')
          return
        }
      let TEXT = newMSGtext + ''
      this.setState({ newMSGtext: '' })

      GF.API_task({
        operation: 'sendMSG',
        ID_CHAT: this.props.match.params.ID_CHAT,
        TEXT,
      })
        .then((data) => {
          if (!type.dontGetNewMSGs) this.getMSGs('noTimer')
          setTimeout(() => resolve(data), 100)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }
  OpenChatWith(ID_USER) {
    const { COMPANYS, user, ID_OBJECT, USER_STATUS, PROJECTS } = this.props
    GF.API_task({
      operation: 'getOrCreatePrivateChat',
      ID_USER,
    }).then((data) => {
      window.location.replace('/chats/' + data.ID_CHAT)
    })
  }

  isChatId() {
    if (!this.props.match.params.ID_CHAT) return true
    return this.props.CHATS.some((chat) => this.props.match.params.ID_CHAT == chat.ID)
  }
  onCreateChat() {
    new Promise((resolve) => {
      this.props.openPopUp(<POP_SelectEmploy allowSelfSelect={false} showTextField={false} callBack={resolve} />)
    }).then(({ USER }) => {
      this.OpenChatWith(USER.ID)
    })
  }
  onFilter() {
    this.setState((prevState) => ({
      ...prevState,
      isShowFilter: !this.state.isShowFilter,
    }))
  }
  onSearch(e, CHATS) {
    const value = e.target.value

    this.setState((prevState) => ({
      ...prevState,
      searchedChats: filterChats(value, CHATS),
    }))
  }

  render() {
    const { COMPANYS, CHATS, user } = this.props
    const { selectedCompanys, searchedChats, MSGs, newMSGtext, isShowFilter } = this.state

    const isSelectedCompanies = !!(selectedCompanys && selectedCompanys.length)
    const renderChats = !this.isChatId ? CHATS : searchedChats

    return (
      <PageLayout
        topContent={
          <>
            <SearchField onChange={(e) => this.onSearch(e, CHATS)} />
            <ButtonFilter onClick={() => this.onFilter()} />

            {isShowFilter ? (
              <FilterWrapper>
                <Autocomplete
                  multiple
                  options={COMPANYS}
                  value={selectedCompanys}
                  onChange={(event, newValue) => {
                    this.setState({ selectedCompanys: newValue })
                  }}
                  limitTags={5}
                  getOptionLabel={(option) => option.TITLE}
                  defaultValue={COMPANYS}
                  size="small"
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Представители компаний" size="small" />
                  )}
                />
              </FilterWrapper>
            ) : (
              <></>
            )}

            {user.ROLE != 'ADMN' && user.ROLE != 'EMPL' ? null : (
              <ButtonCreate onClick={() => this.onCreateChat()}>Создать беседу</ButtonCreate>
            )}
          </>
        }
        leftContent={
          <>
            {isSelectedCompanies ? (
              <>
                {renderChats.map((chat, index) => {
                  if (COMPANYS.findIndex((s) => s.ID == chat.COMPANY.ID) == -1) return null
                  let title = null

                  if (chat.TITLE != null) title = chat.TITLE
                  else if (chat.IS_PRIVATE == 1) {
                    let ferstNotIam = chat.DIALOG_USERS.filter((cdu) => cdu.ID != user.ID).slice(0)

                    if (ferstNotIam == null || ferstNotIam.length == 0) title = `Группа ${chat.ID}`
                    else title = GF.FIO(ferstNotIam[0].FIO)
                  }

                  const lastText = chat?.LAST_TEXT
                  const lastDate = chat?.LAST_DATE_CREATE
                  const lastMessage = lastText

                  const lastMessageDate = formatDate(lastDate)

                  return (
                    <Chat
                      key={`${index} - ${chat.ID}`}
                      noOneIsSelected={this.props.match.params.ID_CHAT == null}
                      isSelected={this.props.match.params.ID_CHAT == chat.ID}
                      onClick={() => {
                        clearTimeout(this.autoApdateTimer)
                        this.nowUpdatingChats = false
                        this.setState({ MSGs: [], newMSGtext: '' })
                        this.props.history.push('/chats/' + chat.ID)
                        this.lastUpdateTime = null
                        this.previosChatId = null

                        setTimeout(() => this.getMSGs(), 1)
                      }}
                    >
                      <ChatSelected className="flex">
                        <div className="flex flex-col mr-10">
                          <Avatar alt={title} src={chat.COMPANY.IMAGES_URL} />
                        </div>
                        <div className="flex flex-col w-100">
                          <small
                            style={{
                              width: '200px',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {chat.COMPANY == null ? null : chat.COMPANY.TITLE}
                          </small>
                          <p>{title}</p>
                          {user.ROLE != 'ADMN' ? null : (
                            <IconButton
                              color="inherit"
                              size="small"
                              style={{ backgroundColor: 'none' }}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                            >
                              <MoreVertIcon fontSize="inherit" />{' '}
                            </IconButton>
                          )}
                        </div>
                        <div className="flex flex-col mr-10">
                          <LastDate>
                            <p>{lastMessageDate}</p>
                          </LastDate>
                        </div>
                      </ChatSelected>
                      <LastMessage>
                        <p>{lastMessage}</p>
                      </LastMessage>
                    </Chat>
                  )
                })}
              </>
            ) : (
              <ChatsEmpty>
                <p>Создайте беседу или выберите из фильтра представителей компаний</p>
              </ChatsEmpty>
            )}
          </>
        }
        rightContent={
          <>
            <CompaniesWrapper className="flex flex-row">
              {isSelectedCompanies ? (
                <>
                  {!this.isChatId() ? (
                    <p style={{ padding: '5px', color: 'red', border: '1px solid red' }}>
                      Отправьте сообщение чтобы создать новый чат
                    </p>
                  ) : (
                    <>
                      {CHATS.map((chat) => {
                        if (COMPANYS.findIndex((s) => s.ID == chat.COMPANY.ID) == -1) return null
                        let title = null

                        if (chat.TITLE != null) title = chat.TITLE
                        else if (chat.IS_PRIVATE == 1) {
                          let ferstNotIam = chat.DIALOG_USERS.filter((cdu) => cdu.ID != user.ID).slice(0)

                          if (ferstNotIam == null || ferstNotIam.length == 0) title = `Группа ${chat.ID}`
                          else title = GF.FIO(ferstNotIam[0].FIO)
                        }

                        return this.props.match.params.ID_CHAT == chat.ID ? (
                          <ChatSelected key={chat.COMPANY.ID} className="flex">
                            <div className="flex flex-col mr-10">
                              <Avatar alt={title} src={chat.COMPANY.IMAGES_URL} />
                            </div>
                            <div className="flex flex-col">
                              <small>{chat.COMPANY == null ? null : chat.COMPANY.TITLE}</small>
                              <p>{title}</p>
                            </div>
                          </ChatSelected>
                        ) : (
                          <></>
                        )
                      })}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </CompaniesWrapper>
            <MessagesWrapper ref={(ref) => (this.ChatBody = ref)}>
              {isSelectedCompanies && (
                <Messages>
                  <MessagesContent>{this.renderView().MSGs()}</MessagesContent>
                </Messages>
              )}
            </MessagesWrapper>
            <SenderWrapper className="flex flex-row">
              {isSelectedCompanies && (
                <ChatSender isChatActive={this.props.match.params.ID_CHAT != null}>
                  <div className="send-file">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        this.input.files.click()
                      }}
                    >
                      <AttachFile />
                    </IconButton>
                    <input
                      ref={(ref) => (this.input.files = ref)}
                      multiple={true}
                      hidden={true}
                      accept="image/png,image/jpeg,image/jpg"
                      type="file"
                      onChange={(e) => this.sendFiles(e.target)}
                    />
                  </div>
                  <TextField
                    label="Напишите сообщение..."
                    // variant="filled"
                    size="large"
                    // multiline
                    // rows={6}
                    value={newMSGtext}
                    onKeyUp={(e) => {
                      if (e.keyCode == 13) {
                        this.setState({ newMSGtext: e.target.value.substr(0, e.target.value.length - 1) })
                        this.sendMSG()
                      }
                    }}
                    onChange={(e) => {
                      this.setState({ newMSGtext: e.target.value + '' })
                    }}
                  />
                  <div className="send-message">
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation()
                        this.sendMSG()
                      }}
                    >
                      <SendIcon />
                    </IconButton>
                  </div>
                </ChatSender>
              )}
            </SenderWrapper>
          </>
        }
      />
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    getCHATS: (arg) => dispatch(getCHATS(arg)),
    openPopImgs: (arg) => dispatch(openPopImgs(arg)),
  }
}
const mapStateToProps = (store) => {
  return {
    user: store.user.data,
    COMPANYS: store.page.COMPANYS,
    PROJECTS: store.page.PROJECTS,
    CHATS: store.page.CHATS,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Chats))
