import { Button, Checkbox, TextField } from '@mui/material'
import styled from 'styled-components'

import { GFlexCenter } from './index'

const FlexContainerRow = styled.div`
  ${GFlexCenter};
  flex-direction: row;
`
const FlexContainerCol = styled.div`
  ${GFlexCenter};
  flex-direction: column;
`
const FlexChildDiv = styled.div`
  flex: 1;
  ${GFlexCenter};

  &:first-child {
    width: 100%;
    flex: ${(props) => props.firstFlex || 0.6};
  }
  &:last-child {
    width: 100%;
    flex: ${(props) => props.lastFlex || 0.4};
  }
`

const SText = styled.p`
  display: inline-flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
`
const SLink = styled.a`
  display: inline-flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-align: left;
`
const SInputText = styled(TextField)`
  width: 100%;
  height: 100%;
  border-radius: 8px;

  .MuiFormControl-root,
  .MuiInputLabel-root,
  .MuiOutlinedInput-root,
  .MuiOutlinedInput-input {
    color: #2a2a2a !important;
    -webkit-text-fill-color: #2a2a2a !important;
  }

  .MuiOutlinedInput-root {
    width: 320px;
    height: 50px;
    margin-right: 15px;
    background: #f8f8f9 !important;
  }
`
const SCheckbox = styled(Checkbox)`
  width: 26px;
  height: 26px;
  display: inline-flex;
  padding: 4px;
  margin-right: 5px;
  color: #41a3da !important;
`
const SButton = styled(Button)`
  min-width: 120px !important;
  height: 36px;
  padding: 6px 30px 6px 30px;
  margin-right: 15px !important;
  border-radius: 4px;

  &:disabled {
    background: #f8f8f9;
  }
`

export { FlexContainerRow, FlexContainerCol, FlexChildDiv, SText, SLink, SInputText, SCheckbox, SButton }
