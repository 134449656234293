import styled from 'styled-components'

interface ITasksStyled {
  position?: string
  statusColor?: string
}

const TopWrapper = styled.div`
  width: 100%;
  display: flex;

  .MuiAutocomplete-root[aria-expanded='false'] {
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        .MuiOutlinedInput-input {
          width: 0 !important;
          height: 0 !important;
          padding: 0 !important;
          margin: 0 !important;
        }
      }
    }
  }

  .MuiAutocomplete-root[aria-expanded='true'] {
    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1auycx3-MuiAutocomplete-root {
      .MuiOutlinedInput-root {
        padding-right: 65px;
        z-index: 9999;
      }
    }
  }
`

const FilterWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 40px;
  margin-right: 15px;
`

const ProjectsWrapper = styled.div``

const TasksWrapper = styled.div`
  width: 100%;

  .MuiMenuItem-root {
    width: 100%;
    height: 141px;
    margin-bottom: 15px;
    border: 1px solid #000b310a;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 #000b310a;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  height: 100%;
  min-height: 30px;

  h5 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }
  .MuiButton-root {
    margin-right: 5px;
  }
`

const Card = styled.div`
  overflow: hidden;
  position: relative;

  & span {
    font-size: 13px;
    white-space: break-spaces;
  }

  & li {
    margin-top: 0;
  }

  & hr {
    margin: 0 !important;
  }

  & ul {
    padding: 0;
  }

  .projects .DetalButton {
    opacity: 0;
    transition: 0.3s;
  }

  .projects:hover .DetalButton {
    opacity: 1;
  }
`
const ChatSelected = styled.div`
  width: 100%;
  height: 38px;
  position: absolute;
  bottom: 25px;
  left: 15px;

  p,
  small {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #000000;
  }
`
const TaskStatus = styled.div<ITasksStyled>`
  display: flex;
  position: ${(props: ITasksStyled) => (props.position ? props.position : 'absolute')};

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;
  }
  .MuiChip-root {
    background: ${(props: ITasksStyled) => (props.statusColor ? props.statusColor : '#f8f8f9')};
    color: ${(props: ITasksStyled) => (props.statusColor ? '#ffffff' : '#000000')};
  }
`
const TaskText = styled.div`
  padding-top: 15px;

  p {
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    padding-right: 110px;
    white-space: normal;
  }
`

export {
  TopWrapper,
  FilterWrapper,
  ProjectsWrapper,
  TasksWrapper,
  TaskStatus,
  TaskText,
  TitleWrapper,
  Card,
  ChatSelected,
}
