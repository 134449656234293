import styled from 'styled-components'

interface IMainPage {
  showHistory?: boolean
}

const MainView = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
const MainContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &:first-child {
    width: 100%;
    flex: 100%;
  }
  &:last-child {
  }
`

const CardContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0 0 0 20px;

  &:first-child {
    margin-left: 0;
  }

  .MuiPaper-root {
    //margin-left: 20px;
    &:first-child {
      //margin-left: 0;
    }
    //&:last-child {
    //  margin: 20px 0;
    //}

    margin: 0 20px 20px 0;
  }
`
const CardView = styled.div`
  background: white;

  border-radius: 10px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 15px;

  margin-bottom: 20px;
  margin-right: 20px;

  & content {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 50vh;
  }
`
const CardViewTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  h4 {
    margin: 0;
  }

  .MuiButton-root {
    height: 100%;
    margin-left: 15px;
  }
`
const CardViewContainer = styled.div`
  margin-top: 15px;
`

const TasksWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 15px;
  padding: 5px;
  border: 1px solid #000b310a;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 #000b310a;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`

const HistoryWrapper = styled.div<IMainPage>`
  width: 500px;
  height: ${(props: IMainPage) => (props.showHistory ? 'calc(100vh - 129px)' : '115px')};
  padding: 20px;
  background: white;
  border: 1px solid #000b310a;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 #000b310a;
  overflow: ${(props: IMainPage) => (props.showHistory ? 'auto' : 'hidden')};
`
const HistoryTitle = styled.div``
const HistoryText = styled.div`
  margin-bottom: 10px;

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  h4 {
    font-size: 32px;
    line-height: 38px;
  }

  &:last-child {
    margin-bottom: 20px;
  }
`
const HistoryCard = styled.div`
  height: 80px;

  .MuiMenuItem-root {
    height: 100%;
    min-height: 80px;
    margin-bottom: 5px;
    border: 1px solid #000b310a;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 #000b310a;
  }
`
const HistoryTask = styled.div`
  width: 100%;
  height: 100%;

  p,
  small {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #000000;
  }
`
const TaskTextContainer = styled.div`
  p {
    font-size: 13px;
    font-weight: 600;
    line-height: 14px;
    padding-right: 110px;
    white-space: normal;
  }
`
const TaskText = styled.div`
  p {
    font-weight: 400;
    &:first-child {
      margin: 10px 0;
    }
  }
  span {
    font-weight: 600;
  }
`

export {
  MainView,
  MainContainer,
  MainContainerCol,
  CardView,
  CardViewTitle,
  CardViewContainer,
  CardContainer,
  TasksWrapper,
  HistoryWrapper,
  HistoryTitle,
  HistoryText,
  HistoryCard,
  HistoryTask,
  TaskTextContainer,
  TaskText,
}
