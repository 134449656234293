export const ROUTES = {
  BASE: '/',
  MAIN: '/main',
  MAIN_WELCOME: '/main',
  DATA: '/data',
  TASKS: '/tasks',
  PLANNER: '/planner',
  REPORTS: '/reports',
  CHATS: '/chats',
  HELP: '/help',
  USER_PROFILE: '/user_profile',
}
export const ROUTES_NAMES = {
  MAIN: 'Главная',
  MAIN_WELCOME: 'Добро пожаловать в Taskmanager',
  DATA: 'База данных',
  TASKS: 'Задачи',
  PLANNER: 'Планировщик',
  REPORTS: 'Отчеты',
  CHATS: 'Чаты',
  HELP: 'Помощь',
  USER_PROFILE: 'Профиль пользователя',
}
export const ROUTES_ENUM = {
  [ROUTES.MAIN]: ROUTES_NAMES.MAIN,
  [ROUTES.MAIN_WELCOME]: ROUTES_NAMES.MAIN_WELCOME,
  [ROUTES.BASE]: ROUTES_NAMES.MAIN_WELCOME,
  [ROUTES.DATA]: ROUTES_NAMES.DATA,
  [ROUTES.TASKS]: ROUTES_NAMES.TASKS,
  [ROUTES.PLANNER]: ROUTES_NAMES.PLANNER,
  [ROUTES.REPORTS]: ROUTES_NAMES.REPORTS,
  [ROUTES.CHATS]: ROUTES_NAMES.CHATS,
  [ROUTES.HELP]: ROUTES_NAMES.HELP,
  [ROUTES.USER_PROFILE]: ROUTES_NAMES.USER_PROFILE,
}
