import React from 'react'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { CreateStyled } from './styles'

interface IButtonCreate {
  onClick?: () => void
  children?: React.ReactNode
}

const ButtonCreate = (props: IButtonCreate) => {
  const { onClick, children } = props

  return (
    <CreateStyled>
      <Button startIcon={<AddIcon />} onClick={onClick}>
        {children}
      </Button>
    </CreateStyled>
  )
}

export default ButtonCreate
