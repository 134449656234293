import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Chip, MenuList, MenuItem, Stack } from '@mui/material'
import moment from 'moment'

import { TaskWrapper } from './styles'

import { openPopUp } from 'features/actions/PopUpActions'
import POP_Task from 'pages/Tasks/POP_Task'
import useMyTasks from 'pages/Planner/useMyTasks'
import PageLayout from 'components/UI/layouts/PageLayout'

const Planner = () => {
  const dispatch = useDispatch()
  const myTasks = useMyTasks()
  const selfUser = useSelector((store) => store.user.data)

  const taskItem = () => {
    return myTasks.map((b) => {
      const isFire = moment(b.DATE_DEADLINE).isSameOrAfter(moment())

      return [
        <MenuItem key={b.ID} onClick={() => dispatch(openPopUp(<POP_Task ID_OBJECT={b.ID} />))}>
          <div className="flex flex-aic">
            <small className="m-0 d-block">{b.PROJECT_TITLE}</small>
            <p className="m-0">{b.TEXT.substring(0, 200)}</p>
          </div>
          <div className="flex flex-col">
            <Chip
              size="small"
              style={{
                background: b.STATUS_COLOR,
                color: 'white',
              }}
              label={b.STATUS_TITLE}
            />
            {!!b.DATE_DEADLINE && (
              <Chip
                size="small"
                style={{ marginTop: '5px' }}
                color={isFire ? 'error' : 'default'}
                label={`${isFire ? '🔥' : ''} ${moment(b.DATE_DEADLINE).format('D MMM YYYY')}`}
              />
            )}
          </div>
        </MenuItem>,
      ]
    })
  }

  return (
    <PageLayout
      topContent={
        <>
          <Stack flexDirection={'row'} gap={2} alignContent="center" alignItems="center">
            <Button
              onClick={() =>
                dispatch(
                  openPopUp(
                    <POP_Task
                      SelfDefault={{
                        ID_AUTHOR: selfUser.ID,
                        AUTHOR: {
                          ...selfUser,
                          ID_USER: selfUser.ID,
                        },
                      }}
                    />,
                  ),
                )
              }
            >
              Новая задача
            </Button>
          </Stack>
        </>
      }
      fullWidthContent={
        <TaskWrapper>
          <MenuList>{taskItem()}</MenuList>
        </TaskWrapper>
      }
      isFullWidthContent
    />
  )
}

export default Planner
