import styled from 'styled-components'

interface IStyledSearchField {
  isIconClick?: boolean
}

const SearchFieldStyled = styled.div<IStyledSearchField>`
  margin-right: 15px;

  .MuiOutlinedInput-root {
    width: 100%;
    min-width: 330px;
    max-width: 450px;
    height: 40px;
    border-radius: 45px;
    background: #f8f8f9;
  }
  .MuiSvgIcon-root {
    cursor: ${(props) => (props.isIconClick ? 'pointer' : 'default')};
  }
`

export { SearchFieldStyled }
