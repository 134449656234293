import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { openPopUp } from 'features/actions/PopUpActions'

import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'
import Users from 'pages/DataBase/pages/Users'
import Companys from 'pages/DataBase/pages/Companys'
import Depts from 'pages/DataBase/pages/Depts'
import Projects from 'pages/DataBase/pages/Projects'
import Statuses from 'pages/DataBase/pages/Statuses'

class Index extends React.Component {
  constructor() {
    super()
    this.state = {
      errorMSG: null,
    }
    this.input = []
  }

  render() {
    const {} = this.props
    let pathname = this.props.location.pathname
    if (pathname == '/data') pathname = '/data/employs'

    return (
      <View>
        <Tabs
          value={pathname}
          onChange={(e, value) => {
            this.props.history.push(value)
          }}
        >
          <Tab value="/data/employs" label="Персонал" />
          <Tab value="/data/clients" label="Заказчики" />
          <Tab value="/data/companys" label="Компании" />
          <Tab value="/data/depts" label="Отделы" />
          <Tab value="/data/projects" label="Проекты" />
          <Tab value="/data/statuses" label="Статусы" />
        </Tabs>

        <div style={{ padding: 20, paddingTop: 0 }}>
          <Switch>
            <Route path="/data/employs">
              <Users role="EMPL" />
            </Route>
            <Route path="/data/clients">
              <Users role="CLEN" />
            </Route>
            <Route path="/data/companys">
              <Companys />
            </Route>
            <Route path="/data/depts">
              <Depts />
            </Route>
            <Route path="/data/projects">
              <Projects />
            </Route>
            <Route path="/data/statuses">
              <Statuses />
            </Route>
            <Route path="/data">
              <Redirect to="/data/employs" />
            </Route>
          </Switch>
        </div>
      </View>
    )
  }
}

const View = styled.div`
  height: 100%;
  padding: 0;
  margin: -20px;
`

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
  }
}

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Index))
