import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import reportWebVitals from './reportWebVitals'
import { DevSupport } from '@react-buddy/ide-toolbox'

import 'styles/App.css'

import { store } from 'features/store/configureStore'
import { ToastProvider } from 'components/Toast/Toast'
import App from 'components/App/App'

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

reportWebVitals()
