import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { openPopUp } from 'features/actions/PopUpActions'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import Table from 'components/Table'
import { Button, TextField } from '@mui/material'
import POP_Company from 'pages/DataBase/pages/POP_Company'

const Rows = [
  {
    title: 'Название',
    key: 'TITLE',
  },
  {
    title: 'Сайт',
    key: 'WEB',
  },
  {
    title: 'Заметка',
    key: 'DESCR',
  },
]

class Companys extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMSG: null,
      succes: false,
      filter: null,
      Data: [],
    }
    this.input = {
      dataWindow: {},
      main: {},
    }
    this.checkBoxs = {}
  }

  PreDelete() {
    let promises = []
    let somethingDoing = false

    Object.keys(this.checkBoxs).forEach((key, index) => {
      if (this.checkBoxs[key].checked) {
        somethingDoing = true
        this.checkBoxs[key].checked = false
        promises.push(this.Delete(key))
        delete this.checkBoxs[key]
      }
    })

    if (!somethingDoing) return

    Promise.allSettled(promises).then(() => {
      this.props.getUsers()
      this.props.addToast('Удалено', { appearance: 'success', autoDismiss: true })
    })
  }

  Delete(ID) {
    return new Promise((resolve, reject) => {
      let data = {
        operation: 'deleteSome',
        Table: 'USERS',
        ID,
      }

      GF.API_task(data)
        .then((res) => {
          setTimeout(() => resolve(), 1)
        })
        .catch(() => reject())
    })
  }

  changeBlock(ID) {
    return new Promise((resolve, reject) => {
      let data = {
        operation: 'changeBlockUser',
        ID,
      }

      GF.API_task(data)
        .then((res) => {
          setTimeout(() => resolve(), 1)
        })
        .catch(() => reject())
    })
  }

  Block() {
    let somethingDoing = false
    let promises = []
    Object.keys(this.checkBoxs).forEach((key, index) => {
      if (this.checkBoxs[key].checked) {
        somethingDoing = true
        this.checkBoxs[key].checked = false
        promises.push(this.changeBlock(key))
      }
    })

    if (!somethingDoing) return

    Promise.allSettled(promises).then(() => {
      this.props.getUsers()
      this.props.addToast('Значение обновлено', { appearance: 'success', autoDismiss: true })
    })
  }

  render() {
    const { COMPANYS } = this.props
    const { filter, Data } = this.state
    return (
      <View>
        <div className="mt-4">
          <TextField
            className="me-2"
            label="Поиск"
            variant="outlined"
            size="small"
            onKeyUp={(e) => this.setState({ filter: e.target.value.length > 0 ? e.target.value.toUpperCase() : null })}
          />

          <Button
            className="me-2 align-bottom"
            variant="text"
            onClick={() => {
              Object.keys(this.checkBoxs).forEach((key, index) => {
                this.checkBoxs[key].checked = true
              })
            }}
          >
            Выбрать все
          </Button>

          <Button
            className="me-2 align-bottom"
            variant="text"
            onClick={() => {
              Object.keys(this.checkBoxs).forEach((key, index) => {
                this.checkBoxs[key].checked = false
              })
            }}
          >
            Cнять выделение
          </Button>

          <Button
            className="me-2 align-bottom"
            variant="text"
            onClick={() => {
              this.props.openPopUp(<POP_Company />)
            }}
          >
            ДОбавить
          </Button>
        </div>

        <Table
          className="mt-1"
          rows={Rows}
          canCheck={true}
          checkBoxs={this.checkBoxs}
          data={COMPANYS.filter((a) => filter == null || a.TITLE.toUpperCase().indexOf(filter) != -1)}
          itemOnClick={({ data, index }) => {
            this.props.openPopUp(<POP_Company ID_OBJECT={data.ID} />)
          }}
          rowOnClick={({ data, index }) => {
            // debugger
          }}
          renderItem={({ data, index }) =>
            Rows.map((r) => {
              switch (r.key) {
                case 'WEB':
                  return (
                    <td>
                      <a href={data[r.key]} target="black">
                        {data[r.key]}
                      </a>
                    </td>
                  )
                default:
                  return <td>{data[r.key]}</td>
              }
            })
          }
        />
      </View>
    )
  }
}

const View = styled.div`
  height: 100%;
`

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
  }
}

const mapStateToProps = (store) => {
  return {
    COMPANYS: store.page.COMPANYS,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Companys)
