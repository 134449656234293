import styled from 'styled-components'

interface IStyledInputImage {
  multiple?: boolean
}

const ScrollTo = styled.img`
  position: absolute;
  border-radius: 200px;
  background: white;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
  height: 30px;
  width: 30px;
  left: -15px;
  top: calc(50% - 30px);
  transition: 0.3s;
  cursor: pointer;

  &.left:hover {
    transform: scale(1.2) rotate(180deg);
  }
  &.right:hover {
    transform: scale(1.2);
  }

  &.right {
    left: unset;
    right: -15px;
  }

  &.left {
    transform: rotate(180deg);
  }
`

const Img = styled.img<IStyledInputImage>`
  width: ${(props) => (props.multiple ? '100px' : '100%')};
  height: 100px;
  border-radius: 10px;
  transition: 0.3s;
  object-fit: cover;
`

const Delete = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 10px;
  background: #ab4040;
  border: 2px solid white;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  text-align: center;
  color: white;
  font-weight: 400;
  line-height: 18px;
  transition: 0.3s;
  cursor: pointer;
  opacity: 0;
  &:hover {
    transform: scale(1.1);
  }
`

const ImgView = styled.div<IStyledInputImage>`
  width: ${(props) => (props.multiple ? '100px' : '100%')};
  height: 100px;
  position: relative;
  display: inline-block;
  margin-right: 20px;
  &:hover ${Img} {
    opacity: 0.8;
  }
  &:hover ${Delete} {
    opacity: 1;
  }
`

const ImgScroll = styled.div`
  overflow-x: scroll;

  border-radius: 10px;
  margin-bottom: 5px;
`
const Input_imageView = styled.div`
  position: relative;
`
const ImgContent = styled.div<IStyledInputImage>`
  width: ${(props) => (props.multiple ? 'max-content' : '100%')};
  position: relative;
`

export { ScrollTo, Img, Delete, ImgView, ImgScroll, Input_imageView, ImgContent }
