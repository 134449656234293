import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Avatar, Button, CardActions, Chip, MenuItem, MenuList } from '@mui/material'
import moment from 'moment'

import { IProjectStatusesId, IRootStoreState, ITasksStatusesId } from 'features/types'

import {
  CardContainer,
  CardView,
  CardViewContainer,
  CardViewTitle,
  HistoryCard,
  HistoryText,
  HistoryTitle,
  HistoryWrapper,
  MainContainer,
  MainContainerCol,
  MainView,
  TasksWrapper,
  HistoryTask,
  TaskTextContainer,
  TaskText,
} from './styles'
import { Card, TaskStatus } from 'pages/Tasks/styles'

import { filterByStatus, sortArrByLastChangeDate } from 'utils/functions'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import { getUSERS } from 'features/actions/PageActions'
import { openPopUp } from 'features/actions/PopUpActions'
import POP_Task from 'pages/Tasks/POP_Task'
import ChartMainCard from 'components/Chart/ChartMainCard'

interface IMainCardUserData {
  user: { ID: any }
  COMPANYS: string | any[]
  PROJECTS: any[]
  BACKLOG: any[]
}
interface ITaskCard {
  tasks: Array<any>
  title: string
  openPopUp: (element: JSX.Element) => void
}
interface IMainPage {
  PROJECTS: any[]
  user: any
  COMPANYS: any[]
  BACKLOG: any[]
  STATUSES: any[]
  history: any
  openPopUp: any
}

const mainCardUserData = (props: IMainCardUserData) => {
  const { user, COMPANYS, PROJECTS, BACKLOG } = props

  const clients = {
    total: COMPANYS.length - 1,
  }
  const projects = {
    total: PROJECTS.length,
    new: filterByStatus({ data: PROJECTS, id: IProjectStatusesId.new }),
    on_approval: filterByStatus({ data: PROJECTS, id: IProjectStatusesId.on_approval }),
    in_work: filterByStatus({ data: PROJECTS, id: IProjectStatusesId.in_work }),
    revision: filterByStatus({ data: PROJECTS, id: IProjectStatusesId.revision }),
    closed: filterByStatus({ data: PROJECTS, id: IProjectStatusesId.closed }),
  }
  const tasks = {
    total: BACKLOG.length,
    new: filterByStatus({ data: BACKLOG, id: ITasksStatusesId.new }),
    in_work: filterByStatus({ data: BACKLOG, id: ITasksStatusesId.in_work }),
    review: filterByStatus({ data: BACKLOG, id: ITasksStatusesId.review }),
    closed: filterByStatus({ data: BACKLOG, id: ITasksStatusesId.closed }),
    transfer: filterByStatus({ data: BACKLOG, id: ITasksStatusesId.transfer }),
  }
  const employeeTasks = {
    ...tasks,
    total: filterByStatus({ data: BACKLOG, id: user.ID, isLastStatus: true }),
  }

  return {
    ADMN: {
      clients,
      projects,
      tasks,
    },
    EMPL: {
      tasks: employeeTasks,
    },
    CLEN: {
      projects,
      tasks,
    },
  }
}

const TaskCard = (props: ITaskCard) => {
  const { tasks, title, openPopUp } = props
  const [showCard, setShowCard] = useState<boolean>(false)

  const onOpenTask = (task: any) => {
    openPopUp(<POP_Task ID_OBJECT={task.ID} />)
  }

  return (
    <CardView>
      <CardViewTitle>
        <h4>
          {title} {tasks?.length} шт.
        </h4>
        <Button size="small" onClick={() => setShowCard(!showCard)}>
          {showCard ? 'Свернуть' : 'Подробнее'}
        </Button>
      </CardViewTitle>
      {showCard && (
        <CardViewContainer>
          {tasks?.length == 0 && <small>Нет задач</small>}
          {tasks.map((b: any) => {
            const isFire = moment(b.DATE_DEADLINE).isSameOrAfter(moment())
            return (
              <TasksWrapper onClick={() => onOpenTask(b)}>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <small className="m-0 d-block">{b.PROJECT_TITLE}</small>
                  <p className="m-0">{b.TEXT.substring(0, 200)}</p>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Chip
                    size="small"
                    style={{
                      background: b.STATUS_COLOR,
                      color: 'white',
                    }}
                    label={b.STATUS_TITLE}
                  />
                  {!!b.DATE_DEADLINE ? (
                    <Chip
                      color={isFire ? 'error' : 'default'}
                      size="small"
                      label={`${isFire ? '🔥' : ''} ${moment(b.DATE_DEADLINE).format('D MMM YYYY')}`}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </TasksWrapper>
            )
          })}
        </CardViewContainer>
      )}
    </CardView>
  )
}

interface IBacklogHistory {
  state: { BACKLOG: any[] }
  user: { ID: any }
  users: Array<any>
  openPopUp: (arg0: JSX.Element) => void
}

const getLastUser = (users: Array<any>, ID_LAST_USER: number) => {
  return users?.filter((u) => u?.ID == ID_LAST_USER)
}

const BacklogHistory = (props: IBacklogHistory) => {
  const { state, user, users, openPopUp } = props
  if (state.BACKLOG.length === 0) return <></>

  const sortedBacklogByDate = useMemo(() => sortArrByLastChangeDate(state.BACKLOG), [state.BACKLOG])
  const backlogHistory = useMemo(() => {
    return sortedBacklogByDate.map((task: any) => {
      const LAST_CHANGE_DATE = task?.LAST_CHANGE_DATE ? GF.makeNormalDate(task.LAST_CHANGE_DATE, 'dd.mm.yyyy') : ''
      const LAST_TEXT = task?.LAST_TEXT
      const TEXT = task?.TEXT
      const STATUS_TITLE = task?.STATUS_TITLE
      const STATUS_COLOR = task?.STATUS_COLOR
      const LAST_USER_ARR = getLastUser(users, task?.ID_LAST_USER)
      const LAST_USER = LAST_USER_ARR ? LAST_USER_ARR[0] : {}

      const textSubstr = TEXT ? `${TEXT?.substr(0, 30)} ${TEXT?.length > 30 ? '...' : ''}` : TEXT
      const lastTextSubstr = LAST_TEXT ? `${LAST_TEXT?.substr(0, 30)} ${LAST_TEXT?.length > 30 ? '...' : ''}` : ''

      const taskTextStatus = () => {
        return (
          <TaskText>
            <p>
              <span>Задача:</span> {textSubstr}
            </p>
            <p>
              <span>Действие:</span> {lastTextSubstr}
            </p>
          </TaskText>
        )
      }

      return [
        <MenuItem
          key={LAST_USER?.ID}
          className="d-block"
          onClick={() => {
            openPopUp(<POP_Task ID_OBJECT={task.ID} />)
          }}
        >
          <TaskTextContainer>
            <HistoryTask className="flex">
              <div className="flex flex-col mr-10">
                <Avatar alt={LAST_USER?.FIO} src={LAST_USER?.IMAGES_URL} />
              </div>
              <div className="flex flex-col flex-jcc">
                <p style={{ fontWeight: '600' }}>{LAST_USER?.FIO}</p>
                <p style={{ fontWeight: '400' }}>{LAST_USER?.ROLE_TITLE}</p>
              </div>
            </HistoryTask>

            {task?.AUTHOR?.ID_USER == 1 ? taskTextStatus() : [taskTextStatus()]}
          </TaskTextContainer>

          <TaskStatus style={{ top: 10, right: 15 }} statusColor={STATUS_COLOR}>
            <Chip size="small" className="float-end" label={STATUS_TITLE} />
          </TaskStatus>

          <TaskStatus style={{ bottom: 10, right: 15 }}>
            <Chip size="small" color="success" label={LAST_CHANGE_DATE} />
          </TaskStatus>
        </MenuItem>,
      ]
    })
  }, [state.BACKLOG])

  return <MenuList>{backlogHistory}</MenuList>
}

const MainPage = (props: IMainPage) => {
  const { PROJECTS, user, COMPANYS, BACKLOG, history, openPopUp } = props

  const userData = mainCardUserData({ user, COMPANYS, PROJECTS, BACKLOG })

  const [state, setState] = useState({
    errorMSG: null,
    GlobalBakLog: [],
    NewBakLog: [],
    BACKLOG: [],
    BACKLOG_STORY: [],
    USERS: [],
  })
  const [showHistory, setShowHistory] = useState<boolean>(false)

  useEffect(() => {
    GF.API_task({
      operation: 'getGlobalBakLog',
    }).then((res) => {
      setState((prevState: any) => ({
        ...prevState,
        GlobalBakLog: res,
      }))
    })
    GF.API_task({
      operation: 'getNewBakLog',
    }).then((res) => {
      setState((prevState: any) => ({
        ...prevState,
        NewBakLog: res,
      }))
    })
    GF.API_task({
      operation: 'getBACKLOG',
    }).then((res) => {
      setState((prevState: any) => ({
        ...prevState,
        BACKLOG: res,
      }))
    })
    GF.API_task({
      operation: 'getBACKLOG_STORY',
    }).then((res) => {
      setState((prevState: any) => ({
        ...prevState,
        BACKLOG_STORY: res,
      }))
    })
    GF.API_task({
      operation: 'getUSERS',
    }).then((res) => {
      setState((prevState: any) => ({
        ...prevState,
        USERS: res,
      }))
    })
  }, [])

  const component = () => {
    switch (user.ROLE) {
      case 'ADMN':
        return (
          <MainView>
            <MainContainer>
              <MainContainerCol>
                <CardContainer>
                  <ChartMainCard
                    title="Клиенты"
                    subTitle="Зарегистрированные в системе"
                    totalCount={userData.ADMN.clients.total}
                    titleButton={
                      <CardActions>
                        <Button size="small" onClick={() => history.push('/data/clients')}>
                          Подробнее
                        </Button>
                      </CardActions>
                    }
                  />
                  <ChartMainCard
                    title="Проекты"
                    subTitle="Статистика проектов"
                    totalCount={userData.ADMN.projects.total}
                    status={userData.ADMN.projects}
                    isProjects
                    titleButton={
                      <CardActions>
                        <Button size="small" onClick={() => history.push('/data/projects')}>
                          Подробнее
                        </Button>
                      </CardActions>
                    }
                  />
                  <ChartMainCard
                    title="Задачи"
                    subTitle="Статистика всех задач"
                    totalCount={userData.ADMN.tasks.total}
                    status={userData.ADMN.tasks}
                    titleButton={
                      <CardActions>
                        <Button size="small" onClick={() => history.push('/tasks')}>
                          Подробнее
                        </Button>
                      </CardActions>
                    }
                  />
                </CardContainer>
                <MainContainer>
                  <TaskCard tasks={state.GlobalBakLog} title="Просроченные задачи" openPopUp={openPopUp} />
                </MainContainer>
                <MainContainer>
                  <TaskCard tasks={state.NewBakLog} title="Новые задачи" openPopUp={openPopUp} />
                </MainContainer>
              </MainContainerCol>
              <MainContainerCol>
                <HistoryWrapper showHistory={showHistory}>
                  <HistoryTitle>
                    <HistoryText>
                      <CardViewTitle>
                        <h4>История действий</h4>
                        <Button size="small" onClick={() => setShowHistory(!showHistory)}>
                          {showHistory ? 'Свернуть' : 'Показать'}
                        </Button>
                      </CardViewTitle>
                    </HistoryText>
                    <HistoryText>
                      <p>Действия совершенные другими участниками системы</p>
                    </HistoryText>
                  </HistoryTitle>
                  <HistoryCard>
                    {showHistory && (
                      <Card className="mt-3">
                        <BacklogHistory state={state} user={user} users={state.USERS} openPopUp={openPopUp} />
                      </Card>
                    )}
                  </HistoryCard>
                </HistoryWrapper>
              </MainContainerCol>
            </MainContainer>
          </MainView>
        )
      case 'EMPL':
        return (
          <MainView>
            <MainContainer>
              <CardContainer>
                <ChartMainCard
                  title="Задачи"
                  subTitle="Статистика всех задач"
                  totalCount={userData.EMPL.tasks.total}
                  status={userData.EMPL.tasks}
                  titleButton={
                    <CardActions>
                      <Button size="small" onClick={() => history.push('/tasks')}>
                        Подробнее
                      </Button>
                    </CardActions>
                  }
                />
              </CardContainer>
            </MainContainer>
          </MainView>
        )
      case 'CLEN':
        return (
          <MainView>
            <MainContainer>
              <CardContainer>
                <ChartMainCard
                  title="Проекты"
                  subTitle="Статистика проектов"
                  totalCount={userData.CLEN.projects.total}
                  status={userData.CLEN.projects}
                  isProjects
                />
              </CardContainer>
              <CardContainer>
                <ChartMainCard
                  title="Задачи"
                  subTitle="Статистика всех задач"
                  totalCount={userData.CLEN.tasks.total}
                  status={userData.CLEN.tasks}
                />
              </CardContainer>
            </MainContainer>
          </MainView>
        )

      default:
        return <></>
    }
  }

  return <div>{component()}</div>
}

const mapDispatchToProps = (dispatch: React.Dispatch<any>) => {
  return {
    openPopUp: (arg: any) => dispatch(openPopUp(arg)),
  }
}
const mapStateToProps = (store: IRootStoreState) => {
  return {
    user: store.user.data,
    POPUPS: store.page.POPUPS,
    PROJECTS: store.page.PROJECTS,
    COMPANYS: store.page.COMPANYS,
    BACKLOG: store.page.BACKLOG,
    STATUSES: store.page.STATUSES,
  }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainPage))
