import styled from 'styled-components'

const TaskWrapper = styled.div`
  width: 100%;
  height: 100%;

  .MuiMenuItem-root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #000b310a;
    border-radius: 10px;
    box-shadow: 0 0 4px 0 #000b310a;

    div {
      padding: 0;
    }

    .MuiCardHeader-root,
    .MuiCardContent-root {
      margin-bottom: 15px;
    }

    p {
      font-weight: 600;
    }
  }
`

export { TaskWrapper }
