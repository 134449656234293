import React, { useEffect, useRef } from 'react'
import { Tooltip } from '@mui/material'
import { Chart as _Chart } from 'chart.js/auto'

import { ChartComponentStyled, TotalCount } from './styles'

interface Status {
  label: string
  value: number
  color: string
}
interface IChart {
  totalCount?: number
  statusList: Array<Status>
}

const getDataSets = (statusList: Array<Status>, totalCount?: number) => {
  const data = statusList.map((status) => status.value)
  const isNoData = data.every((value) => value == 0)

  if (isNoData) {
    return [
      {
        label: 'Нет данных',
        data: totalCount ? [totalCount] : [1],
        backgroundColor: 'grey',
      },
    ]
  }

  return [
    {
      label: 'Кол-во',
      data,
      backgroundColor: statusList.map((status) => status.color),
    },
  ]
}

const Chart = (props: IChart) => {
  const { totalCount, statusList } = props

  const chartRef = useRef(null)
  const chartInstance = useRef(null)

  useEffect(() => {
    if (chartInstance.current) {
      // @ts-ignore
      chartInstance.current.destroy()
    }

    if (chartRef.current) {
      const chartConfig = {
        type: 'doughnut',
        data: {
          datasets: getDataSets(statusList, totalCount),
        },
        options: {
          responsive: true,
          cutout: '80%',
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
        },
      }

      // @ts-ignore
      chartInstance.current = new _Chart(chartRef.current, chartConfig)
    }

    return () => {
      if (chartInstance.current) {
        // @ts-ignore
        chartInstance.current.destroy()
      }
    }
  }, [statusList])

  const _totalCount = totalCount || statusList.reduce((sum, status) => sum + status.value, 0)
  const circleSize = Math.min(200, Math.max(200, _totalCount * 10))

  return (
    <ChartComponentStyled circleSize={circleSize}>
      <canvas ref={chartRef} />
      <TotalCount>
        <Tooltip title="Все">
          <h4>{_totalCount}</h4>
        </Tooltip>
      </TotalCount>
    </ChartComponentStyled>
  )
}

export default Chart
