import styled from 'styled-components'

const CreateStyled = styled.div`
  .MuiButton-root {
    width: 100%;
    height: 40px;
    padding: 6px 40px 6px 30px;
    border-radius: 4px;
    background: #41a3da;
  }
`
const FilterStyled = styled.div`
  width: 36px;
  height: 40px;
  margin-right: 15px;

  .MuiButton-root {
    width: 36px;
    min-width: 36px;
    height: 40px;
    padding: 6px 0;
    background: #f8f8f9;
    border: 1px solid rgba(0, 0, 0, 0.23);

    &:hover {
      background: #f8f8f9;
      border: 1px solid #2a2a2a;
    }
  }
  .MuiButton-startIcon {
    margin: 0;
    color: #2a2a2a;
  }
`

export { CreateStyled, FilterStyled }
