import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { withCookies } from 'react-cookie'
import ruLocale from 'date-fns/locale/ru'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { LocalizationProvider } from '@mui/lab'
import { LinearProgress } from '@mui/material'

import { BlurBg } from './styles'

import 'utils/global'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import { getBACKLOG, getCHATS, getDB_Data, getDB_Data_lazy, getUSERS, getVARS } from 'features/actions/PageActions'
import { loadUserData_FromStorage, loginIn } from 'features/actions/UserAct'
import { openPopImgs } from 'features/actions/PopUpActions'
import Guid from 'pages/Guid'
import Login from 'components/Login/Login'
import Main from 'components/Main/Main'
import PopUpImgs from 'components/PopUp/PopUpImgs'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      appLoaded: false,
    }
    this.updateTimer = null
    this.DataShedul = []

    global.afterSustemLoad = () => {
      //тут описывается то, что будет запущено при старте системы
      this.props.loginIn()
    }
    global.afterLoginned = async () => {
      let DataTables = [
        'HUMANS',
        'USER_ROLES',
        'USER_STATUS',
        'VARS',
        'STATUSES',
        'DEPTS',
        'USERS',
        'COMPANYS',
        'PROJECTS',
        'BACKLOG',
      ]
      this.DataShedul = [
        () => this.props.getDB_Data('getPROJECTS', 'PROJECTS'),
        () => this.props.getDB_Data_lazy('HUMANS', 'IMAGES_URL'),
        () => this.props.getDB_Data_lazy('CHAT_USERS'),
        () => this.props.getDB_Data_lazy('USER_ROLES'),
        () => this.props.getDB_Data_lazy('HELP_CARDS'),
        () => this.props.getDB_Data_lazy('USER_STATUS'),
        () => this.props.getDB_Data_lazy('DOCUMENTS'),
        () => this.props.getDB_Data_lazy('VARS'),
        () => this.props.getUSERS(),
        () => this.props.getDB_Data_lazy('STATUSES'),
        () => this.props.getDB_Data_lazy('DEPTS'),
        () => this.props.getBACKLOG(),
        () => this.props.getCHATS(),
      ]
      //тут описывается то, что будет запущено после авторизации
      try {
        switch (this.props.user.data.ROLE) {
          case 'ADMN':
            this.DataShedul = this.DataShedul.concat([() => this.props.getDB_Data_lazy('COMPANYS')])
            break
          case 'EMPL':
            this.DataShedul = this.DataShedul.concat([() => this.props.getDB_Data('getCOMPANYS', 'COMPANYS')])
            break
          case 'CLEN':
            this.DataShedul = this.DataShedul.concat([() => this.props.getDB_Data('getCOMPANYS', 'COMPANYS')])
            break
        }
      } catch (e) {}

      this.DataShedul.forEach((ds, i) => {
        setTimeout(() => ds(), 10 * i)
      })

      new Promise((resolve) => {
        DataTables.forEach(async (d, i) => {
          while (this.props.page[d] == null) {
            await GF.WaitingTimer(10)
          }
          //console.warn(d+' - LOADED')
          if (i == DataTables.length - 1) resolve()
        })
      }).then(() => {
        setTimeout(() => {
          this.setState({ appLoaded: true })
        }, 2000)
      })
    }
    global.startShedul = () => {
      //тут описываются все события, которые должны быть запущены после успешной авторизации
    }

    props.loadUserData_FromStorage()
  }

  componentDidMount() {
    this.updateTimer = setInterval(() => {
      this.DataShedul.forEach((ds, i) => {
        setTimeout(() => ds(), 1000 * i)
      })
    }, this.DataShedul.length * 1000 + 600000)
  }
  componentWillUnmount() {
    clearInterval(this.updateTimer)
  }

  render() {
    const { user, PopUps } = this.props
    const { appLoaded } = this.state
    let content = null

    if (user.isLogined == null) {
      content = null
    } else if (user.isLogined) {
      if (appLoaded)
        content = (
          <div key="rootView">
            <Router key="mainRouter">
              <Switch>
                <Route path="/guid/:GUID">
                  <Guid />
                </Route>
                <Route path="/">
                  <Main />
                </Route>
              </Switch>
            </Router>
          </div>
        )
      else
        content = (
          <div key="rootView">
            <LinearProgress />
          </div>
        )
    } else
      content = (
        <Router key="mainRouter">
          <Switch>
            <Route path="/guid/:GUID">
              <Guid />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
      )

    return (
      <ToastProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
          {PopUps.length > 0 ? <BlurBg visible={true} /> : <BlurBg visible={false} />}

          {PopUps.map((popUp) => {
            return popUp
          })}

          {content}
          <PopUpImgs />
        </LocalizationProvider>
      </ToastProvider>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUserData_FromStorage: (arg) => dispatch(loadUserData_FromStorage(arg)),
    loginIn: (arg) => dispatch(loginIn(arg)),
    getVARS: (arg) => dispatch(getVARS(arg)),
    getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),
    getDB_Data_lazy: (tableName, JSONparseKey) => dispatch(getDB_Data_lazy(tableName, JSONparseKey)),

    getBACKLOG: (arg) => dispatch(getBACKLOG(arg)),
    getUSERS: (arg) => dispatch(getUSERS(arg)),
    getCHATS: (arg) => dispatch(getCHATS(arg)),
    openPopImgs: (arg) => dispatch(openPopImgs(arg)),
  }
}
const mapStateToProps = (store) => {
  return {
    user: store.user,
    page: store.page,
    PopUps: store.popups.windows,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(App))
