import styled from 'styled-components'

interface IChatsStyled {
  count: number
  index: number
  isChatActive: boolean
  isIam: boolean
  isSelected: boolean
  noOneIsSelected: boolean
}

const FilterWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin-right: 15px;
`

const Chat = styled.div<IChatsStyled>`
  position: relative;
  height: 115px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #000b310a;
  box-shadow: 0 0 4px 0 #000b310a;
  transition: 0.2s;
  cursor: pointer;

  p,
  small {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #000000;
  }

  small {
    font-weight: 600;
  }

  & .MuiAvatar-root {
    position: absolute;
  }

  ${(props) =>
    props.isSelected
      ? `
background: #B4E2FB; !important;
& .MuiAvatar-root{
    background: white !important;
    color: #1976d2 !important;
}
& button{
    opacity:1;
}
`
      : `
opacity:0.7;
& button{
    opacity:0;
}
`}
  ${(props) => (props.noOneIsSelected ? `opacity:1;` : null)}
  
  &:hover {
    opacity: 1;
  }

  & .MuiAvatar-root {
    margin: auto;
    transition: 0.2s;
    background: #1976d2;
  }

  & p {
    transition: 0.2s;
    margin: 0;
    font-size: 13px;
    width: 100%;
    display: block;
    text-align: left;
    padding-left: 50px;
  }

  & small {
    margin: 0;
    transition: 0.2s;
    display: block;
    width: 100%;
    text-align: left;
    padding-left: 50px;
  }

  & button {
    transition: 0.3s;
    position: absolute;
    top: 3px;
    right: 3px;
  }
`
const ChatSelected = styled.div`
  width: 100%;
  height: 38px;

  p,
  small {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #000000;
  }

  small {
    font-weight: 600;
  }
`
const ChatsEmpty = styled.div`
  height: 100%;

  p {
    font-size: 16px;
    line-height: 20px;
  }
`
const NewMSG = styled.div`
  background: red;
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 200px;
  top: 11px;
  left: calc(50% + 11px);
`

const CompaniesWrapper = styled.div`
  width: 100%;
  height: 80px;
  padding: 15px;
  margin-bottom: 5px;
  background-color: white;
`

const MessagesWrapper = styled.div`
  height: 100%;
  padding: 20px;
  background-color: white;
  overflow: auto;
`
const Messages = styled.div`
  width: 100%;
  height: 100%;

  .MuiChip-root {
    margin-bottom: 15px;
  }
`
const MessagesContent = styled.div`
  padding-bottom: 15px;
`
const Message = styled.div<IChatsStyled>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isIam ? 'flex-end' : 'flex-start')};
  margin-bottom: 5px;
`
const LastMessage = styled.div`
  width: 100%;
  max-width: 300px;
  padding-left: 10px;
  margin-top: 30px;

  p {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const LastDate = styled.div`
  width: 100%;

  p {
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
const MSG = styled.div<IChatsStyled>`
  height: Hug (72px);
  padding: 10px;
  border-radius: 4px;
  background: #eff7ff;
  word-wrap: break-word;

  p {
    width: 100%;
    min-width: 200px;
    max-width: 587px;
    padding: 0;
    margin: 0;
    line-height: 16px;
    font-size: 14px;
    color: #2a2a2a;
  }
`
const MSG_img = styled.img<IChatsStyled>`
  max-width: 290px;
  max-height: 300px;
  min-height: 100px;
  border-radius: 3px;
  margin-top: 10px;
  object-fit: cover;
  display: block;
  ${(props) =>
    props.count > 0
      ? `
    min-width:${300 / 4 - 10}px;
    min-height:${300 / 4 - 10}px;
    width: ${300 / props.count - 10}px;
    height: ${300 / props.count - 10}px;
    display:inline-block;
    ${
      props.index % 4 == 0 || props.index == props.count
        ? `
    
    `
        : `
    margin-right: 10px;
    `
    }
    
    
`
      : `

`}
`

const SenderWrapper = styled.div`
  width: 100%;
  height: 80px;
  margin-top: 5px;
  background-color: white;
`

const ChatSender = styled.div<IChatsStyled>`
  width: 100%;
  height: 80px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.05);
  padding: 13px;
  transition: 0.5s ease 0.3s;

  & .MuiFormControl-root,
  & .MuiFilledInput-root {
    height: 100%;
    width: calc(100% - 85px);
    margin: 0 45px;
  }

  & .send-file {
    position: absolute;
    left: 10px;
    top: 20px;
    display: flex;
  }
  & .send-message {
    position: absolute;
    right: 5px;
    top: 20px;
    display: flex;
  }

  ${(props) =>
    props.isChatActive
      ? `
bottom:0px;
`
      : `
bottom:-200px;
`}
`

export {
  MSG_img,
  ChatSender,
  MSG,
  NewMSG,
  Chat,
  ChatsEmpty,
  CompaniesWrapper,
  MessagesWrapper,
  Messages,
  MessagesContent,
  Message,
  LastMessage,
  LastDate,
  SenderWrapper,
  ChatSelected,
  FilterWrapper,
}
