import React from 'react'
import { Button } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

import { FilterStyled } from './styles'

interface IButtonFilter {
  onClick?: () => void
}

const ButtonFilter = (props: IButtonFilter) => {
  const { onClick } = props

  return (
    <FilterStyled>
      <Button startIcon={<FilterAltIcon />} onClick={onClick} />
    </FilterStyled>
  )
}

export default ButtonFilter
