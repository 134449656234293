import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { openPopUp } from 'features/actions/PopUpActions'
import { getUSERS } from 'features/actions/PageActions'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import Table from 'components/Table'
import { withToast } from 'components/Toasts'
import { Avatar, Button, Chip, TextField } from '@mui/material'
import POP_User from 'pages/DataBase/pages/POP_User'

const Rows = {
  EMPL: [
    {
      title: 'ФИО',
      key: 'FIO',
    },
    {
      title: 'E-mail',
      key: 'EMAIL',
    },
    {
      title: 'Телефон',
      key: 'PHONE',
    },
    {
      title: 'Метод комуникации',
      key: 'COMMUNICATION_METHOD',
    },
    {
      title: 'Стоимость часа',
      key: 'COAST_PER_HOUR',
    },
    {
      title: 'Статус',
      key: 'STATUS_TITLE',
    },
    {
      title: 'Должность',
      key: 'EMPLOYE_GRADE',
    },
  ],
  CLEN: [
    {
      title: 'ФИО',
      key: 'FIO',
    },
    {
      title: 'E-mail',
      key: 'EMAIL',
    },
    {
      title: 'Телефон',
      key: 'PHONE',
    },
    {
      title: 'Метод комуникации',
      key: 'COMMUNICATION_METHOD',
    },
    {
      title: 'Статус',
      key: 'STATUS_TITLE',
    },
    {
      title: 'Компания',
      key: 'COMPANY',
    },
  ],
}

class Users extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMSG: null,
      succes: false,
      filter: null,
      Data: [],
    }
    this.input = {
      dataWindow: {},
      main: {},
    }
    this.checkBoxs = {}
  }

  PreDelete() {
    let promises = []
    let somethingDoing = false

    Object.keys(this.checkBoxs).forEach((key, index) => {
      if (this.checkBoxs[key].checked) {
        somethingDoing = true
        this.checkBoxs[key].checked = false
        promises.push(this.Delete(key))
        delete this.checkBoxs[key]
      }
    })

    if (!somethingDoing) return

    Promise.allSettled(promises).then(() => {
      this.props.getUSERS()
      this.props.addToast('Удалено', { appearance: 'success', autoDismiss: true })
    })
  }

  Delete(ID) {
    return new Promise((resolve, reject) => {
      let data = {
        operation: 'deleteSome',
        Table: 'USERS',
        ID,
      }

      GF.API_task(data)
        .then((res) => {
          setTimeout(() => resolve(), 1)
        })
        .catch(() => reject())
    })
  }

  changeBlock(ID) {
    return new Promise((resolve, reject) => {
      let data = {
        operation: 'changeBlockUser',
        ID,
      }

      GF.API_task(data)
        .then((res) => {
          setTimeout(() => resolve(), 1)
        })
        .catch(() => reject())
    })
  }

  Block() {
    let somethingDoing = false
    let promises = []
    Object.keys(this.checkBoxs).forEach((key, index) => {
      if (this.checkBoxs[key].checked) {
        somethingDoing = true
        this.checkBoxs[key].checked = false
        promises.push(this.changeBlock(key))
      }
    })

    if (!somethingDoing) return

    Promise.allSettled(promises).then(() => {
      this.props.getUSERS()
      this.props.addToast('Значение обновлено', { appearance: 'success', autoDismiss: true })
    })
  }

  render() {
    const { USERS } = this.props
    const { filter, Data } = this.state
    return (
      <View>
        <div className="mt-4">
          <TextField
            className="me-2"
            label="Поиск"
            variant="outlined"
            size="small"
            onKeyUp={(e) => this.setState({ filter: e.target.value.length > 0 ? e.target.value.toUpperCase() : null })}
          />

          <Button
            className="me-2 align-bottom"
            variant="text"
            onClick={() => {
              Object.keys(this.checkBoxs).forEach((key, index) => {
                this.checkBoxs[key].checked = true
              })
            }}
          >
            Выбрать все
          </Button>

          <Button
            className="me-2 align-bottom"
            variant="text"
            onClick={() => {
              Object.keys(this.checkBoxs).forEach((key, index) => {
                this.checkBoxs[key].checked = false
              })
            }}
          >
            Cнять выделение
          </Button>

          <Button
            className="me-2 align-bottom"
            variant="text"
            onClick={() => {
              this.Block()
            }}
          >
            Изменить статус
          </Button>

          <Button
            className="me-2 align-bottom"
            variant="text"
            onClick={() => {
              this.props.openPopUp(<POP_User ROLE={this.props.role} />)
            }}
          >
            Добавить
          </Button>
        </div>

        <Table
          className="mt-1"
          rows={Rows[this.props.role]}
          canCheck={true}
          checkBoxs={this.checkBoxs}
          data={this.props.USERS.filter(
            (a) => a.ROLE == this.props.role && (filter == null || a.FIO.toUpperCase().indexOf(filter) != -1),
          )}
          itemOnClick={({ data, index }) => {
            this.props.openPopUp(<POP_User ID_OBJECT={data.ID} />)
          }}
          rowOnClick={({ data, index }) => {
            // debugger
          }}
          renderItem={({ data, index }) =>
            Rows[this.props.role].map((r) => {
              switch (r.key) {
                case 'EMPLOYE_GRADE':
                  return (
                    <td>
                      {data.DPTS.map((dp) => (
                        <Chip
                          style={{
                            backgroundColor: dp.COLOR,
                            color: 'white',
                          }}
                          className="me-1 mb-1"
                          label={dp.TITLE + ' | ' + dp.GRADE}
                          size="small"
                        />
                      ))}
                    </td>
                  )
                case 'COMPANY':
                  return <td>{data.COMPANY == null ? null : data.COMPANY.TITLE}</td>
                case 'PHONE':
                  return <td>{GF.phoneFormatter(data.PHONE)}</td>
                case 'EMAIL':
                  return (
                    <td>
                      <a href={'mailto:' + data[r.key]} target="black">
                        {data[r.key]}
                      </a>
                    </td>
                  )
                case 'FIO':
                  return (
                    <td>
                      <Chip
                        avatar={
                          data.IMAGES_URL == null ? (
                            <Avatar>{data.FIO[0]}</Avatar>
                          ) : (
                            <Avatar src={data.IMAGES_URL[0]} alt={data.FIO[0]} />
                          )
                        }
                        size="small"
                        label={GF.FIO(data.FIO, 2)}
                      />
                    </td>
                  )
                default:
                  return <td>{data[r.key]}</td>
              }
            })
          }
        />
      </View>
    )
  }
}

const View = styled.div`
  height: 100%;
`

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    getUSERS: (arg) => dispatch(getUSERS(arg)),
  }
}

const mapStateToProps = (store) => {
  return {
    USERS: store.page.USERS,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToast(Users))
