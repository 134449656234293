import React from 'react'
import { connect } from 'react-redux'
import { closePopImgs } from 'features/actions/PopUpActions'
import styled from 'styled-components'
import { GlobalFunctions } from 'utils/GlobalFunctions'
import { IconButton, Slider, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ZoomIn, ZoomOut } from '@mui/icons-material'
import Draggable from 'react-draggable'

class PopUpImgs extends React.Component {
  constructor() {
    super()
    this.state = {
      visible: false,
      Zoom: 1,
    }
    this.key = GlobalFunctions.randomString()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.popUppedImgs.aImgs.length == 0) {
      this.setState({ Zoom: 1 })
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ visible: true })
    }, 1)
  }

  render() {
    const { Zoom } = this.state
    const { popUppedImgs } = this.props
    if (popUppedImgs.aImgs.length == 0) return <div />
    return [
      <Bg
        style={{
          zIndex: 1000,
        }}
      >
        <IconButton
          className="float-end"
          size="small"
          onClick={() => this.props.closePopImgs()}
          style={{
            position: 'absolute',
            right: 50,
            background: 'white',
            zIndex: 5,
            top: 50,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>

        <Content>
          {popUppedImgs.aImgs.map((img) => (
            <Draggable>
              <div
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  width: ' 100vw',
                  height: '100vh',
                }}
              >
                <Img img={img} Zoom={Zoom} />
              </div>
            </Draggable>
          ))}
        </Content>
        <Zoomer>
          <Stack spacing={2} direction="row" alignItems="center">
            <IconButton
              color="inherit"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                let tmp = Zoom
                tmp -= 0.5
                if (tmp >= 0.5) this.setState({ Zoom: tmp })
                else this.setState({ Zoom: 0.5 })
              }}
            >
              {' '}
              <ZoomOut />{' '}
            </IconButton>
            <Slider
              min={0.5}
              step={0.1}
              max={3}
              aria-label="Volume"
              value={Zoom}
              onChange={(e, v) => this.setState({ Zoom: v })}
            />
            <IconButton
              color="inherit"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                let tmp = Zoom
                tmp += 0.5
                if (tmp <= 3) this.setState({ Zoom: tmp })
                else this.setState({ Zoom: 3 })
              }}
            >
              {' '}
              <ZoomIn />{' '}
            </IconButton>
          </Stack>
        </Zoomer>
      </Bg>,
    ]
  }
}

const Zoomer = styled.div`
  box-shadow: 2px 5px 30px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 300px;
  background: white;
  border-radius: 10px;
  padding: 10px;
  left: calc(50vw - 150px);
  bottom: 20px;
  z-index: 1000;
`

const Img = styled.div`
  transition: 0.2s;
  ${(props) => `
transform:translate(-50%, -50%) scale(${props.Zoom});
background:url(${props.img});
background-size: contain;
background-position: center;
background-repeat: no-repeat;
`}
  width: 80vw;
  height: 80vh;
  position: absolute;
  left: 50%;
  top: 50%;

  border-radius: 5px;
`

const Content = styled.div``
const Bg = styled.div`
  position: fixed;
  top: 0;
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
`

const mapDispatchToProps = (dispatch) => {
  return {
    closePopImgs: () => dispatch(closePopImgs()),
  }
}

const mapStateToProps = (store) => {
  return {
    popUppedImgs: store.popups.popUppedImgs,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpImgs)
