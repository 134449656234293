import { Autocomplete, Avatar, Button, Chip, TextField } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { getBACKLOG, getDB_Data } from 'features/actions/PageActions'
import { closePopUp, openPopUp } from 'features/actions/PopUpActions'
import { GlobalFunctions as GF } from 'utils/GlobalFunctions'

class POP_WorkCoast extends React.Component {
  constructor(props) {
    super()
    this.state = {
      Self: {
        ID_TASK: props.ID_TASK,
      },
    }
    this.input = []
  }

  componentDidMount() {
    if (this.props.ID_OBJECT != null) {
      GF.API_task({
        operation: 'getBACKLOG_WORK_COAST',
        ID_TASK: this.props.ID_TASK,
        ID: this.props.ID_OBJECT,
      }).then((data) => {
        this.setState({ Self: { ...this.state.Self, ...data[0] } })
      })
    }
  }

  Delete() {
    GF.API_task({
      operation: 'deleteSome',
      Table: 'BACKLOG_WORK_COAST',
      ID: this.state.Self.ID,
    }).then((res) => {
      this.props.closePopUp()
      this.props.callBack()
    })
  }

  Save() {
    const { Self } = this.state
    const { state, props } = this

    if (!(Self.HOURS * 1 > 0 && Self.COAST * 1 > 0)) {
      alert('заполните поля трудозатрат')
      return
    }

    let reqv = {
      operation: 'createUpdateSome',
      Table: 'BACKLOG_WORK_COAST',
      data: {
        ID_TASK: Self.ID_TASK,
        ID_USER: Self.ID_USER,
        COAST: Self.COAST,
        HOURS: Self.HOURS,
        TEXT: Self.TEXT,
      },
    }

    if (props.user.ROLE != 'ADMN') {
      if (reqv.data.ID_USER == null) {
        reqv.data.ID_USER = props.user.ID
      }
    }

    delete reqv.data.ID

    if (this.props.ID_OBJECT != null) reqv.ID = this.props.ID_OBJECT

    GF.API_task(reqv).then((res) => {
      this.props.closePopUp()
      this.props.callBack()
    })
  }

  render() {
    const { COMPANYS, user } = this.props
    const { Self } = this.state

    const USERS_filterd = this.props.USERS.filter((u) => u.ROLE != 'ADMN' && u.ROLE != 'CLEN')

    if (Self.ID == null && this.props.ID_OBJECT != null) return <div />

    return (
      <POP>
        <h4>Запись трудозатрат</h4>

        {this.props.user.ROLE != 'ADMN' ? null : (
          <Autocomplete
            options={USERS_filterd}
            value={Self.ID_USER == null ? null : USERS_filterd.find((u) => u.ID == Self.ID_USER)}
            size="small"
            variant="filled"
            getOptionLabel={(option) => option.FIO}
            renderOption={(props, option) => (
              <div {...props}>
                <div
                  style={{
                    width: 'max-content',
                    margin: 'auto',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Chip
                    style={{
                      width: 'max-content',
                      margin: 'auto',
                    }}
                    size="small"
                    avatar={
                      option.IMAGES_URL == null ? (
                        <Avatar>{option.FIO[0]}</Avatar>
                      ) : (
                        <Avatar src={option.IMAGES_URL[0]} />
                      )
                    }
                    label={GF.FIO(option.FIO, 2)}
                  />
                  <small className="d-block mx-auto text-center w-100">
                    {option.DPTS.map((d, i) => d.TITLE + (i != option.DPTS.length - 1 ? ',' : '') + ' ')}
                  </small>
                </div>
              </div>
            )}
            onChange={(event, newValue) => {
              this.setState({
                Self: {
                  ...Self,
                  ID_USER: newValue == null ? null : newValue.ID,
                },
              })
            }}
            renderInput={(params) => <TextField {...params} variant="filled" label="Исполнитель" size="small" />}
          />
        )}

        <TextField
          className="w-100 mb-2"
          margin="dense"
          size="small"
          variant="filled"
          label="Стоимость часа (руб)"
          value={Self.COAST}
          onChange={(e) => {
            let value = e.target.value
              .replace(/[^0-9.,]/gm, '')
              .replace(',', '.')
              .replace('..', '.')
            if (value == '') value = null

            e.target.value = value
            this.setState({
              Self: {
                ...Self,
                COAST: value * 1,
              },
            })
          }}
        />

        <TextField
          className="w-100 mb-2"
          margin="dense"
          size="small"
          variant="filled"
          label="Часов трудозатрат (час)"
          value={Self.HOURS}
          onChange={(e) => {
            let value = e.target.value.replace(/[^0-9.,]/gm, '').replace(',', '.')
            if (value == '') value = null

            e.target.value = value
            this.setState({
              Self: {
                ...Self,
                HOURS: value * 1,
              },
            })
          }}
        />

        <small>Итого {GF.makeCoast((Self.COAST || 0) * (Self.HOURS || 1))} руб</small>

        <hr />

        <TextField
          className="w-100 mb-2"
          margin="dense"
          size="small"
          variant="filled"
          multiline
          rows={5}
          label="Комментарий / причина"
          value={Self.TEXT}
          onChange={(e) => {
            let value = e.target.value == '' ? null : e.target.value
            this.setState({
              Self: {
                ...Self,
                TEXT: value,
              },
            })
          }}
        />

        <div className="mt-3" />

        {this.props.ID_OBJECT == null ? null : (
          <Button className="float me-2" color="error" onClick={() => this.Delete()}>
            Удалить
          </Button>
        )}

        <Button className="float-end" onClick={() => this.Save()}>
          Сохранить
        </Button>
      </POP>
    )
  }
}

const POP = styled.div`
  width: 400px;
`

const mapDispatchToProps = (dispatch) => {
  return {
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    openPopUp: (arg) => dispatch(openPopUp(arg)),
    getBACKLOG: (arg) => dispatch(getBACKLOG(arg)),
    getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),
  }
}

const mapStateToProps = (store) => {
  return {
    USERS: store.page.USERS,
    COMPANYS: store.page.COMPANYS,
    user: store.user.data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_WorkCoast)
