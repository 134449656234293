import styled from 'styled-components'
import { down, up } from 'styled-breakpoints'

import { GFlexCenter } from 'styles/global'

interface IMenuStyledProps {
  isOpen?: boolean
  isSelected?: boolean
}

const Burger = styled.div<IMenuStyledProps>`
  ${up('md')} {
    display: none;
  }

  height: 32px;
  width: 32px;
  position: fixed;
  top: 40px;
  left: 30px;
  cursor: pointer;
  transition: 0.3s;
  padding-top: 7px;
  z-index: 199;

  & div {
    transform-origin-x: 0%;
    transition: 0.3s;
    background: rgba(0, 0, 0, 0.8);
    height: 3px;
    width: 100%;
    margin-bottom: 5px;
    border-radius: 2px;
  }

  & div.b2 {
    transition: all 0.3s, transform 0.3s ease 0.1s;
  }

  ${(props) =>
    props.isOpen
      ? `
    & div.b1{
        transform:scale(0.8,1) translateY(8px) rotate(-34deg);
    }
    & div.b3{
        transform:scale(0.8,1) translateY(-8px) rotate(34deg);
    }
    & div.b2{
        transform:scale(0,1);
    }
    & div:hover{
        margin-bottom:5px;
        background:rgba(0,0,0,1);
    }
    &:hover{
        transform:scale(1);
        padding-top: 7px;
    }
`
      : `
    &:hover div{
        margin-bottom:8px;
        background:rgba(0,0,0,1);
    }
    &:hover{
        transform:scale(1.1);
        padding-top: 4px;
    
    }
`}
`

const LeftMenuView = styled.div<IMenuStyledProps>`
  width: 230px;
  height: calc(100vh);
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  background: white;
  border-radius: 0 20px 20px 0;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
  z-index: 198;

  ${down('md')} {
    transition: 0.5s ease-in-out;
    ${(props) =>
      props.isOpen
        ? `
        transform:translateX(0);
        box-shadow: 10px 0px 30px rgba(0,0,0,0.1);
    `
        : `
        transform:translateX(-60vw);
        box-shadow: 10px 0px 30px rgba(0,0,0,0);
    `}
  }
`
const LogoContainer = styled.div`
  ${GFlexCenter};
  height: 63px;
  margin: 15px 0 40px 0;

  img {
    width: 118px;
    height: 63px;
  }
`
const Logo = styled.img`
  height: 35px;
  margin-top: 5px;
  margin-left: 5px;
  float: left;

  ${down('md')} {
    height: 26px;
    margin-top: 9px;
    margin-left: 70px;
  }
`
const LinkContainer = styled.div<IMenuStyledProps>`
  a {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    color: gray;
    color: rgb(151, 158, 167);
    text-decoration: none;
    padding: 10px 5px;
    width: 100%;
    height: 50px;
    cursor: pointer;
    transition: 0.3s;

    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0;
    text-align: left;
  }
  a:hover {
    color: gray;
    background: #b4e2fb;
    border-radius: 6px;
  }

  ${(props) =>
    props.isSelected
      ? `
background: #b4e2fb;
border-radius: 6px;
`
      : ``}
`
const IconContainer = styled.div<IMenuStyledProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;

  width: 46px;
  height: 36px;
  border-radius: 6px;

  background: white;

  img {
    width: 28px;
    height: 28px;
    padding: 2.3px 1.6px 2.3px 1.6px;
  }

  ${(props) =>
    props.isSelected
      ? `
background: #eff7ff;
`
      : ``}
`
const ListElem = styled.p<IMenuStyledProps>`
  display: inline-block;
  margin: 0;

  transition: 0.3s;
  ${(props) =>
    props.isSelected
      ? `
color:rgb(33, 37, 41);
`
      : ``}
`

const ContentView = styled.div`
  margin-left: 230px;
  width: calc(100vw - 230px);

  height: calc(100vh - 50px);
  overflow-y: auto;
  margin-top: 50px;
  overflow-x: hidden;
  padding: 70px 20px 20px 20px;

  ${down('md')} {
    margin-left: 0;
    width: 100vw;
    padding-bottom: 20vh;
    overflow-x: auto;
  }
`

const TopMenuView = styled.div`
  height: 85px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(232, 236, 238);
  z-index: 197;
`
const PageTitle = styled.div`
  padding-top: 30px;
  margin-left: 250px;

  h1 {
    font-size: 40px;
    font-weight: 500;
    line-height: 47px;
    letter-spacing: 0;
    text-align: left;
  }

  @media (max-width: 1080px) {
    margin-right: 200px;

    h1 {
      font-size: 30px;
      line-height: 37px;
    }
  }

  ${down('md')} {
    margin-left: 85px;
  }

  ${down('sm')} {
    h1 {
      display: none;
    }
  }
`
const AccountContainer = styled.div`
  position: absolute;
  top: 35px;
  right: 20px;
`

export {
  Burger,
  LeftMenuView,
  ListElem,
  ContentView,
  TopMenuView,
  Logo,
  IconContainer,
  LinkContainer,
  LogoContainer,
  PageTitle,
  AccountContainer,
}
