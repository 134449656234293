import React from 'react'
import { connect } from 'react-redux'
import { DateTimePicker, TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Tab,
  TextField,
} from '@mui/material'

import styled from 'styled-components'

import { GlobalFunctions as GF } from 'utils/GlobalFunctions'
import { getBACKLOG, getDB_Data } from 'features/actions/PageActions'
import { closePopUp, openPopImgs, openPopUp } from 'features/actions/PopUpActions'

import Question from 'components/PopUp/Views/Question'
import Input_image from 'components/UI/inputs/InputImage/Input_image'
import POP_Project from 'pages/Tasks/POP_Project'
import POP_SelectEmploy from 'pages/Tasks/POP_SelectEmploy'
import POP_WorkCoast from 'pages/Tasks/POP_WorkCoast'

const form_fields = [
  {
    label: 'Что нужно сделать',
    name: 'TEXT',
    multiline: true,
    rows: 4,
  },
]

// class POP_Task extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       Self: {
//         AUTHOR: null,
//         COMPANY: null,
//         PROJECT: null,
//         STORY: null,
//         DATE_DEADLINE: null,
//         ...props.SelfDefault,
//       },
//       BACKLOG_WORK_COAST: null,
//       errorTEXT: null,
//       tab: 'История',
//     }
//     this.input = []
//     this.filesToUpload = []
//     this.filesToDelete = []
//   }
//
//   componentDidMount() {
//     const {} = this.state
//     const { COMPANYS, PROJECTS, ID_OBJECT, BACKLOG, HUMANS, USERS, user } = this.props
//
//     if (ID_OBJECT != null) {
//       var Self_tmp = BACKLOG.find((p) => p.ID == ID_OBJECT)
//       if (Self_tmp == null) return
//       var Self = {}
//       Object.assign(Self, Self_tmp)
//
//       var keys = ['DATE_CREATE']
//       keys.forEach((key) => {
//         Self[key] = GF.SQLdateToJsDate(Self[key])
//       })
//       Object.keys(this.input).forEach((key) => {
//         this.input[key].value = Self[key]
//       })
//
//       var keys = ['DATE_DEADLINE']
//       keys.forEach((key) => {
//         Self[key] = GF.SQLdateToJsDate(Self[key])
//       })
//
//       Self.STORY = null
//
//       this.setState({ Self })
//       setTimeout(() => {
//         this.getStory()
//       }, 1)
//
//       GF.API_task({
//         operation: 'getBACKLOG_WORK_COAST',
//         ID_TASK: ID_OBJECT,
//       }).then((data) => {
//         this.setState({ BACKLOG_WORK_COAST: data })
//       })
//     } else {
//       this.setState({
//         Self: {
//           COMPANY: this.props.COMPANYS[1],
//           PROJECT: null,
//           STORY: null,
//           DATE_DEADLINE: null,
//         },
//       })
//     }
//   }
//
//   getStory() {
//     const { Self } = this.state
//     const { ID_OBJECT, HUMANS, user } = this.props
//     var Self_tmp = {}
//     Object.assign(Self_tmp, Self)
//
//     GF.API_task({
//       operation: 'getBACKLOG_STORY',
//       ID_TASK: ID_OBJECT,
//     }).then((data) => {
//       Self_tmp.STORY = data
//
//       Self_tmp.STORY.forEach((s, i) => {
//         Self_tmp.STORY[i].HUMAN = HUMANS.find((h) => h.ID_USER == s.ID_USER)
//         delete Self_tmp.STORY[i].ID_HUMAN
//       })
//
//       this.setState({ Self: Self_tmp })
//     })
//   }
//
//   CloseTask() {
//     new Promise((resolve, reject) => {
//       this.props.openPopUp(
//         <Question
//           text="Вы уверены что хотите завершить выбранную задачу?"
//           buttons={[
//             <Button className="ms-1 me-1" onClick={resolve}>
//               Да
//             </Button>,
//             <Button className="ms-1 me-1" onClick={reject}>
//               Нет
//             </Button>,
//           ]}
//         />,
//       )
//     })
//       .then(() => {
//         this.props.closePopUp()
//         if (this.props.user.ROLE != 'EMPL') {
//           GF.API_task({
//             operation: 'closeTask',
//             ID_TASK: this.props.ID_OBJECT,
//           }).then((res) => {
//             this.props.getBACKLOG()
//             this.props.closePopUp()
//           })
//         } else {
//           new Promise((resolve, reject) => {
//             this.props.openPopUp(
//               <Question
//                 text="Комментарий к проделаной работе"
//                 contentObject={
//                   <TextField
//                     className="w-100"
//                     margin="dense"
//                     size="small"
//                     multiline
//                     rows={5}
//                     label="Описание работы"
//                     value={this.state.errorTEXT}
//                     onChange={(e) => {
//                       this.setState({ errorTEXT: e.target.value })
//                     }}
//                   />
//                 }
//                 buttons={[
//                   <Button className="ms-1 me-1" onClick={resolve}>
//                     Продолжить
//                   </Button>,
//                   <Button className="ms-1 me-1" onClick={reject}>
//                     Отмена
//                   </Button>,
//                 ]}
//               />,
//             )
//           })
//             .then(() => {
//               this.props.closePopUp()
//               this.props.closePopUp()
//               GF.API_task({
//                 operation: 'closeTask',
//                 ID_TASK: this.props.ID_OBJECT,
//                 TEXT: this.state.errorTEXT,
//               }).then((res) => {
//                 this.props.getBACKLOG()
//               })
//             })
//             .catch(() => {
//               this.props.closePopUp()
//             })
//         }
//       })
//       .catch(() => {
//         this.props.closePopUp()
//       })
//   }
//
//   uncloceTask() {
//     const { Self } = this.state
//     new Promise((resolve, reject) => {
//       this.props.openPopUp(
//         <Question
//           text="Вы хотите вернуть задачу обратно в работу?"
//           buttons={[
//             <Button className="ms-1 me-1" onClick={resolve}>
//               Да
//             </Button>,
//             <Button className="ms-1 me-1" onClick={reject}>
//               Нет
//             </Button>,
//           ]}
//         />,
//       )
//     })
//       .then(() => {
//         this.props.closePopUp()
//         new Promise((resolve, reject) => {
//           this.props.openPopUp(
//             <Question
//               text="В чем ошибка исполнителя? Опишите наиболее подробно причину отклонения"
//               contentObject={
//                 <TextField
//                   className="w-100"
//                   margin="dense"
//                   size="small"
//                   multiline
//                   rows={5}
//                   label="Наставление к задаче"
//                   value={this.state.errorTEXT}
//                   onChange={(e) => {
//                     this.setState({ errorTEXT: e.target.value })
//                   }}
//                 />
//               }
//               buttons={[
//                 <Button className="ms-1 me-1" onClick={resolve}>
//                   Продолжить
//                 </Button>,
//                 <Button className="ms-1 me-1" onClick={reject}>
//                   Отмена
//                 </Button>,
//               ]}
//             />,
//           )
//         })
//           .then(() => {
//             GF.API_task({
//               operation: 'uncloceTask',
//               ID_TASK: this.props.ID_OBJECT,
//               TEXT: this.state.errorTEXT,
//             }).then((res) => {
//               this.props.getBACKLOG()
//               this.props.closePopUp()
//             })
//           })
//           .finally(() => {
//             this.props.closePopUp()
//           })
//       })
//       .catch(() => {
//         this.props.closePopUp()
//       })
//   }
//
//   backFromRedirect() {
//     const { ID_OBJECT, user } = this.props
//     GF.API_task({
//       operation: 'RedirectTask',
//       ID_TASK: ID_OBJECT,
//       ID_EMPLOY: null,
//       TEXT: 'Возврат задачи с назначения',
//     }).then((data) => {
//       this.getStory()
//       this.props.getBACKLOG()
//     })
//   }
//
//   Redirect() {
//     const { ID_OBJECT, user } = this.props
//     new Promise((resolve, reject) => {
//       this.props.openPopUp(<POP_SelectEmploy callBack={resolve} />)
//     }).then(({ USER, TEXT }) => {
//       GF.API_task({
//         operation: 'RedirectTask',
//         ID_TASK: ID_OBJECT,
//         ID_EMPLOY: USER.ID_EMPLOY,
//         TEXT,
//       }).then((data) => {
//         this.getStory()
//         this.props.getBACKLOG()
//         if (user.ROLE == 'EMPL') this.props.closePopUp()
//       })
//     })
//   }
//
//   getToWork() {
//     const { ID_OBJECT } = this.props
//     GF.API_task({
//       operation: 'getToWork',
//       ID_TASK: ID_OBJECT,
//     }).then((data) => {
//       this.getStory()
//       this.props.getBACKLOG()
//       setTimeout(() => this.componentDidMount(), 300)
//
//       setTimeout(() => this.componentDidMount(), 800)
//     })
//   }
//
//   Save() {
//     const { Self } = this.state
//     const { ID_OBJECT, user } = this.props
//
//     const canChange = Self.ID == null || user.ROLE == 'ADMN' || Self?.AUTHOR?.ID_USER == user.ID
//
//     if (!GF.checkReqvireds([this.input.TITLE])) return
//     let data = {
//       ID_PROJECT: Self.PROJECT.ID,
//       TEXT: Self.TEXT,
//     }
//
//     if (user.ROLE == 'ADMN') data.CLEN_VISIBLE = Self.CLEN_VISIBLE
//
//     let reqv = {
//       operation: 'createUpdateSome',
//       Table: 'BACKLOG_TASKS',
//       data,
//     }
//     if (canChange) {
//       reqv.data.ID_AUTHOR = user.ID
//       reqv.data.DATE_DEADLINE = GF.makeNormalDate(Self.DATE_DEADLINE, 'yyyy-mm-dd H:M')
//     }
//
//     if (canChange) {
//       reqv.data.PREM_HOURS = this.state.Self.PREM_HOURS
//       reqv.data.PREM_COAST = this.state.Self.PREM_COAST
//     }
//
//     if (ID_OBJECT != null) reqv.ID = ID_OBJECT
//
//     if (this.props.ID_OBJECT != null) reqv.ID = this.props.ID_OBJECT
//
//     GF.API_task(reqv).then((res) => {
//       var promises = []
//       if (this.filesToUpload.length > 0)
//         promises.push(
//           GF.API_files(
//             {
//               operation: 'setIMAGE',
//               Table: 'BACKLOG_TASKS',
//               ID: res.ID,
//             },
//             this.filesToUpload.splice(0),
//           ),
//         )
//
//       if (this.filesToDelete.length > 0)
//         promises.push(
//           GF.API_task({
//             operation: 'deleteIMAGE',
//             ID: this.props.ID_OBJECT,
//             Table: 'BACKLOG_TASKS',
//             filesToDelete: JSON.stringify(this.filesToDelete.splice(0)),
//           }),
//         )
//       Promise.all(promises).then(() => {
//         this.props.getBACKLOG()
//       })
//
//       this.props.getDB_Data('getPROJECTS', 'PROJECTS')
//       this.props.getBACKLOG()
//       setTimeout(() => {
//         if (this.props.callBack != null) {
//           this.props.callBack()
//         }
//       }, 500)
//       this.props.closePopUp()
//     })
//   }
//
//   renderView() {
//     const { state, props } = this
//     const { Self } = state
//     const { user } = props
//
//     const Calc = {
//       WORK_COAST: {
//         HOURS: 0,
//         COAST: 0,
//         REAL_COAST: 0,
//       },
//     }
//
//     if (state.BACKLOG_WORK_COAST != null) {
//       state.BACKLOG_WORK_COAST.forEach((wc) => {
//         Calc.WORK_COAST.HOURS += wc.HOURS
//
//         Calc.WORK_COAST.REAL_COAST += wc.COAST * wc.HOURS
//       })
//     }
//
//     const tabs = {
//       История: () => (
//         <>
//           <ScrollView className="hiddenScroll" length={Self.STORY == null ? 1 : Self.STORY.length}>
//             {Self.STORY == null ? (
//               <LinearProgress />
//             ) : Self.STORY.length == 0 ? (
//               <small>Действий не обнаружено</small>
//             ) : (
//               Self.STORY.map((story) => {
//                 return (
//                   <Card sx={{ mb: 2 }}>
//                     {story.IS_AUTOCREATE == 1 ? (
//                       <small className="mt-2 ps-3 pe-3">{GF.makeNormalDate(story.DATE_CREATE, 'dd.mm.yyyy H:M')}</small>
//                     ) : (
//                       <CardHeader
//                         avatar={
//                           story.HUMAN?.IMAGES_URL == null ? (
//                             <Avatar sx={{ bgcolor: 'red' }}>{story.HUMAN?.FIO?.[0]}</Avatar>
//                           ) : (
//                             <Avatar src={story.HUMAN?.IMAGES_URL?.[0]} alt={story.HUMAN.FIO[0]} />
//                           )
//                         }
//                         title={GF.FIO(story.HUMAN?.FIO, 2)}
//                         subheader={GF.makeNormalDate(story.DATE_CREATE, 'dd.mm.yyyy H:M')}
//                       />
//                     )}
//                     <p className="ps-3 pe-3">{story.TEXT}</p>
//                   </Card>
//                 )
//               })
//             )}
//           </ScrollView>
//         </>
//       ),
//       Трудозатраты: () =>
//         user.ROLE == 'CLEN' ? (
//           <p>Раздел для администрации</p>
//         ) : (
//           <>
//             <small style={{ color: 'black' }}>Предварительная оценка</small>
//             <TextField
//               className="w-100 mb-2"
//               margin="dense"
//               size="small"
//               variant="filled"
//               label="Стоимость часа (руб)"
//               disabled={(user.ROLE != 'ADMN' && user.ROLE != 'CLEN') || Self.ID_STATUS == 8}
//               value={Self.PREM_COAST}
//               onChange={(e) => {
//                 let value = e.target.value.replace(/[^0-9.,]/gm, '').replace(',', '.') * 1
//                 if (value <= 0) value = null
//                 e.target.value = value
//                 this.setState({
//                   Self: {
//                     ...Self,
//                     PREM_COAST: value,
//                   },
//                 })
//               }}
//             />
//
//             <TextField
//               className="w-100 mb-2"
//               margin="dense"
//               size="small"
//               variant="filled"
//               label="Кол-во трудозатрат (часов)"
//               disabled={(user.ROLE != 'ADMN' && user.ROLE != 'CLEN') || Self.ID_STATUS == 8}
//               value={Self.PREM_HOURS}
//               onChange={(e) => {
//                 let value = e.target.value.replace(/[^0-9.,]/gm, '').replace(',', '.') * 1
//                 if (value <= 0) value = null
//                 e.target.value = value
//                 this.setState({
//                   Self: {
//                     ...Self,
//                     PREM_HOURS: value,
//                   },
//                 })
//               }}
//             />
//
//             <small>Итого {GF.makeCoast((Self.PREM_COAST || 0) * (Self.PREM_HOURS || 1))} руб</small>
//             <hr />
//
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center',
//               }}
//             >
//               <small style={{ color: 'black' }}>
//                 Фактические трудозатраты
//                 {Calc.WORK_COAST.COAST == 0 && Calc.WORK_COAST.HOURS == 0 ? null : (
//                   <div
//                     style={{
//                       display: 'flex',
//                       gap: 20,
//                       alignItems: 'center',
//                     }}
//                   >
//                     <small
//                       style={{
//                         color: Calc.WORK_COAST.REAL_COAST / Calc.WORK_COAST.HOURS > Self.PREM_COAST ? 'red' : '',
//                       }}
//                     >
//                       ~{GF.makeCoast(Calc.WORK_COAST.REAL_COAST / Calc.WORK_COAST.HOURS)}руб/час
//                     </small>
//                     <small
//                       style={{
//                         color: Calc.WORK_COAST.HOURS > Self.PREM_HOURS ? 'red' : '',
//                       }}
//                     >
//                       {GF.makeCoast(Calc.WORK_COAST.HOURS)}час
//                     </small>
//
//                     <small
//                       style={{
//                         color: Calc.WORK_COAST.REAL_COAST > Self.PREM_COAST * Self.PREM_HOURS ? 'red' : '',
//                       }}
//                     >
//                       Итого {GF.makeCoast(Calc.WORK_COAST.REAL_COAST)} руб
//                     </small>
//                   </div>
//                 )}
//               </small>
//               <Button
//                 color="primary"
//                 size="small"
//                 onClick={() => {
//                   this.props.openPopUp(
//                     <POP_WorkCoast
//                       ID_TASK={Self.ID}
//                       callBack={() => {
//                         this.componentDidMount()
//                         setTimeout(() => {
//                           if (this.props.callBack != null) {
//                             this.props.callBack()
//                           }
//                         }, 500)
//                       }}
//                     />,
//                   )
//                 }}
//               >
//                 Записать время
//               </Button>
//             </div>
//
//             <ScrollView
//               style={{
//                 maxHeight: '30vh',
//               }}
//             >
//               {state.BACKLOG_WORK_COAST == null ? (
//                 <LinearProgress />
//               ) : state.BACKLOG_WORK_COAST.length == 0 ? (
//                 <small>Нет списанных трудозатрат</small>
//               ) : (
//                 state.BACKLOG_WORK_COAST.map((story) => (
//                   <Card
//                     sx={{ mb: 2 }}
//                     onClick={(e) => {
//                       this.props.openPopUp(
//                         <POP_WorkCoast
//                           ID_OBJECT={story.ID}
//                           ID_TASK={Self.ID}
//                           callBack={() => this.componentDidMount()}
//                         />,
//                       )
//                     }}
//                   >
//                     <CardHeader
//                       title={GF.FIO(story.FIO, 2)}
//                       avatar={
//                         story.IMAGES_URL == null ? (
//                           <Avatar sx={{ bgcolor: 'red' }}>{story.FIO[0]}</Avatar>
//                         ) : (
//                           <Avatar src={story.IMAGES_URL[0]} alt={story.HUMAN.FIO[0]} />
//                         )
//                       }
//                       subheader={GF.makeNormalDate(story.DATE_CREATE, 'dd.mm.yyyy H:M')}
//                     />
//                     <CardContent>
//                       <div
//                         style={{
//                           display: 'flex',
//                           alignItems: 'center',
//                           justifyContent: 'space-between',
//                         }}
//                       >
//                         <b
//                           style={{
//                             fontSize: 14,
//                             lineHeight: '14px',
//                             marginTop: 0,
//                             marginBottom: 0,
//                           }}
//                         >
//                           {story.HOURS == null ? '' : GF.makeCoast(story.HOURS) + 'час по '}
//                           {story.COAST == null ? '' : GF.makeCoast(story.COAST) + 'руб '}
//                         </b>
//                         <small className="d-block">
//                           Итого {GF.makeCoast((story.COAST || 0) * (story.HOURS || 1))} руб
//                         </small>
//                       </div>
//                       {story.TEXT != null && story.TEXT != '' && story.TEXT != ' ' ? <hr /> : null}
//                       <p
//                         style={{
//                           fontSize: 14,
//                           marginTop: 0,
//                           marginBottom: 0,
//                           lineHeight: '14px',
//                         }}
//                       >
//                         {story.TEXT}
//                       </p>
//                     </CardContent>
//                   </Card>
//                 ))
//               )}
//             </ScrollView>
//           </>
//         ),
//     }
//
//     return {
//       renderTabs: () => {
//         return Object.keys(tabs).map((key) => <TabPanel value={key}>{tabs[key]()}</TabPanel>)
//       },
//     }
//   }
//
//   render() {
//     const { COMPANYS, ID_OBJECT, PROJECTS, user } = this.props
//     const { Self } = this.state
//     const canChange = Self.ID == null || user.ROLE == 'ADMN' || Self?.AUTHOR?.ID_USER == user.ID
//
//     let PROJECTS_sorted =
//       Self.COMPANY == null
//         ? []
//         : PROJECTS.filter((p) => p.ID_COMPANY == Self.COMPANY.ID && (user.ROLE == 'ADMN' || p.ID_STATUS != 9))
//
//     if (ID_OBJECT != null && Self.ID == null) return <div />
//
//     return (
//       <POP ID_OBJECT={ID_OBJECT}>
//         <div className="row">
//           <div className={ID_OBJECT != null ? 'col-md-5' : 'col-md-12'}>
//             <h4>Задача {ID_OBJECT == null ? null : '#' + ID_OBJECT}</h4>
//             {Self.DATE_CREATE == null ? null : (
//               <p style={{ marginTop: -10, marginBottom: 0 }}>
//                 от {GF.makeNormalDate(Self.DATE_CREATE, 'dd.mm.yyyy H:M')}
//               </p>
//             )}
//             {Self.COMPANY != null || Self.PROJECT != null ? (
//               <Breadcrumbs separator="›" aria-label="breadcrumb">
//                 <small>{Self.COMPANY == null ? null : Self.COMPANY.TITLE}</small>
//                 <small onClick={() => this.props.openPopUp(<POP_Project ID_OBJECT={Self.PROJECT.ID} />)}>
//                   {Self.PROJECT == null ? null : Self.PROJECT.TITLE}
//                 </small>
//               </Breadcrumbs>
//             ) : null}
//
//             {user.ROLE == 'ADMN' && (
//               <FormControl className="mt-3 w-50" size="small">
//                 <InputLabel>Видимость для заказчика</InputLabel>
//                 <Select
//                   size="small"
//                   value={Self.CLEN_VISIBLE}
//                   label="Видимость для заказчика"
//                   onChange={(e) => {
//                     this.setState({ Self: { ...Self, CLEN_VISIBLE: e.target.value } })
//                   }}
//                 >
//                   <MenuItem value={1}>Показывать</MenuItem>
//                   <MenuItem value={0}>Скрывать</MenuItem>
//                 </Select>
//               </FormControl>
//             )}
//
//             {!canChange
//               ? null
//               : [
//                   <div className="mt-3" />,
//                   <Autocomplete
//                     options={COMPANYS}
//                     disabled={Self.ID_STATUS == 8}
//                     value={Self.COMPANY == null ? null : Self.COMPANY}
//                     defaultValue={Self.COMPANY}
//                     onChange={(event, newValue) => {
//                       if (newValue == null) newValue = COMPANYS[0]
//                       this.setState({ Self: { ...Self, COMPANY: newValue, PROJECT: null } })
//                     }}
//                     getOptionLabel={(option) => option.TITLE}
//                     size="small"
//                     renderInput={(params) => <TextField {...params} variant="outlined" label="Компания" size="small" />}
//                   />,
//                   <div className="mt-3" />,
//                   <Autocomplete
//                     options={PROJECTS_sorted}
//                     defaultValue={Self.PROJECT}
//                     disabled={Self.ID_STATUS == 8}
//                     value={Self.PROJECT == null ? null : Self.PROJECT}
//                     onChange={(event, newValue) => {
//                       this.setState({ Self: { ...Self, PROJECT: newValue } })
//                     }}
//                     getOptionLabel={(option) => option.TITLE}
//                     size="small"
//                     renderInput={(params) => <TextField {...params} variant="outlined" label="Проект" size="small" />}
//                   />,
//
//                   <hr />,
//                 ]}
//
//             {form_fields.map((input) =>
//               canChange ? (
//                 <TextField
//                   className="w-100 mt-2"
//                   margin="dense"
//                   size="small"
//                   disabled={Self.ID_STATUS == 8}
//                   {...input}
//                   value={Self[input.name]}
//                   inputRef={(ref) => (this.input[input.name] = ref)}
//                   onChange={(e) => {
//                     let Self_tmp = {}
//                     Object.assign(Self_tmp, Self)
//                     Self_tmp[input.name] = e.target.value
//                     this.setState({ Self: Self_tmp })
//                   }}
//                 />
//               ) : (
//                 [
//                   <hr />,
//                   <b className="mt-4 d-block">{input.label}</b>,
//                   <p className="mb-4 d-block">{Self[input.name]}</p>,
//                 ]
//               ),
//             )}
//
//             <div className="mt-3" />
//             {canChange ? (
//               <FormControl fullWidth>
//                 <DateTimePicker
//                   renderInput={(props) => <TextField {...props} />}
//                   label="Дата и время DEADLINE"
//                   value={Self.DATE_DEADLINE}
//                   onChange={(e) => this.setState({ Self: { ...Self, DATE_DEADLINE: e } })}
//                 />
//               </FormControl>
//             ) : Self.DATE_DEADLINE == null ? null : (
//               [
//                 <Alert severity="error">
//                   <AlertTitle>DEADLINE</AlertTitle>
//                   {GF.makeNormalDate(Self.DATE_DEADLINE, 'dd.mm.yyyy H:M')}
//                 </Alert>,
//               ]
//             )}
//
//             {canChange ? (
//               <div className="mb-2" style={{ height: 140, marginTop: 15 }}>
//                 <Input_image
//                   value={Self.IMAGES_URL}
//                   multiple={true}
//                   onChange={(filesToUpload, filesToDelete) => {
//                     this.filesToUpload = [...this.filesToUpload, ...filesToUpload]
//                     this.filesToDelete = [...this.filesToDelete, ...filesToDelete]
//                   }}
//                 />
//               </div>
//             ) : Self.IMAGES_URL == null || Self.IMAGES_URL.length == 0 ? null : (
//               [
//                 <hr />,
//                 <p>Приложенные файлы</p>,
//
//                 <ScrollViewSmall className="hiddenScroll">
//                   {Self.IMAGES_URL == null
//                     ? null
//                     : Self.IMAGES_URL.map((img) => (
//                         <Img
//                           src={`${global.URL_host}img/${img}`}
//                           onClick={(e) =>
//                             this.props.openPopImgs({
//                               selectedImg: 0,
//                               aImgs: [e.target.src],
//                             })
//                           }
//                         />
//                       ))}
//                 </ScrollViewSmall>,
//               ]
//             )}
//           </div>
//           {ID_OBJECT == null ? null : (
//             <div className="col-md-7">
//               <TabContext value={this.state.tab}>
//                 <TabList
//                   onChange={(e, value) => {
//                     this.setState({ tab: value })
//                   }}
//                 >
//                   {['История', 'Трудозатраты'].map((p) => (
//                     <Tab value={p} label={p} />
//                   ))}
//                 </TabList>
//                 {this.renderView().renderTabs()}
//               </TabContext>
//             </div>
//           )}
//         </div>
//
//         <div className="mt-3" />
//         {Self.ID_STATUS == 8 ||
//         (user.ROLE == 'CLEN' && Self.ID != null && Self?.AUTHOR?.ID_USER != user.ID) ||
//         (user.ROLE == 'EMPL' && Self?.LAST_HUMAN?.ID_USER != user.ID) ? null : (
//           <hr />
//         )}
//
//         {(user.ROLE != 'ADMN' && Self.ID != null && user.ID != Self?.AUTHOR?.ID_USER) ||
//         Self.ID_STATUS == 8 ||
//         Self.ID_STATUS == 7 ||
//         ID_OBJECT == null ||
//         user.ROLE == 'CLEN' ? null : (
//           <Button className="float-end ms-1 me-1" onClick={() => this.Redirect()}>
//             Назначить
//           </Button>
//         )}
//         {user.ROLE != 'ADMN' && Self?.AUTHOR?.ID_USER != user.ID ? null : (
//           <Button className="float-end ms-1 me-1" color="success" onClick={() => this.CloseTask()}>
//             Завершить
//           </Button>
//         )}
//
//         {user.ROLE == 'EMPL' && Self?.AUTHOR?.ID_USER != user.ID && (
//           <Button className="float-end ms-1 me-1" color="success" onClick={() => this.CloseTask()}>
//             Выполнено
//           </Button>
//         )}
//
//         {ID_OBJECT == null || (user.ROLE != 'ADMN' && user.ROLE != 'CLEN') || Self.ID_STATUS != 7 ? null : (
//           <Button color="error" className="float-end ms-1 me-1" onClick={() => this.uncloceTask()}>
//             ЗАДАча не решена
//           </Button>
//         )}
//
//         {!canChange ? null : (
//           <Button className="float-left" onClick={() => this.Save()}>
//             Сохранить изменения
//           </Button>
//         )}
//
//         {Self.ID_STATUS != 10 ||
//         user.ROLE != 'EMPL' ||
//         (user.ROLE == 'EMPL' && Self?.LAST_HUMAN?.ID_USER != user.ID) ? null : (
//           <Button className="float-end" color="success" onClick={() => this.getToWork()}>
//             Взять в работу
//           </Button>
//         )}
//
//         {Self.ID_STATUS == 10 ||
//         user.ROLE != 'EMPL' ||
//         Self.ID_STATUS == 8 ||
//         (user.ROLE == 'EMPL' && Self?.LAST_HUMAN?.ID_USER != user.ID) ||
//         Self.ID_STATUS == 7 ? null : (
//           <Button className="float-end me-1" onClick={() => this.Redirect()}>
//             передать задачу
//           </Button>
//         )}
//
//         {(Self.ID_STATUS == 10 || Self.ID_STATUS == 6) && (Self.AUTHOR?.ID_USER == user.ID || user.ROLE == 'ADMN') && (
//           <Button className="float-end me-1" onClick={() => this.backFromRedirect()}>
//             Снять с назначения
//           </Button>
//         )}
//       </POP>
//     )
//   }
// }

class POP_Task extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      Self: {
        AUTHOR: null,
        COMPANY: null,
        PROJECT: null,
        STORY: null,
        DATE_DEADLINE: null,
        ...props.SelfDefault,
      },
      BACKLOG_WORK_COAST: null,
      errorTEXT: null,
      tab: 'История',
    }
    this.input = []
    this.filesToUpload = []
    this.filesToDelete = []
  }

  componentDidMount() {
    const {} = this.state
    const { COMPANYS, PROJECTS, ID_OBJECT, BACKLOG, HUMANS, USERS, user } = this.props

    if (ID_OBJECT != null) {
      var Self_tmp = BACKLOG.find((p) => p.ID == ID_OBJECT)
      if (Self_tmp == null) return
      var Self = {}
      Object.assign(Self, Self_tmp)

      var keys = ['DATE_CREATE']
      keys.forEach((key) => {
        Self[key] = GF.SQLdateToJsDate(Self[key])
      })
      Object.keys(this.input).forEach((key) => {
        this.input[key].value = Self[key]
      })

      var keys = ['DATE_DEADLINE']
      keys.forEach((key) => {
        Self[key] = GF.SQLdateToJsDate(Self[key])
      })

      Self.STORY = null

      this.setState({ Self })
      setTimeout(() => {
        this.getStory()
      }, 1)

      GF.API_task({
        operation: 'getBACKLOG_WORK_COAST',
        ID_TASK: ID_OBJECT,
      }).then((data) => {
        this.setState({ BACKLOG_WORK_COAST: data })
      })
    } else {
      this.setState({
        Self: {
          COMPANY: this.props.COMPANYS[1],
          PROJECT: null,
          STORY: null,
          DATE_DEADLINE: null,
        },
      })
    }
  }

  getStory() {
    const { Self } = this.state
    const { ID_OBJECT, HUMANS, user } = this.props
    var Self_tmp = {}
    Object.assign(Self_tmp, Self)

    GF.API_task({
      operation: 'getBACKLOG_STORY',
      ID_TASK: ID_OBJECT,
    }).then((data) => {
      Self_tmp.STORY = data

      Self_tmp.STORY.forEach((s, i) => {
        Self_tmp.STORY[i].HUMAN = HUMANS.find((h) => h.ID_USER == s.ID_USER)
        delete Self_tmp.STORY[i].ID_HUMAN
      })

      this.setState({ Self: Self_tmp })
    })
  }

  CloseTask() {
    new Promise((resolve, reject) => {
      this.props.openPopUp(
        <Question
          text="Вы уверены что хотите завершить выбранную задачу?"
          buttons={[
            <Button className="ms-1 me-1" onClick={resolve}>
              Да
            </Button>,
            <Button className="ms-1 me-1" onClick={reject}>
              Нет
            </Button>,
          ]}
        />,
      )
    })
      .then(() => {
        this.props.closePopUp()
        if (this.props.user.ROLE != 'EMPL') {
          GF.API_task({
            operation: 'closeTask',
            ID_TASK: this.props.ID_OBJECT,
          }).then((res) => {
            this.props.getBACKLOG()
            this.props.closePopUp()
          })
        } else {
          new Promise((resolve, reject) => {
            this.props.openPopUp(
              <Question
                text="Комментарий к проделаной работе"
                contentObject={
                  <TextField
                    className="w-100"
                    margin="dense"
                    size="small"
                    multiline
                    rows={5}
                    label="Описание работы"
                    value={this.state.errorTEXT}
                    onChange={(e) => {
                      this.setState({ errorTEXT: e.target.value })
                    }}
                  />
                }
                buttons={[
                  <Button className="ms-1 me-1" onClick={resolve}>
                    Продолжить
                  </Button>,
                  <Button className="ms-1 me-1" onClick={reject}>
                    Отмена
                  </Button>,
                ]}
              />,
            )
          })
            .then(() => {
              this.props.closePopUp()
              this.props.closePopUp()
              GF.API_task({
                operation: 'closeTask',
                ID_TASK: this.props.ID_OBJECT,
                TEXT: this.state.errorTEXT,
              }).then((res) => {
                this.props.getBACKLOG()
              })
            })
            .catch(() => {
              this.props.closePopUp()
            })
        }
      })
      .catch(() => {
        this.props.closePopUp()
      })
  }

  uncloceTask() {
    const { Self } = this.state
    new Promise((resolve, reject) => {
      this.props.openPopUp(
        <Question
          text="Вы хотите вернуть задачу обратно в работу?"
          buttons={[
            <Button className="ms-1 me-1" onClick={resolve}>
              Да
            </Button>,
            <Button className="ms-1 me-1" onClick={reject}>
              Нет
            </Button>,
          ]}
        />,
      )
    })
      .then(() => {
        this.props.closePopUp()
        new Promise((resolve, reject) => {
          this.props.openPopUp(
            <Question
              text="В чем ошибка исполнителя? Опишите наиболее подробно причину отклонения"
              contentObject={
                <TextField
                  className="w-100"
                  margin="dense"
                  size="small"
                  multiline
                  rows={5}
                  label="Наставление к задаче"
                  value={this.state.errorTEXT}
                  onChange={(e) => {
                    this.setState({ errorTEXT: e.target.value })
                  }}
                />
              }
              buttons={[
                <Button className="ms-1 me-1" onClick={resolve}>
                  Продолжить
                </Button>,
                <Button className="ms-1 me-1" onClick={reject}>
                  Отмена
                </Button>,
              ]}
            />,
          )
        })
          .then(() => {
            GF.API_task({
              operation: 'uncloceTask',
              ID_TASK: this.props.ID_OBJECT,
              TEXT: this.state.errorTEXT,
            }).then((res) => {
              this.props.getBACKLOG()
              this.props.closePopUp()
            })
          })
          .finally(() => {
            this.props.closePopUp()
          })
      })
      .catch(() => {
        this.props.closePopUp()
      })
  }

  backFromRedirect() {
    const { ID_OBJECT, user } = this.props
    GF.API_task({
      operation: 'RedirectTask',
      ID_TASK: ID_OBJECT,
      ID_EMPLOY: null,
      TEXT: 'Возврат задачи с назначения',
    }).then((data) => {
      this.getStory()
      this.props.getBACKLOG()
    })
  }

  Redirect() {
    const { ID_OBJECT, user } = this.props
    new Promise((resolve, reject) => {
      this.props.openPopUp(<POP_SelectEmploy callBack={resolve} />)
    }).then(({ USER, TEXT }) => {
      GF.API_task({
        operation: 'RedirectTask',
        ID_TASK: ID_OBJECT,
        ID_EMPLOY: USER.ID_EMPLOY,
        TEXT,
      }).then((data) => {
        this.getStory()
        this.props.getBACKLOG()
        if (user.ROLE == 'EMPL') this.props.closePopUp()
      })
    })
  }

  getToWork() {
    const { ID_OBJECT } = this.props
    GF.API_task({
      operation: 'getToWork',
      ID_TASK: ID_OBJECT,
    }).then((data) => {
      this.getStory()
      this.props.getBACKLOG()
      setTimeout(() => this.componentDidMount(), 300)

      setTimeout(() => this.componentDidMount(), 800)
    })
  }

  Save() {
    const { Self } = this.state
    const { ID_OBJECT, user } = this.props

    const canChange = Self.ID == null || user.ROLE == 'ADMN' || Self?.AUTHOR?.ID_USER == user.ID

    if (!GF.checkReqvireds([this.input.TITLE])) return
    let data = {
      ID_PROJECT: Self.PROJECT.ID,
      TEXT: Self.TEXT,
    }

    if (user.ROLE == 'ADMN') data.CLEN_VISIBLE = Self.CLEN_VISIBLE

    let reqv = {
      operation: 'createUpdateSome',
      Table: 'BACKLOG_TASKS',
      data,
    }
    if (canChange) {
      reqv.data.ID_AUTHOR = user.ID
      reqv.data.DATE_DEADLINE = GF.makeNormalDate(Self.DATE_DEADLINE, 'yyyy-mm-dd H:M')
    }

    if (canChange) {
      reqv.data.PREM_HOURS = this.state.Self.PREM_HOURS
      reqv.data.PREM_COAST = this.state.Self.PREM_COAST
    }

    if (ID_OBJECT != null) reqv.ID = ID_OBJECT

    if (this.props.ID_OBJECT != null) reqv.ID = this.props.ID_OBJECT

    GF.API_task(reqv).then((res) => {
      var promises = []
      if (this.filesToUpload.length > 0)
        promises.push(
          GF.API_files(
            {
              operation: 'setIMAGE',
              Table: 'BACKLOG_TASKS',
              ID: res.ID,
            },
            this.filesToUpload.splice(0),
          ),
        )

      if (this.filesToDelete.length > 0)
        promises.push(
          GF.API_task({
            operation: 'deleteIMAGE',
            ID: this.props.ID_OBJECT,
            Table: 'BACKLOG_TASKS',
            filesToDelete: JSON.stringify(this.filesToDelete.splice(0)),
          }),
        )
      Promise.all(promises).then(() => {
        this.props.getBACKLOG()
      })

      this.props.getDB_Data('getPROJECTS', 'PROJECTS')
      this.props.getBACKLOG()
      setTimeout(() => {
        if (this.props.callBack != null) {
          this.props.callBack()
        }
      }, 500)
      this.props.closePopUp()
    })
  }

  renderView() {
    const { state, props } = this
    const { Self } = state
    const { user } = props

    const Calc = {
      WORK_COAST: {
        HOURS: 0,
        COAST: 0,
        REAL_COAST: 0,
      },
    }

    if (state.BACKLOG_WORK_COAST != null) {
      state.BACKLOG_WORK_COAST.forEach((wc) => {
        Calc.WORK_COAST.HOURS += wc.HOURS

        Calc.WORK_COAST.REAL_COAST += wc.COAST * wc.HOURS
      })
    }

    const tabs = {
      История: () => (
        <>
          <ScrollView className="hiddenScroll" length={Self.STORY == null ? 1 : Self.STORY.length}>
            {Self.STORY == null ? (
              <LinearProgress />
            ) : Self.STORY.length == 0 ? (
              <small>Действий не обнаружено</small>
            ) : (
              Self.STORY.map((story) => {
                return (
                  <Card sx={{ mb: 2 }}>
                    {story.IS_AUTOCREATE == 1 ? (
                      <small className="mt-2 ps-3 pe-3">{GF.makeNormalDate(story.DATE_CREATE, 'dd.mm.yyyy H:M')}</small>
                    ) : (
                      <CardHeader
                        avatar={
                          story.HUMAN?.IMAGES_URL == null ? (
                            <Avatar sx={{ bgcolor: 'red' }}>{story.HUMAN?.FIO?.[0]}</Avatar>
                          ) : (
                            <Avatar src={story.HUMAN?.IMAGES_URL?.[0]} alt={story.HUMAN.FIO[0]} />
                          )
                        }
                        title={GF.FIO(story.HUMAN?.FIO, 2)}
                        subheader={GF.makeNormalDate(story.DATE_CREATE, 'dd.mm.yyyy H:M')}
                      />
                    )}
                    <p className="ps-3 pe-3">{story.TEXT}</p>
                  </Card>
                )
              })
            )}
          </ScrollView>
        </>
      ),
      Трудозатраты: () =>
        user.ROLE == 'CLEN' ? (
          <p>Раздел для администрации</p>
        ) : (
          <>
            <small style={{ color: 'black' }}>Предварительная оценка</small>
            <TextField
              className="w-100 mb-2"
              margin="dense"
              size="small"
              variant="filled"
              label="Стоимость часа (руб)"
              disabled={(user.ROLE != 'ADMN' && user.ROLE != 'CLEN') || Self.ID_STATUS == 8}
              value={Self.PREM_COAST}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9.,]/gm, '').replace(',', '.') * 1
                if (value <= 0) value = null
                e.target.value = value
                this.setState({
                  Self: {
                    ...Self,
                    PREM_COAST: value,
                  },
                })
              }}
            />

            <TextField
              className="w-100 mb-2"
              margin="dense"
              size="small"
              variant="filled"
              label="Кол-во трудозатрат (часов)"
              disabled={(user.ROLE != 'ADMN' && user.ROLE != 'CLEN') || Self.ID_STATUS == 8}
              value={Self.PREM_HOURS}
              onChange={(e) => {
                let value = e.target.value.replace(/[^0-9.,]/gm, '').replace(',', '.') * 1
                if (value <= 0) value = null
                e.target.value = value
                this.setState({
                  Self: {
                    ...Self,
                    PREM_HOURS: value,
                  },
                })
              }}
            />

            <small>Итого {GF.makeCoast((Self.PREM_COAST || 0) * (Self.PREM_HOURS || 1))} руб</small>
            <hr />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <small style={{ color: 'black' }}>
                Фактические трудозатраты
                {Calc.WORK_COAST.COAST == 0 && Calc.WORK_COAST.HOURS == 0 ? null : (
                  <div
                    style={{
                      display: 'flex',
                      gap: 20,
                      alignItems: 'center',
                    }}
                  >
                    <small
                      style={{
                        color: Calc.WORK_COAST.REAL_COAST / Calc.WORK_COAST.HOURS > Self.PREM_COAST ? 'red' : '',
                      }}
                    >
                      ~{GF.makeCoast(Calc.WORK_COAST.REAL_COAST / Calc.WORK_COAST.HOURS)}руб/час
                    </small>
                    <small
                      style={{
                        color: Calc.WORK_COAST.HOURS > Self.PREM_HOURS ? 'red' : '',
                      }}
                    >
                      {GF.makeCoast(Calc.WORK_COAST.HOURS)}час
                    </small>

                    <small
                      style={{
                        color: Calc.WORK_COAST.REAL_COAST > Self.PREM_COAST * Self.PREM_HOURS ? 'red' : '',
                      }}
                    >
                      Итого {GF.makeCoast(Calc.WORK_COAST.REAL_COAST)} руб
                    </small>
                  </div>
                )}
              </small>
              <Button
                color="primary"
                size="small"
                onClick={() => {
                  this.props.openPopUp(
                    <POP_WorkCoast
                      ID_TASK={Self.ID}
                      callBack={() => {
                        this.componentDidMount()
                        setTimeout(() => {
                          if (this.props.callBack != null) {
                            this.props.callBack()
                          }
                        }, 500)
                      }}
                    />,
                  )
                }}
              >
                Записать время
              </Button>
            </div>

            <ScrollView
              style={{
                maxHeight: '30vh',
              }}
            >
              {state.BACKLOG_WORK_COAST == null ? (
                <LinearProgress />
              ) : state.BACKLOG_WORK_COAST.length == 0 ? (
                <small>Нет списанных трудозатрат</small>
              ) : (
                state.BACKLOG_WORK_COAST.map((story) => (
                  <Card
                    sx={{ mb: 2 }}
                    onClick={(e) => {
                      this.props.openPopUp(
                        <POP_WorkCoast
                          ID_OBJECT={story.ID}
                          ID_TASK={Self.ID}
                          callBack={() => this.componentDidMount()}
                        />,
                      )
                    }}
                  >
                    <CardHeader
                      title={GF.FIO(story.FIO, 2)}
                      avatar={
                        story.IMAGES_URL == null ? (
                          <Avatar sx={{ bgcolor: 'red' }}>{story.FIO[0]}</Avatar>
                        ) : (
                          <Avatar src={story.IMAGES_URL[0]} alt={story.HUMAN.FIO[0]} />
                        )
                      }
                      subheader={GF.makeNormalDate(story.DATE_CREATE, 'dd.mm.yyyy H:M')}
                    />
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <b
                          style={{
                            fontSize: 14,
                            lineHeight: '14px',
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                        >
                          {story.HOURS == null ? '' : GF.makeCoast(story.HOURS) + 'час по '}
                          {story.COAST == null ? '' : GF.makeCoast(story.COAST) + 'руб '}
                        </b>
                        <small className="d-block">
                          Итого {GF.makeCoast((story.COAST || 0) * (story.HOURS || 1))} руб
                        </small>
                      </div>
                      {story.TEXT != null && story.TEXT != '' && story.TEXT != ' ' ? <hr /> : null}
                      <p
                        style={{
                          fontSize: 14,
                          marginTop: 0,
                          marginBottom: 0,
                          lineHeight: '14px',
                        }}
                      >
                        {story.TEXT}
                      </p>
                    </CardContent>
                  </Card>
                ))
              )}
            </ScrollView>
          </>
        ),
    }

    return {
      renderTabs: () => {
        return Object.keys(tabs).map((key) => <TabPanel value={key}>{tabs[key]()}</TabPanel>)
      },
    }
  }

  render() {
    const { COMPANYS, ID_OBJECT, PROJECTS, user } = this.props
    const { Self } = this.state
    const canChange = Self.ID == null || user.ROLE == 'ADMN' || Self?.AUTHOR?.ID_USER == user.ID

    let PROJECTS_sorted =
      Self.COMPANY == null
        ? []
        : PROJECTS.filter((p) => p.ID_COMPANY == Self.COMPANY.ID && (user.ROLE == 'ADMN' || p.ID_STATUS != 9))

    if (ID_OBJECT != null && Self.ID == null) return <div />

    return (
      <POP ID_OBJECT={ID_OBJECT}>
        <div className="row">
          <div className={ID_OBJECT != null ? 'col-md-5' : 'col-md-12'}>
            <h4>Задача {ID_OBJECT == null ? null : '#' + ID_OBJECT}</h4>
            {Self.DATE_CREATE == null ? null : (
              <p style={{ marginTop: -10, marginBottom: 0 }}>
                от {GF.makeNormalDate(Self.DATE_CREATE, 'dd.mm.yyyy H:M')}
              </p>
            )}
            {Self.COMPANY != null || Self.PROJECT != null ? (
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <small>{Self.COMPANY == null ? null : Self.COMPANY.TITLE}</small>
                <small onClick={() => this.props.openPopUp(<POP_Project ID_OBJECT={Self.PROJECT.ID} />)}>
                  {Self.PROJECT == null ? null : Self.PROJECT.TITLE}
                </small>
              </Breadcrumbs>
            ) : null}

            {user.ROLE == 'ADMN' && (
              <FormControl className="mt-3 w-50" size="small">
                <InputLabel>Видимость для заказчика</InputLabel>
                <Select
                  size="small"
                  value={Self.CLEN_VISIBLE}
                  label="Видимость для заказчика"
                  onChange={(e) => {
                    this.setState({ Self: { ...Self, CLEN_VISIBLE: e.target.value } })
                  }}
                >
                  <MenuItem value={1}>Показывать</MenuItem>
                  <MenuItem value={0}>Скрывать</MenuItem>
                </Select>
              </FormControl>
            )}

            {!canChange
              ? null
              : [
                  <div className="mt-3" />,
                  <Autocomplete
                    options={COMPANYS}
                    disabled={Self.ID_STATUS == 8}
                    value={Self.COMPANY == null ? null : Self.COMPANY}
                    defaultValue={Self.COMPANY}
                    onChange={(event, newValue) => {
                      if (newValue == null) newValue = COMPANYS[0]
                      this.setState({ Self: { ...Self, COMPANY: newValue, PROJECT: null } })
                    }}
                    getOptionLabel={(option) => option.TITLE}
                    size="small"
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Компания" size="small" />}
                  />,
                  <div className="mt-3" />,
                  <Autocomplete
                    options={PROJECTS_sorted}
                    defaultValue={Self.PROJECT}
                    disabled={Self.ID_STATUS == 8}
                    value={Self.PROJECT == null ? null : Self.PROJECT}
                    onChange={(event, newValue) => {
                      this.setState({ Self: { ...Self, PROJECT: newValue } })
                    }}
                    getOptionLabel={(option) => option.TITLE}
                    size="small"
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Проект" size="small" />}
                  />,

                  <hr />,
                ]}

            {form_fields.map((input) =>
              canChange ? (
                <TextField
                  className="w-100 mt-2"
                  margin="dense"
                  size="small"
                  disabled={Self.ID_STATUS == 8}
                  {...input}
                  value={Self[input.name]}
                  inputRef={(ref) => (this.input[input.name] = ref)}
                  onChange={(e) => {
                    let Self_tmp = {}
                    Object.assign(Self_tmp, Self)
                    Self_tmp[input.name] = e.target.value
                    this.setState({ Self: Self_tmp })
                  }}
                />
              ) : (
                [
                  <hr />,
                  <b className="mt-4 d-block">{input.label}</b>,
                  <p className="mb-4 d-block">{Self[input.name]}</p>,
                ]
              ),
            )}

            <div className="mt-3" />
            {canChange ? (
              <FormControl fullWidth>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Дата и время DEADLINE"
                  value={Self.DATE_DEADLINE}
                  onChange={(e) => this.setState({ Self: { ...Self, DATE_DEADLINE: e } })}
                />
              </FormControl>
            ) : Self.DATE_DEADLINE == null ? null : (
              [
                <Alert severity="error">
                  <AlertTitle>DEADLINE</AlertTitle>
                  {GF.makeNormalDate(Self.DATE_DEADLINE, 'dd.mm.yyyy H:M')}
                </Alert>,
              ]
            )}

            {canChange ? (
              <div className="mb-2" style={{ height: 140, marginTop: 15 }}>
                <Input_image
                  value={Self.IMAGES_URL}
                  multiple={true}
                  onChange={(filesToUpload, filesToDelete) => {
                    this.filesToUpload = [...this.filesToUpload, ...filesToUpload]
                    this.filesToDelete = [...this.filesToDelete, ...filesToDelete]
                  }}
                />
              </div>
            ) : Self.IMAGES_URL == null || Self.IMAGES_URL.length == 0 ? null : (
              [
                <hr />,
                <p>Приложенные файлы</p>,

                <ScrollViewSmall className="hiddenScroll">
                  {Self.IMAGES_URL == null
                    ? null
                    : Self.IMAGES_URL.map((img) => (
                        <Img
                          src={`${global.URL_host}img/${img}`}
                          onClick={(e) =>
                            this.props.openPopImgs({
                              selectedImg: 0,
                              aImgs: [e.target.src],
                            })
                          }
                        />
                      ))}
                </ScrollViewSmall>,
              ]
            )}
          </div>
          {ID_OBJECT == null ? null : (
            <div className="col-md-7">
              <TabContext value={this.state.tab}>
                <TabList
                  onChange={(e, value) => {
                    this.setState({ tab: value })
                  }}
                >
                  {['История', 'Трудозатраты'].map((p) => (
                    <Tab value={p} label={p} />
                  ))}
                </TabList>
                {this.renderView().renderTabs()}
              </TabContext>
            </div>
          )}
        </div>

        <div className="mt-3" />
        {Self.ID_STATUS == 8 ||
        (user.ROLE == 'CLEN' && Self.ID != null && Self?.AUTHOR?.ID_USER != user.ID) ||
        (user.ROLE == 'EMPL' && Self?.LAST_HUMAN?.ID_USER != user.ID) ? null : (
          <hr />
        )}

        {(user.ROLE != 'ADMN' && Self.ID != null && user.ID != Self?.AUTHOR?.ID_USER) ||
        Self.ID_STATUS == 8 ||
        Self.ID_STATUS == 7 ||
        ID_OBJECT == null ||
        user.ROLE == 'CLEN' ? null : (
          <Button className="float-end ms-1 me-1" onClick={() => this.Redirect()}>
            Назначить
          </Button>
        )}
        {user.ROLE != 'ADMN' && Self?.AUTHOR?.ID_USER != user.ID ? null : (
          <Button className="float-end ms-1 me-1" color="success" onClick={() => this.CloseTask()}>
            Завершить
          </Button>
        )}

        {user.ROLE == 'EMPL' && Self?.AUTHOR?.ID_USER != user.ID && (
          <Button className="float-end ms-1 me-1" color="success" onClick={() => this.CloseTask()}>
            Выполнено
          </Button>
        )}

        {ID_OBJECT == null || (user.ROLE != 'ADMN' && user.ROLE != 'CLEN') || Self.ID_STATUS != 7 ? null : (
          <Button color="error" className="float-end ms-1 me-1" onClick={() => this.uncloceTask()}>
            ЗАДАча не решена
          </Button>
        )}

        {!canChange ? null : (
          <Button className="float-left" onClick={() => this.Save()}>
            Сохранить изменения
          </Button>
        )}

        {Self.ID_STATUS != 10 ||
        user.ROLE != 'EMPL' ||
        (user.ROLE == 'EMPL' && Self?.LAST_HUMAN?.ID_USER != user.ID) ? null : (
          <Button className="float-end" color="success" onClick={() => this.getToWork()}>
            Взять в работу
          </Button>
        )}

        {Self.ID_STATUS == 10 ||
        user.ROLE != 'EMPL' ||
        Self.ID_STATUS == 8 ||
        (user.ROLE == 'EMPL' && Self?.LAST_HUMAN?.ID_USER != user.ID) ||
        Self.ID_STATUS == 7 ? null : (
          <Button className="float-end me-1" onClick={() => this.Redirect()}>
            передать задачу
          </Button>
        )}

        {(Self.ID_STATUS == 10 || Self.ID_STATUS == 6) && (Self.AUTHOR?.ID_USER == user.ID || user.ROLE == 'ADMN') && (
          <Button className="float-end me-1" onClick={() => this.backFromRedirect()}>
            Снять с назначения
          </Button>
        )}
      </POP>
    )
  }
}

const POP = styled.div`
  width: ${(props) => (props.ID_OBJECT != null ? '900px' : '400px')};
`

const ScrollView = styled.div`
  max-height: 60vh;
  height: ${(props) => props.length * 150}px;
  transition: 1s;
  overflow: auto;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
`
const ScrollViewSmall = styled.div`
  max-height: 30vh;
  overflow: auto;
  margin-right: -20px;
  margin-bottom: -17px;
`

const Img = styled.img`
  width: calc(50% - 20px);
  margin-right: 20px;
  height: 100px;
  border-radius: 10px;
  transition: 0.3s;
  margin-bottom: 20px;
  object-fit: cover;

  &:nth-child(2n + 1) {
    margin-right: 20px;
  }
`

const mapDispatchToProps = (dispatch) => {
  return {
    closePopUp: (arg) => dispatch(closePopUp(arg)),
    openPopUp: (arg) => dispatch(openPopUp(arg)),

    getBACKLOG: (arg) => dispatch(getBACKLOG(arg)),
    getDB_Data: (operation, tableName, JSONparseKey) => dispatch(getDB_Data(operation, tableName, JSONparseKey)),
    openPopImgs: (arg) => dispatch(openPopImgs(arg)),
  }
}

const mapStateToProps = (store) => {
  return {
    PROJECTS: store.page.PROJECTS,
    COMPANYS: store.page.COMPANYS,
    BACKLOG: store.page.BACKLOG,
    HUMANS: store.page.HUMANS,
    user: store.user.data,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(POP_Task)
