import { IEnvIconEnum, IEnvImageEnum } from 'components/UI/images/types'

interface IEnvIconPath {
  name: IEnvIconEnum
}
interface IEnvImagePath {
  name: IEnvImageEnum
}

const envIconPath = ({ name }: IEnvIconPath) => {
  return `${process.env.PUBLIC_URL}/images/icons/${name}.svg`
}
const envImagePath = ({ name }: IEnvImagePath) => {
  return `${process.env.PUBLIC_URL}/images/${name}.jpg`
}

export { envIconPath, envImagePath }
