import React, { FC } from 'react'
import { connect } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'

import { IRootStoreState } from 'features/types'

import { AccountContainer, PageTitle, TopMenuView } from './styles'

import { ROUTES_ENUM } from 'routes/routes'
import { openPopUp } from 'features/actions/PopUpActions'
import { loadUserData_FromStorage, logOut } from 'features/actions/UserAct'
import Account from 'components/Account/Account'

interface ITopMenu {
  isLogined?: boolean
}

const TopMenu: FC<ITopMenu> = (props: ITopMenu) => {
  const { isLogined } = props

  const { pathname } = useLocation()
  const currentRoute = ROUTES_ENUM[pathname]

  return (
    <TopMenuView>
      {!isLogined ? null : (
        <>
          <PageTitle>
            <h1>{currentRoute}</h1>
          </PageTitle>
          <AccountContainer>
            <Account {...props} />
          </AccountContainer>
        </>
      )}
    </TopMenuView>
  )
}

const mapDispatchToProps = (dispatch: React.Dispatch<any>) => {
  return {
    openPopUp: (arg: any) => dispatch(openPopUp(arg)),
    logOut: (arg: any) => dispatch(logOut(arg)),
    loadUserData_FromStorage: (arg: any) => dispatch(loadUserData_FromStorage(arg)),
  }
}
const mapStateToProps = (store: IRootStoreState) => {
  return {
    page: store?.page,
    user: store?.user?.data,
    isLogined: store?.user?.isLogined,
  }
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopMenu))
