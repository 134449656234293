import styled from 'styled-components'
import { Chip } from '@mui/material'

const StyledAccount = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .MuiTouchRipple-root {
    display: none;
    border: none;
  }
  .css-15dxc3s-MuiButtonBase-root-MuiChip-root {
    &:hover,
    &:active {
      box-shadow: none !important;
    }
  }
`
const StyledChip = styled(Chip)`
  width: 100%;
  min-width: 166px;
  height: 40px !important;
  display: flex;
  justify-content: flex-end !important;
  align-items: flex-start;
  background: none !important;

  .MuiChip-avatar {
    width: 38px !important;
    height: 38px !important;
  }
`
const AvatarWrapper = styled.div`
  order: 2;

  .MuiAvatar-root {
    width: 38px;
    height: 38px;
    top: 0;
    padding: 0;
  }
`
const LabelWrapper = styled.div`
  order: 1;

  p {
    padding: 0;
    margin: 0;

    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0;
    text-align: right;

    :first-child {
      font-weight: 600;
    }
  }
`

const MenuWrapper = styled.div`
  width: 260px;
  height: 100px;
  padding: 20px;
  margin-top: 10px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
  z-index: 999;
`
const MenuOption = styled.div`
  margin-bottom: 20px;

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #2d2d2d;

  img {
    width: 15px;
    margin-right: 10px;
  }

  a {
    color: #2d2d2d;
    text-decoration: none;
    background: none;
    text-align: left;

    :hover {
      text-decoration: underline;
      color: #b4e2fb;
    }
  }
`

export { StyledAccount, StyledChip, AvatarWrapper, LabelWrapper, MenuWrapper, MenuOption }
