import React from 'react'
import { Button } from '@mui/material'

import { ScrollTo, Img, Delete, ImgView, ImgScroll, Input_imageView, ImgContent } from './styles'

import { IEnvIconEnum } from 'components/UI/images/types'

import { GlobalFunctions } from 'utils/GlobalFunctions'

import { envIconPath } from 'components/UI/images/envIconPath'

export default class Input_image extends React.Component {
  constructor(props) {
    super()
    this.state = {
      img_url: [],
    }

    this.input = []
    this.filesToDelete = []

    this.randomId = GlobalFunctions.randomString()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value == null || nextProps.value == undefined) {
      this.setState({ img_url: [] })
      return
    }
    if (nextProps.value != this.state.img_url) this.setState({ img_url: nextProps.value.map((f) => (f = { url: f })) })
  }

  onChange(target) {
    const { multiple } = this.props
    if (this.state.img_url.length > 0 && !multiple) {
      this.DeleteImg(0)
    }
    const files = target.files
    if (files.length > 0) {
      var currentFile = 0
      var fr = new FileReader()
      fr.addEventListener(
        'load',
        () => {
          let img_url = this.state.img_url
          img_url.push({
            url: fr.result,
            fileData: files[currentFile],
          })

          currentFile++
          this.setState({ img_url })
          if (currentFile < files.length) fr.readAsDataURL(files[currentFile])
          else {
          }
        },
        false,
      )

      fr.readAsDataURL(files[currentFile])
    }
    this.props.onChange(files, this.filesToDelete)
  }

  DeleteImg(index) {
    let img_url = this.state.img_url
    if (img_url[index].fileData == null) {
      //если это ранее загруженый файл
      this.filesToDelete.push(img_url[index].url)
    }
    img_url.splice(index, 1)
    this.setState({ img_url })

    let filesToUpload = img_url.filter((file) => file.fileData != null).map((file) => file.fileData)
    this.props.onChange(filesToUpload, this.filesToDelete)
  }

  ScrollTo(dir) {
    let k = 240
    var r = 0
    var step = 10
    if (dir == 'left') {
      k = -k
      step = -step
    }

    while (r != k) {
      setTimeout(() => {
        this.ImgScroll.scrollLeft += step
      }, Math.abs(r))
      r += step
    }
  }

  render() {
    const { img_url } = this.state
    const { multiple } = this.props
    return (
      <Input_imageView>
        <ImgScroll ref={(ref) => (this.ImgScroll = ref)}>
          <ImgContent multiple={multiple}>
            {img_url.map((img, i) => (
              <ImgView multiple={multiple == null ? true : multiple} key={'photo' + i}>
                <Img id="preview_title" src={img.fileData == null ? `${global.URL_host}img/${img.url}` : img.url} />
                <Delete onClick={() => this.DeleteImg(i)}>x</Delete>
              </ImgView>
            ))}
          </ImgContent>
        </ImgScroll>
        {img_url.length == 0 || multiple == null || multiple == false
          ? null
          : [
              <ScrollTo
                className="left"
                src={envIconPath({ name: IEnvIconEnum.arrow })}
                onClick={() => this.ScrollTo('left')}
              />,
              <ScrollTo
                className="right"
                src={envIconPath({ name: IEnvIconEnum.arrow })}
                onClick={() => this.ScrollTo('right')}
              />,
            ]}
        <Button
          className="w-100"
          size="small"
          onClick={() => {
            this.input.click()
          }}
        >
          Выберите {multiple == null ? 'изображения' : multiple ? 'изображения' : 'изображение'}
        </Button>

        <input
          id={this.randomId}
          ref={(ref) => (this.input = ref)}
          multiple={multiple == null ? true : multiple}
          hidden={true}
          accept="image/png,image/jpeg,image/jpg"
          type="file"
          onChange={(e) => this.onChange(e.target)}
        />
      </Input_imageView>
    )
  }
}
