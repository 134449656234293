import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withCookies } from 'react-cookie'
import { Lock, Person } from '@mui/icons-material'
import { Alert, AlertTitle, Button, InputAdornment, TextField } from '@mui/material'

import { IEnvIconEnum, IEnvImageEnum } from 'components/UI/images/types'

import styled from 'styled-components'
import { down, up } from 'styled-breakpoints'
import { envIconPath, envImagePath } from 'components/UI/images/envIconPath'

import { GlobalFunctions } from 'utils/GlobalFunctions'
import { openPopUp } from 'features/actions/PopUpActions'
import { loadUserData_FromStorage, logOut } from 'features/actions/UserAct'
import Alert_pop from 'components/PopUp/Views/Alert'

class Index extends React.Component {
  constructor() {
    super()
    this.state = {
      errorMSG: null,
      isLoaded: false,
      isLoadedALL: false,
      isError: false,
    }
    this.input = []
  }

  componentDidMount() {
    GlobalFunctions.deleteCookie('login')
    GlobalFunctions.deleteCookie('password')
    this.props.logOut()
    this.props.loadUserData_FromStorage()

    GlobalFunctions.API_task({
      operation: 'checkKey',
      KEY_TYPE: 'CUREG',
      GUID: this.props.match.params.GUID,
    })
      .then()
      .catch(() => {
        this.setState({ isError: true })
      })

    setTimeout(() => {
      this.setState({ isLoaded: true })
    }, 500)
  }

  passwordSendToMail() {
    if (!GlobalFunctions.checkReqvireds(this.input, ['PASSWORD'])) return

    GlobalFunctions.API_task({
      operation: 'passwordSendToMail',
      LOGIN: encodeURIComponent(this.input.LOGIN.value),
    }).then((res) => {
      if (res == 'ok') {
        this.props.openPopUp(<Alert_pop text="Восстановленный пароль выслан на почту пользователя" />)
      }
    })
  }

  signIN() {
    this.setState({ errorMSG: null })

    if (!GlobalFunctions.checkReqvireds(this.input)) return

    GlobalFunctions.API_task({
      operation: 'confimEmailCustomer',
      GUID: this.props.match.params.GUID,
      PASSWORD: encodeURIComponent(this.input.PASSWORD.value),
      LOGIN: encodeURIComponent(this.input.LOGIN.value),
    })
      .then((res) => {
        if (res == null) {
          this.setState({ errorMSG: 'ОШИБКА' })
          return
        }
        this.setState({ isLoaded: false, isLoadedALL: true })
        const { cookies } = this.props
        cookies.set('login', res.LOGIN, { path: '/' })
        cookies.set('password', res.PASSWORD, { path: '/' })
        cookies.set('id', res.ID, { path: '/' })

        window.location.replace('../../')
      })
      .catch((error) => {
        this.setState({ errorMSG: error })
      })
  }

  render() {
    const {} = this.props.page
    const { isLoaded, isLoadedALL, isError } = this.state

    if (isError) return <h1>Неверный ключ регистрации</h1>
    return (
      <BodyBlack isLoadedALL={isLoadedALL}>
        <Page className="Page Login bg-light">
          <BGimg isLoaded={isLoaded}>
            <DarkBg />
          </BGimg>

          <LoginView isLoaded={isLoaded} isLoadedALL={isLoadedALL} className="row p-4">
            <div className="col-lg-4 col-md-3" />
            <div className="col-lg-4 col-md-6">
              <View>
                <Img isLoaded={isLoaded} src={envIconPath({ name: IEnvIconEnum.logo })} />

                <h5>Регистрация в приложении</h5>

                <p>Система ведения проектов</p>
                <div className="row">
                  <div className="col-7">
                    <TextField
                      label="Логин"
                      variant="outlined"
                      size="small"
                      className="bg-light"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Person />
                          </InputAdornment>
                        ),
                      }}
                      inputRef={(ref) => (this.input.LOGIN = ref)}
                    />

                    <TextField
                      label="Пароль"
                      type="password"
                      variant="outlined"
                      size="small"
                      className="bg-light mt-3 "
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                      inputRef={(ref) => (this.input.PASSWORD = ref)}
                    />
                  </div>
                  <div className="col-5">
                    <Button
                      variant="text"
                      style={{
                        position: 'absolute',
                        bottom: 20,
                        right: 20,
                      }}
                      size="small"
                      onClick={() => this.signIN()}
                    >
                      регистрация
                    </Button>
                  </div>
                </div>
              </View>
              {this.state.errorMSG != null ? (
                <Alert severity="error" className="mt-3">
                  <AlertTitle>Ошибка</AlertTitle>
                  {this.state.errorMSG}
                </Alert>
              ) : null}
            </div>
            <div className="col-lg-4 col-md-3" />
          </LoginView>
        </Page>
      </BodyBlack>
    )
  }
}

const LoginView = styled.div`
  ${(props) => (props.isLoadedALL ? `transition:1s;` : `transition:1s ease 1s;`)}

  ${(props) =>
    props.isLoaded
      ? `
transform:translateY(0);
opacity:1;
`
      : `
transform:translateY(50px);
opacity:0;
`}
`

const View = styled.div`
  background: white;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.1);
  padding: 20px !important;
  border-radius: 5px;
  margin-top: 50px;
  position: relative;
  z-index: 10;
`

const BodyBlack = styled.div`
  height: 100vh;
  width: 100vw;
  ${(props) => (props.isLoadedALL ? 'background:rgba(0,0,0,0);' : 'background:rgba(0,0,0,1);')}
`

const Img = styled.img`
  display: block;
  margin: auto;
  margin-top: 30px;
  z-index: 1;
  transition: 1s;
  position: absolute;
  width: 80px;
  right: 25px;
  top: 3px;
`

const mapDispatchToProps = (dispatch) => {
  return {
    openPopUp: (content) => dispatch(openPopUp(content)),
    loadUserData_FromStorage: (content) => dispatch(loadUserData_FromStorage(content)),

    logOut: (arg) => dispatch(logOut(arg)),
  }
}

const mapStateToProps = (store) => {
  return {
    page: store.page,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(withRouter(Index)))

const BGimg = styled.div`
  background: url('${envImagePath({ name: IEnvImageEnum.bg })}');
  height: 100vh;
  width: 100vw;
  transition: 1s;
  object-fit: cover;
  background-position: center top;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;

  ${down('md')} {
    ${(props) =>
      props.isLoaded
        ? `
    transform:scale(1);
    opacity:1;
    `
        : `
    transform:scale(1.5);
    opacity:0;
    `}
  }

  ${up('md')} {
    ${(props) =>
      props.isLoaded
        ? `
    transform:scale(1);
    opacity:1;
    `
        : `
    transform:scale(1.1);
    opacity:0;
    `}
  }
`

const Page = styled.div`
  overflow: hidden;
  height: 100vh;
  width: 100vw;
`

const DarkBg = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`
