import React, { FC } from 'react'
import { IEnvIconEnum } from 'components/UI/images/types'

interface IEnvIcon {
  name: IEnvIconEnum
}

const EnvIcon: FC<IEnvIcon> = (props: IEnvIcon) => {
  const { name } = props
  const src = `${process.env.PUBLIC_URL}/images/icons/${name}.svg`

  return <img src={src} alt={name} className={'icon-template__img'} />
}

export default EnvIcon
